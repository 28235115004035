import React from 'react';
import {
  Button,
  Icon,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const BankTransferP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'flex-end',
        },
        dimensions.width
      )}
    >
      {/* bottom-panel */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App.Thriller,
            borderTopLeftRadius: 32,
            borderTopRightRadius: 32,
            padding: 20,
          },
          dimensions.width
        )}
      >
        {/* panel-title */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['headling-19'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['headling-19'].style,
              { fontSize: 22 }
            ),
            dimensions.width
          )}
        >
          {'Bank transfer'}
        </Text>
        {/* options */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
        >
          {/* label-1 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['body-content'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                { marginTop: 10 }
              ),
              dimensions.width
            )}
          >
            {'Is this a current or savings account?'}
          </Text>
          {/* btn-current */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Thriller Pink'],
                borderRadius: 35,
                marginTop: 10,
                paddingBottom: 12,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 12,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                .props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['poppins-14-medium-white'].style,
                dimensions.width
              )}
            >
              {'Current'}
            </Text>
          </View>
          {/* btn-savings */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['TFA-InputBG'],
                borderRadius: 35,
                marginTop: 10,
                paddingBottom: 12,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 12,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                .props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['poppins-14-medium-white'].style,
                dimensions.width
              )}
            >
              {'Savings'}
            </Text>
          </View>
          {/* label-2 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['body-content'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                { marginTop: 10 }
              ),
              dimensions.width
            )}
          >
            {'Account holder name'}
          </Text>
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newTextInputValue => {
              const textInputValue = newTextInputValue;
              try {
                setTextInputValue2(newTextInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            placeholder={'PEDRO PASCAL'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                {
                  backgroundColor: palettes.App['TFA-InputBG'],
                  borderBottomWidth: null,
                  borderLeftWidth: null,
                  borderRadius: 35,
                  borderRightWidth: null,
                  borderTopWidth: null,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_500Medium',
                  marginTop: 5,
                  paddingBottom: 12,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 12,
                }
              ),
              dimensions.width
            )}
            value={textInputValue2}
          />
          {/* description */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-start', flexDirection: 'row', marginTop: 5 },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App['TFA-StatsBlockText']}
              name={'AntDesign/exclamationcircleo'}
              size={16}
              style={StyleSheet.applyWidth(
                { marginRight: 5, marginTop: 4 },
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['body-content'].style,
                  { fontSize: 12 }
                ),
                dimensions.width
              )}
            >
              {
                'Enter the account holder’s name exactly as it appears on bank statements'
              }
            </Text>
          </View>
          {/* label-3 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['body-content'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                { marginTop: 10 }
              ),
              dimensions.width
            )}
          >
            {'Routing number'}
          </Text>
          {/* Text Input 2 */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newTextInput2Value => {
              const textInputValue = newTextInput2Value;
              try {
                setTextInputValue2(newTextInput2Value);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            placeholder={'4365457537'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                {
                  backgroundColor: palettes.App['TFA-InputBG'],
                  borderBottomWidth: null,
                  borderLeftWidth: null,
                  borderRadius: 35,
                  borderRightWidth: null,
                  borderTopWidth: null,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_500Medium',
                  marginTop: 5,
                  paddingBottom: 12,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 12,
                }
              ),
              dimensions.width
            )}
            value={textInputValue2}
          />
          {/* description 2 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-start', flexDirection: 'row', marginTop: 5 },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App['TFA-StatsBlockText']}
              name={'AntDesign/exclamationcircleo'}
              size={16}
              style={StyleSheet.applyWidth(
                { marginRight: 5, marginTop: 4 },
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['body-content'].style,
                  { fontSize: 12 }
                ),
                dimensions.width
              )}
            >
              {
                'Enter the routing number. It is located on the bottom left corner of a cheque or within account details from the bank'
              }
            </Text>
          </View>
          {/* label-4 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['body-content'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                { marginTop: 10 }
              ),
              dimensions.width
            )}
          >
            {'Confirm account number'}
          </Text>
          {/* Text Input 3 */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newTextInput3Value => {
              const textInputValue = newTextInput3Value;
              try {
                setTextInputValue2(newTextInput3Value);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            placeholder={'57846-48452'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                {
                  backgroundColor: palettes.App['TFA-InputBG'],
                  borderBottomWidth: null,
                  borderLeftWidth: null,
                  borderRadius: 35,
                  borderRightWidth: null,
                  borderTopWidth: null,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_500Medium',
                  marginTop: 5,
                  paddingBottom: 12,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 12,
                }
              ),
              dimensions.width
            )}
            value={textInputValue2}
          />
          {/* label-5 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['body-content'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                { marginTop: 10 }
              ),
              dimensions.width
            )}
          >
            {'Account number'}
          </Text>
          {/* Text Input 4 */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newTextInput4Value => {
              const textInputValue = newTextInput4Value;
              try {
                setTextInputValue2(newTextInput4Value);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            placeholder={'57846-48452'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                {
                  backgroundColor: palettes.App['TFA-InputBG'],
                  borderBottomWidth: null,
                  borderLeftWidth: null,
                  borderRadius: 35,
                  borderRightWidth: null,
                  borderTopWidth: null,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_500Medium',
                  marginTop: 5,
                  paddingBottom: 12,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 12,
                }
              ),
              dimensions.width
            )}
            value={textInputValue2}
          />
          {/* description 3 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-start', flexDirection: 'row', marginTop: 5 },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App['TFA-StatsBlockText']}
              name={'AntDesign/exclamationcircleo'}
              size={16}
              style={StyleSheet.applyWidth(
                { marginRight: 5, marginTop: 4 },
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['body-content'].style,
                  { fontSize: 12 }
                ),
                dimensions.width
              )}
            >
              {
                'Enter the routing number. It is located on the bottom left corner of a cheque or within account details from the bank'
              }
            </Text>
          </View>
        </View>
        {/* bottom-btn-panel */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
        >
          {/* btn-add-card */}
          <Button
            accessible={true}
            iconPosition={'left'}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            iconSize={20}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['TFA-White'],
                  borderRadius: 30,
                  color: '"rgb(15, 4, 21)"',
                  fontFamily: 'Poppins_500Medium',
                  paddingBottom: 8,
                  paddingTop: 8,
                  width: '100%',
                }
              ),
              dimensions.width
            )}
            title={'Add'}
          />
          {/* Close */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('BalanceMainP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['Form Field BG'],
                  borderRadius: 35,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_500Medium',
                  marginTop: 10,
                }
              ),
              dimensions.width
            )}
            title={'Close'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(BankTransferP1InProgressScreen);
