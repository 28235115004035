import React from 'react';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { lineup_id: null, player_image: null, player_name: null };

const FeedRemovePlayerP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const thrillerXanoDeleteLineupSelectionDELETE =
    ThrillerXanoApi.useDeleteLineupSelectionDELETE();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'flex-end',
        },
        dimensions.width
      )}
    >
      {/* bottom-panel */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: palettes.App.Thriller,
            borderTopLeftRadius: 32,
            borderTopRightRadius: 32,
            padding: 20,
          },
          dimensions.width
        )}
      >
        {/* Image 2 */}
        <Image
          resizeMode={'cover'}
          {...GlobalStyles.ImageStyles(theme)['Image'].props}
          source={imageSource(
            `${props.route?.params?.player_image ?? defaultProps.player_image}`
          )}
          style={StyleSheet.applyWidth(
            GlobalStyles.ImageStyles(theme)['Image'].style,
            dimensions.width
          )}
        />
        {/* Remove Player? */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['headling-19'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['headling-19'].style,
              { fontFamily: 'Oswald_700Bold', fontSize: 24, marginTop: 10 }
            ),
            dimensions.width
          )}
        >
          {'Remove player?'}
        </Text>

        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', flexDirection: 'row', marginTop: 10 },
            dimensions.width
          )}
        >
          {/* Remove */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['body-content'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['body-content'].style,
              dimensions.width
            )}
          >
            {'Remove '}
          </Text>
          {/* text */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['poppins-14-medium-white'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['poppins-14-medium-white'].style,
              dimensions.width
            )}
          >
            {props.route?.params?.player_name ?? defaultProps.player_name}
          </Text>
          {/* text */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['body-content'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['body-content'].style,
              dimensions.width
            )}
          >
            {' from your list?'}
          </Text>
        </View>
      </View>
      {/* bottom-btn-panel */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App.Thriller,
            marginBottom: 40,
            paddingLeft: 20,
            paddingRight: 20,
          },
          dimensions.width
        )}
      >
        {/* btn-remove */}
        <Button
          accessible={true}
          iconPosition={'left'}
          onPress={() => {
            const handler = async () => {
              try {
                (
                  await thrillerXanoDeleteLineupSelectionDELETE.mutateAsync({
                    lineup_selections_id:
                      props.route?.params?.lineup_id ?? defaultProps.lineup_id,
                  })
                )?.json;
                navigation.navigate('CurrentEntryP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          iconSize={20}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              {
                backgroundColor: palettes.App['TFA-White'],
                borderRadius: 30,
                color: '"rgb(15, 4, 21)"',
                fontFamily: 'Poppins_500Medium',
                paddingBottom: 8,
                paddingTop: 8,
                width: '100%',
              }
            ),
            dimensions.width
          )}
          title={'Remove'}
        />
        {/* Close */}
        <Button
          accessible={true}
          iconPosition={'left'}
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator');
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              {
                backgroundColor: palettes.App['Form Field BG'],
                borderRadius: 35,
                color: palettes.App['TFA-White'],
                fontFamily: 'Poppins_500Medium',
                marginTop: 10,
              }
            ),
            dimensions.width
          )}
          title={'Cancel'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(FeedRemovePlayerP1InProgressScreen);
