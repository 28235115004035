import React from 'react';
import { Icon, IconButton, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, ImageBackground, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const HowToPlayP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [inputCurrentPassValue, setInputCurrentPassValue] = React.useState('');
  const [inputCurrentPassValue2, setInputCurrentPassValue2] =
    React.useState('');
  const [inputNewPassValue, setInputNewPassValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['TFA-ScreenBG'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['TopRectangleProfileThrillerFantasy'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { height: 170 }
          ),
          dimensions.width
        )}
      >
        {/* top-box */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginRight: 20 },
            dimensions.width
          )}
        >
          {/* nav-top */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 10,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            {/* nav-top__left */}
            <View
              style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
            >
              <IconButton
                onPress={() => {
                  try {
                    navigation.navigate('BottomTabNavigator', {
                      screen: 'MyProfileMainP1InProgressScreen',
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                color={palettes.App['Form Placeholder Text color']}
                icon={'Feather/arrow-left'}
              />
            </View>
            {/* nav-top__center */}
            <View>
              {/* How to play */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: '"rgb(255, 255, 255)"',
                      fontFamily: 'Oswald_700Bold',
                      fontSize: 24,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'How to play'}
              </Text>
            </View>
            {/* nav-top__right */}
            <View
              style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
            />
          </View>
        </View>
        {/* choose-player-panel */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginRight: 20, marginTop: 20 },
            dimensions.width
          )}
        >
          {/* 01 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['#7F7685'],
                fontFamily: 'Oswald_700Bold',
                fontSize: 72,
              }),
              dimensions.width
            )}
          >
            {'01'}
          </Text>
          {/* players-block */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['#231C27'],
                borderRadius: 16,
                marginTop: -30,
                padding: 16,
              },
              dimensions.width
            )}
          >
            {/* Choose 1 or more players from any sport */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                  }
                ),
                dimensions.width
              )}
            >
              {'Choose 1 or more players from any sport'}
            </Text>
            {/* players */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 20,
                },
                dimensions.width
              )}
            >
              {/* player-1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['Form Field BG'],
                    borderRadius: 17,
                    height: 90,
                    justifyContent: 'center',
                    width: 70,
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['User'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        borderRadius: 100,
                        height: 50,
                        marginBottom: 10,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* Player 1 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Player 1'}
                </Text>
              </View>
              {/* player-2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['Form Field BG'],
                    borderRadius: 17,
                    height: 90,
                    justifyContent: 'center',
                    width: 70,
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['User'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        borderRadius: 100,
                        height: 50,
                        marginBottom: 10,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* Player 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Player 2'}
                </Text>
              </View>
              {/* player-3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['Form Field BG'],
                    borderRadius: 17,
                    height: 90,
                    justifyContent: 'center',
                    width: 70,
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['User'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        borderRadius: 100,
                        height: 50,
                        marginBottom: 10,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* Player 3 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Player 3'}
                </Text>
              </View>
              {/* add */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: '"rgb(57, 50, 60)"',
                    borderRadius: 17,
                    height: 90,
                    justifyContent: 'center',
                    width: 70,
                  },
                  dimensions.width
                )}
              >
                {/* + */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_200ExtraLight',
                        fontSize: 40,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'+'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* stats */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginRight: 20, marginTop: 20 },
            dimensions.width
          )}
        >
          {/* 02 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['#7F7685'],
                fontFamily: 'Oswald_700Bold',
                fontSize: 72,
              }),
              dimensions.width
            )}
          >
            {'02'}
          </Text>
          {/* players-block */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['#231C27'],
                borderRadius: 16,
                marginTop: -30,
                padding: 16,
              },
              dimensions.width
            )}
          >
            {/* Pick “Above” or “Below” on their projected stats */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                  }
                ),
                dimensions.width
              )}
            >
              {'Pick “Above” or “Below” on their projected stats'}
            </Text>
            {/* stats-panel */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  marginTop: 20,
                },
                dimensions.width
              )}
            >
              {/* below */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: '"rgba(255, 65, 65, 0.2)"',
                    borderColor: '"rgb(255, 65, 65)"',
                    borderRadius: 17,
                    borderWidth: 1,
                    height: 90,
                    justifyContent: 'center',
                    marginRight: 10,
                    width: 70,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={palettes.App['#FF4141']}
                  name={'AntDesign/caretdown'}
                  style={StyleSheet.applyWidth(
                    { marginBottom: 10 },
                    dimensions.width
                  )}
                />
                {/* below */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Below'}
                </Text>
              </View>
              {/* player-1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['Form Field BG'],
                    borderRadius: 17,
                    height: 90,
                    justifyContent: 'center',
                    marginRight: 10,
                    width: 70,
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['User'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        borderRadius: 100,
                        height: 50,
                        marginBottom: 10,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* Player 1 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Player 1'}
                </Text>
              </View>
              {/* above */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: '"rgba(3, 233, 81, 0.2)"',
                    borderColor: '"rgb(3, 233, 81)"',
                    borderRadius: 17,
                    borderWidth: 1,
                    height: 90,
                    justifyContent: 'center',
                    width: 70,
                  },
                  dimensions.width
                )}
              >
                {/* above */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Above'}
                </Text>
                <Icon
                  size={24}
                  color={palettes.App['TFA-Green']}
                  name={'AntDesign/caretup'}
                  style={StyleSheet.applyWidth(
                    { marginTop: 10 },
                    dimensions.width
                  )}
                />
              </View>
            </View>
          </View>
        </View>
        {/* fantasy */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginRight: 20, marginTop: 20 },
            dimensions.width
          )}
        >
          {/* 03 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['#7F7685'],
                fontFamily: 'Oswald_700Bold',
                fontSize: 72,
              }),
              dimensions.width
            )}
          >
            {'03'}
          </Text>
          {/* fantasy-block */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['#231C27'],
                borderRadius: 16,
                marginTop: -30,
                padding: 16,
              },
              dimensions.width
            )}
          >
            {/* Pick “Above” or “Below” on their projected stats */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                  }
                ),
                dimensions.width
              )}
            >
              {'Choose the fantasy score of your favorite players'}
            </Text>
            {/* If you make several correct picks, you will receive the following prizes: */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['body-content'].style,
                  { marginTop: 10 }
                ),
                dimensions.width
              )}
            >
              {
                'If you make several correct picks, you will receive the following prizes:'
              }
            </Text>
            {/* stats-text-wrap-1 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 10,
                },
                dimensions.width
              )}
            >
              {/* stats-text-1 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'stretch', flexDirection: 'row', flexGrow: 1 },
                  dimensions.width
                )}
              >
                {/* 2 picks =  */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['body-content'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['body-content'].style,
                    dimensions.width
                  )}
                >
                  {'2 picks = '}
                </Text>
                {/* 5x prize */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['body-content'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['body-content'].style,
                      { color: palettes.App['TFA-White'] }
                    ),
                    dimensions.width
                  )}
                >
                  {'5x prize'}
                </Text>
              </View>
              {/* stats-text-2 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'stretch', flexDirection: 'row', flexGrow: 1 },
                  dimensions.width
                )}
              >
                {/* 2 picks =  */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['body-content'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['body-content'].style,
                    dimensions.width
                  )}
                >
                  {'3 picks = '}
                </Text>
                {/* 10x prize */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['body-content'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['body-content'].style,
                      { color: palettes.App['TFA-White'] }
                    ),
                    dimensions.width
                  )}
                >
                  {'10x prize'}
                </Text>
              </View>
            </View>
            {/* stats-text-wrap-2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 10,
                },
                dimensions.width
              )}
            >
              {/* stats-text-1 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'stretch', flexDirection: 'row', flexGrow: 1 },
                  dimensions.width
                )}
              >
                {/* 5 picks =  */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['body-content'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['body-content'].style,
                    dimensions.width
                  )}
                >
                  {'5 picks = '}
                </Text>
                {/* 25x prize */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['body-content'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['body-content'].style,
                      { color: palettes.App['TFA-White'] }
                    ),
                    dimensions.width
                  )}
                >
                  {'25x prize'}
                </Text>
              </View>
              {/* stats-text-2 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'stretch', flexDirection: 'row', flexGrow: 1 },
                  dimensions.width
                )}
              >
                {/* 10 picks =  */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['body-content'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['body-content'].style,
                    dimensions.width
                  )}
                >
                  {'10 picks = '}
                </Text>
                {/* 50x prize */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['body-content'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['body-content'].style,
                      { color: palettes.App['TFA-White'] }
                    ),
                    dimensions.width
                  )}
                >
                  {'50x prize'}
                </Text>
              </View>
            </View>
            {/* players */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 20,
                },
                dimensions.width
              )}
            >
              {/* player-1 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['Form Field BG'],
                    borderRadius: 17,
                    height: 106,
                    justifyContent: 'center',
                    width: 70,
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['User'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        borderRadius: 100,
                        height: 50,
                        marginBottom: 10,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* Player 1 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Player 1'}
                </Text>
                {/* won */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: 'rgb(3, 233, 81)',
                        fontFamily: 'Poppins_500Medium',
                        fontSize: 10,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Won'}
                </Text>
              </View>
              {/* player-2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['Form Field BG'],
                    borderRadius: 17,
                    height: 106,
                    justifyContent: 'center',
                    width: 70,
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['User'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        borderRadius: 100,
                        height: 50,
                        marginBottom: 10,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* Player 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Player 2'}
                </Text>
                {/* won */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: 'rgb(3, 233, 81)',
                        fontFamily: 'Poppins_500Medium',
                        fontSize: 10,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Won'}
                </Text>
              </View>
              {/* player-3 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['Form Field BG'],
                    borderRadius: 17,
                    height: 106,
                    justifyContent: 'center',
                    width: 70,
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['User'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        borderRadius: 100,
                        height: 50,
                        marginBottom: 10,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* Player 3 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Player 3'}
                </Text>
                {/* lost */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: '"rgb(255, 65, 65)"',
                        fontFamily: 'Poppins_500Medium',
                        fontSize: 10,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Lost'}
                </Text>
              </View>
              {/* player-4 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['Form Field BG'],
                    borderRadius: 17,
                    height: 106,
                    justifyContent: 'center',
                    width: 70,
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['User'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        borderRadius: 100,
                        height: 50,
                        marginBottom: 10,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* Player 4 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Player 4'}
                </Text>
                {/* won */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: 'rgb(3, 233, 81)',
                        fontFamily: 'Poppins_500Medium',
                        fontSize: 10,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Won'}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(HowToPlayP1InProgressScreen);
