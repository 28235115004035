import React from 'react';
import {
  Button,
  Icon,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import getFirstLetter from '../global-functions/getFirstLetter';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const MyProfileMainP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [screen_user, setScreen_user] = React.useState({});
  const [user_status, setUser_status] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const current_user = (await ThrillerXanoApi.userProfileGET(Constants))
          ?.json;
        setScreen_user(current_user);
        if (current_user?._veriff_session?.status === 'approved') {
          setUser_status('Account Verified');
        } else {
          setUser_status('Verify your account to start playing!');
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.Thriller },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['TopRectangleProfileThrillerFantasy'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { alignItems: 'center', alignSelf: 'auto', height: 100 }
          ),
          dimensions.width
        )}
      >
        <SimpleStyleScrollView
          bounces={true}
          horizontal={false}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsVerticalScrollIndicator={true}
          scrollEnabled={true}
          showsHorizontalScrollIndicator={true}
          style={StyleSheet.applyWidth(
            { alignItems: 'center' },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                marginTop: 15,
                position: 'relative',
              },
              dimensions.width
            )}
          >
            {/* Title */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App.White,
                    fontFamily: 'Roboto_700Bold',
                    fontSize: 25,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Profile'}
            </Text>
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                height: [
                  { minWidth: Breakpoints.Laptop, value: 100 },
                  { minWidth: Breakpoints.Mobile, value: 100 },
                ],
                marginTop: 30,
                position: 'relative',
                width: [
                  { minWidth: Breakpoints.Laptop, value: 100 },
                  { minWidth: Breakpoints.Mobile, value: 100 },
                ],
              },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(`${Constants['User']?.profile_picture?.url}`)}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    borderColor: palettes.App['Custom Color_13'],
                    borderRadius: 100,
                    borderWidth: 4,
                    position: [
                      { minWidth: Breakpoints.Laptop, value: 'absolute' },
                      { minWidth: Breakpoints.Mobile, value: 'absolute' },
                    ],
                  }
                ),
                dimensions.width
              )}
            />
            <>
              {!(
                Constants['User']?.profile_picture?.name === '96064.jpg'
              ) ? null : (
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        alignSelf: [
                          { minWidth: Breakpoints.Laptop, value: 'center' },
                          { minWidth: Breakpoints.Mobile, value: 'center' },
                        ],
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App['TFA-White'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App['TFA-White'],
                          },
                        ],
                        fontFamily: [
                          { minWidth: Breakpoints.Laptop, value: 'System' },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Roboto_700Bold',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 70 },
                          { minWidth: Breakpoints.Mobile, value: 70 },
                        ],
                        fontWeight: {
                          minWidth: Breakpoints.Laptop,
                          value: '700',
                        },
                        textAlign: [
                          { minWidth: Breakpoints.Laptop, value: 'center' },
                          { minWidth: Breakpoints.Mobile, value: 'center' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {getFirstLetter(Constants['User']?.first_name)}
                </Text>
              )}
            </>
          </View>
          {/* View 3 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'center',
                justifyContent: 'center',
                marginTop: 20,
                position: 'relative',
              },
              dimensions.width
            )}
          >
            {/* Name */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_700Bold',
                    fontSize: 24,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {Constants['User']?._veriff_session?.status === 'approved'
                ? Constants['User']?.veriff_name
                : Constants['User']?.first_name +
                  (' ' + Constants['User']?.last_name)}
            </Text>
          </View>
          {/* View 4 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'stretch',
                alignSelf: 'center',
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginTop: 10,
                position: 'relative',
              },
              dimensions.width
            )}
          >
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  navigation.navigate('ProfileEditProfileP1InProgressScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              icon={'Ionicons/pencil-sharp'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: palettes.App['Form Field BG'],
                    fontFamily: 'Roboto_400Regular',
                    height: 38,
                    marginRight: 5,
                    width: 170,
                  }
                ),
                dimensions.width
              )}
              title={'Edit profile'}
            />
          </View>

          <Touchable>
            {/* View 5 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'center',
                  backgroundColor: '"rgb(120, 14, 119)"',
                  borderRadius: 10,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 10,
                  padding: 12,
                  position: 'relative',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={palettes.App.White}
                name={
                  screen_user?._veriff_session?.status === 'approved'
                    ? 'AntDesign/checkcircle'
                    : 'AntDesign/exclamationcircle'
                }
                style={StyleSheet.applyWidth(
                  { marginRight: 5 },
                  dimensions.width
                )}
              />
              <>
                {!screen_user ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('StartVerificationScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: null,
                          color: palettes.App['TFA-White'],
                          fontFamily: 'Roboto_400Regular',
                          height: 0,
                          marginRight: 5,
                          padding: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingTop: 0,
                        }
                      ),
                      dimensions.width
                    )}
                    title={`${user_status}`}
                  />
                )}
              </>
              {/* Button 2 */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    navigation.navigate('StartVerificationScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                icon={'Entypo/chevron-thin-right'}
                iconSize={18}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: null,
                      color: palettes.App['TFA-White'],
                      fontFamily: null,
                      padding: 0,
                    }
                  ),
                  dimensions.width
                )}
                title={''}
              />
            </View>
          </Touchable>
          {/* View 6 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignItems: 'stretch',
                alignSelf: 'center',
                flexDirection: [
                  { minWidth: Breakpoints.Mobile, value: 'row' },
                  { minWidth: Breakpoints.Laptop, value: 'column' },
                ],
                justifyContent: 'space-between',
                marginTop: 10,
              },
              dimensions.width
            )}
          >
            <ThrillerXanoApi.FetchUserBalanceGET>
              {({ loading, error, data, refetchUserBalance }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-start',
                        },
                        flexDirection: [
                          { minWidth: Breakpoints.Laptop, value: 'row' },
                          { minWidth: Breakpoints.Mobile, value: 'row' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('BalanceMainP1InProgressScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignContent: 'flex-start',
                            alignSelf: 'center',
                            backgroundColor: '"rgb(35, 28, 39)"',
                            borderRadius: 18,
                            height: 80,
                            justifyContent: 'center',
                            marginRight: 5,
                            paddingBottom: 10,
                            paddingLeft: 10,
                            width: 170,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignContent: 'center',
                              alignSelf: 'center',
                              justifyContent: 'flex-end',
                              marginLeft: 70,
                              marginTop: 20,
                              position: 'relative',
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={24}
                            color={palettes.App['GetFit Orange']}
                            name={'Ionicons/logo-usd'}
                            style={StyleSheet.applyWidth(
                              { position: 'relative' },
                              dimensions.width
                            )}
                          />
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignContent: 'center',
                              alignSelf: 'flex-start',
                              marginTop: -35,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App.White,
                                  fontFamily: 'Roboto_400Regular',
                                  fontSize: 20,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.balance}
                          </Text>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color:
                                    palettes.App['Form Placeholder Text color'],
                                  fontFamily: 'Roboto_400Regular',
                                  fontSize: 15,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'My Cash'}
                          </Text>
                        </View>
                      </View>
                    </Touchable>
                    {/* Touchable 2 */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('BalanceMainP1InProgressScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: '"rgb(35, 28, 39)"',
                            borderRadius: 16,
                            height: 80,
                            marginLeft: 5,
                            paddingBottom: 10,
                            paddingLeft: 10,
                            width: 170,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: 'center',
                              marginLeft: 70,
                              marginTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            size={24}
                            color={palettes.App['GetFit Orange']}
                            name={'FontAwesome/trophy'}
                          />
                        </View>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: -30 },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App.White,
                                  fontFamily: 'Roboto_400Regular',
                                  fontSize: 20,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchData?.bonus_money}
                          </Text>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color:
                                    palettes.App['Form Placeholder Text color'],
                                  fontFamily: 'Roboto_400Regular',
                                  fontSize: 15,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Bonus cash'}
                          </Text>
                        </View>
                      </View>
                    </Touchable>
                  </View>
                );
              }}
            </ThrillerXanoApi.FetchUserBalanceGET>
          </View>
          {/* View 7 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                alignSelf: 'center',
                backgroundColor: '"rgb(35, 28, 39)"',
                borderRadius: 16,
                marginTop: [
                  { minWidth: Breakpoints.Mobile, value: 10 },
                  { minWidth: Breakpoints.Laptop, value: 10 },
                ],
                maxWidth: 350,
                padding: 10,
                position: 'relative',
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* item-1 */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              {/* View 4 */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { justifyContent: 'center', marginLeft: 5 },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    color={palettes.App['Form Placeholder Text color']}
                    name={'MaterialIcons/password'}
                  />
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      justifyContent: 'center',
                      marginLeft: 15,
                      marginRight: 90,
                    },
                    dimensions.width
                  )}
                >
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate(
                          'ProfileChangePasswordP1InProgressScreen'
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: null,
                          color: palettes.App['TFA-White'],
                          fontFamily: 'Roboto_400Regular',
                          height: 20,
                          padding: 0,
                          paddingBottom: 0,
                          paddingTop: 0,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Change Password'}
                  />
                </View>
              </View>
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  { justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  icon={'Entypo/chevron-thin-right'}
                  iconSize={18}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: null,
                        color: palettes.App['Form Placeholder Text color'],
                        fontFamily: null,
                        padding: 0,
                      }
                    ),
                    dimensions.width
                  )}
                  title={''}
                />
              </View>
            </View>
            {/* item-2 */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              {/* View 4 */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { justifyContent: 'center', marginLeft: 5 },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    color={palettes.App['Form Placeholder Text color']}
                    name={'MaterialIcons/privacy-tip'}
                  />
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      justifyContent: 'center',
                      marginLeft: 15,
                      marginRight: 90,
                    },
                    dimensions.width
                  )}
                >
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://thrillerfantasy.com/privacy-policy/'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: null,
                          color: palettes.App['TFA-White'],
                          fontFamily: 'Roboto_400Regular',
                          height: 20,
                          padding: 0,
                          paddingBottom: 0,
                          paddingTop: 0,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Privacy policy'}
                  />
                </View>
              </View>
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  { justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          'https://thrillerfantasy.com/privacy-policy/'
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  icon={'Entypo/chevron-thin-right'}
                  iconSize={18}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: null,
                        color: palettes.App['Form Placeholder Text color'],
                        fontFamily: null,
                        padding: 0,
                      }
                    ),
                    dimensions.width
                  )}
                  title={''}
                />
              </View>
            </View>
            {/* item-3 */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              {/* View 4 */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { justifyContent: 'center', marginLeft: 5 },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    color={palettes.App['Form Placeholder Text color']}
                    name={'Ionicons/document'}
                  />
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      justifyContent: 'center',
                      marginLeft: 15,
                      marginRight: 90,
                    },
                    dimensions.width
                  )}
                >
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await WebBrowser.openBrowserAsync(
                            'https://thrillerfantasy.com/terms-of-services/'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: null,
                          color: palettes.App['TFA-White'],
                          fontFamily: 'Roboto_400Regular',
                          height: 20,
                          padding: 0,
                          paddingBottom: 0,
                          paddingTop: 0,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Terms of condition'}
                  />
                </View>
              </View>
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  { justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await WebBrowser.openBrowserAsync(
                          'https://thrillerfantasy.com/terms-of-services/'
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  icon={'Entypo/chevron-thin-right'}
                  iconSize={18}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: null,
                        color: palettes.App['Form Placeholder Text color'],
                        fontFamily: null,
                        padding: 0,
                      }
                    ),
                    dimensions.width
                  )}
                  title={''}
                />
              </View>
            </View>
            {/* item-5 */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              {/* View 4 */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { justifyContent: 'center', marginLeft: 5 },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    color={palettes.App['Form Placeholder Text color']}
                    name={'MaterialCommunityIcons/email'}
                  />
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      justifyContent: 'center',
                      marginLeft: 15,
                      marginRight: 90,
                    },
                    dimensions.width
                  )}
                >
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('ContactUsP1InProgressScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: null,
                          color: palettes.App['TFA-White'],
                          fontFamily: 'Roboto_400Regular',
                          height: 20,
                          padding: 0,
                          paddingBottom: 0,
                          paddingTop: 0,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Contact Us'}
                  />
                </View>
              </View>
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  { justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  icon={'Entypo/chevron-thin-right'}
                  iconSize={18}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: null,
                        color: palettes.App['Form Placeholder Text color'],
                        fontFamily: null,
                        padding: 0,
                      }
                    ),
                    dimensions.width
                  )}
                  title={''}
                />
              </View>
            </View>
          </View>
          {/* View 8 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: [
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                ],
                alignSelf: 'center',
                backgroundColor: '"rgb(35, 28, 39)"',
                borderRadius: 16,
                justifyContent: {
                  minWidth: Breakpoints.Tablet,
                  value: 'center',
                },
                marginBottom: 40,
                marginTop: [
                  { minWidth: Breakpoints.Laptop, value: 10 },
                  { minWidth: Breakpoints.Mobile, value: 10 },
                ],
                maxWidth: [
                  { minWidth: Breakpoints.Laptop, value: 350 },
                  { minWidth: Breakpoints.Tablet, value: 350 },
                  { minWidth: Breakpoints.Mobile, value: 350 },
                ],
                paddingBottom: 20,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
                position: 'relative',
                width: [
                  { minWidth: Breakpoints.Laptop, value: '100%' },
                  { minWidth: Breakpoints.Tablet, value: '100%' },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                ],
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'flex-start',
                  alignSelf: 'auto',
                  marginLeft: 10,
                },
                dimensions.width
              )}
            >
              {/* Logout */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'AuthToken',
                      value: '',
                    });
                    setGlobalVariableValue({
                      key: 'User',
                      value: '',
                    });
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('SignUpLogInScreenP1InProgressScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: null,
                      color: palettes.App.Peoplebit_Salmon_Red,
                      fontFamily: 'Roboto_400Regular',
                      fontSize: 16,
                      padding: 0,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'Log out'}
              />
              {/* Delete Account */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const resp = (
                        await ThrillerXanoApi.checkAccountStatusGET(Constants)
                      )?.json;
                      if (extractErrorFromResponse(resp) === 'false') {
                        if (navigation.canGoBack()) {
                          navigation.popToTop();
                        }
                        navigation.replace('AccountDeletionScreen');
                      } else {
                        showAlertUtil({
                          title: 'Oops',
                          message: extractErrorFromResponse(resp),
                          buttonText: undefined,
                        });
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: null,
                      color: palettes.App.Peoplebit_Salmon_Red,
                      fontFamily: 'Roboto_400Regular',
                      fontSize: 16,
                      padding: 0,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
                title={'Request Account Deletion'}
              />
            </View>
          </View>
        </SimpleStyleScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(MyProfileMainP1InProgressScreen);
