import React from 'react';
import {
  Button,
  LottieAnimation,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { H1 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import { Image, ImageBackground, Modal, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import Animations from '../config/Animations';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import askLocationPermission from '../global-functions/askLocationPermission';
import refreshCurrentPage from '../global-functions/refreshCurrentPage';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getLocationUtil from '../utils/getLocation';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const CheckLocationServicesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [is_loading, setIs_loading] = React.useState(true);
  const [location, setLocation] = React.useState(1);
  const [location_error, setLocation_error] = React.useState('');
  const [show_popup, setShow_popup] = React.useState(false);
  const thrillerXanoCheckStatePOST = ThrillerXanoApi.useCheckStatePOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        if (Constants['onboarding_complete'] === false) {
          navigation.navigate('Onboarding01P1InProgressScreen');
        } else {
          const old_loc = await getLocationUtil({ accuracy: 'Low' });
          const locationdata = await askLocationPermission();
          if (old_loc) {
            setIs_loading(true);
            const location = await getLocationUtil({ accuracy: 'Low' });
            if (location) {
              setLocation(location);
              const resp_location = (
                await thrillerXanoCheckStatePOST.mutateAsync({
                  lat: location?.latitude,
                  lng: location?.longitude,
                })
              )?.json;
              setIs_loading(false);
              if (resp_location?.isAllowed === true) {
                if (Constants['User'] !== {} && Constants['User'] !== '') {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'FeedMainP1InProgressScreen',
                  });
                } else {
                  if (navigation.canGoBack()) {
                    navigation.popToTop();
                  }
                  navigation.replace('SignUpLogInScreenP1InProgressScreen');
                }
              } else {
                if (navigation.canGoBack()) {
                  navigation.popToTop();
                }
                navigation.replace('NotAvailableScrenScreen');
              }
            } else {
              setIs_loading(false);
              setLocation('');
            }
          } else {
            setIs_loading(false);
            setLocation('');
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.Thriller },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['Background'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { zIndex: 0 }
          ),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', justifyContent: 'center', marginTop: 100 },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['ThrillerFanstayLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 95, position: 'relative', width: 300 }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* View 3 */}
        <View>
          {/* View 2 */}
          <>
            {!(is_loading === true) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: 50,
                    paddingLeft: 50,
                    paddingRight: 50,
                  },
                  dimensions.width
                )}
              >
                <H1
                  selectable={false}
                  {...GlobalStyles.H1Styles(theme)['H1'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.H1Styles(theme)['H1'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Roboto_500Medium',
                        fontSize: 20,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Intializing Our Location Services'}
                </H1>
                <>
                  {!(is_loading === true) ? null : (
                    <LottieAnimation
                      autoPlay={true}
                      loop={true}
                      speed={1}
                      {...GlobalStyles.LottieAnimationStyles(theme)[
                        'Lottie Animation'
                      ].props}
                      source={imageSource(Animations['loader'])}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.LottieAnimationStyles(theme)[
                          'Lottie Animation'
                        ].style,
                        dimensions.width
                      )}
                    />
                  )}
                </>
              </View>
            )}
          </>
          {/* View 7 */}
          <>
            {!(location === '') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'auto',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 25,
                    paddingLeft: 25,
                    paddingRight: 25,
                  },
                  dimensions.width
                )}
              >
                <H1
                  selectable={false}
                  {...GlobalStyles.H1Styles(theme)['H1'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.H1Styles(theme)['H1'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'RobotoFlex_400Regular',
                        fontSize: 25,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Welcome to Thriller Fantasy!'}
                </H1>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['TFA-White'],
                        fontFamily: 'RobotoFlex_400Regular',
                        letterSpacing: 0.5,
                        lineHeight: 25,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'To ensure you have the best experience, please enable your location services. This is necessary to verify that you are in a state where fantasy sports are permitted. \n\nOnce your location services are activated, you’ll unlock a world of thrilling fantasy sports action! \n\nThank you for joining us, and let thegamesbegin!'
                  }
                </Text>
                {/* Turn Location On */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const locData = await askLocationPermission();
                        if (locData === 'success') {
                          refreshCurrentPage();
                        } else {
                          setLocation_error(locData);
                          setShow_popup(true);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  icon={'MaterialIcons/my-location'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: palettes.App.White,
                        borderRadius: 37,
                        color: palettes.App.Thriller,
                        fontFamily: 'Roboto_400Regular',
                        height: 44,
                        marginTop: 20,
                        width: 350,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Turn on Location'}
                />
              </View>
            )}
          </>
        </View>
      </ImageBackground>

      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        presentationStyle={'formSheet'}
        transparent={true}
        visible={show_popup === true}
      >
        {/* View 4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              alignSelf: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              backgroundColor: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App['TFA-ScreenBG'],
                },
                {
                  minWidth: Breakpoints.Mobile,
                  value: palettes.App['TFA-ScreenBG'],
                },
              ],
              flexWrap: [
                { minWidth: Breakpoints.Laptop, value: 'wrap' },
                { minWidth: Breakpoints.Mobile, value: 'wrap' },
              ],
              justifyContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              marginTop: [
                { minWidth: Breakpoints.Laptop, value: 250 },
                { minWidth: Breakpoints.Mobile, value: 250 },
              ],
              padding: 20,
              position: [
                { minWidth: Breakpoints.Laptop, value: 'absolute' },
                { minWidth: Breakpoints.Mobile, value: 'absolute' },
              ],
              width: [
                { minWidth: Breakpoints.Laptop, value: 500 },
                { minWidth: Breakpoints.Mobile, value: '100%' },
              ],
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: 'rgba(0, 0, 0, 0)',
                },
                paddingTop: { minWidth: Breakpoints.Desktop, value: 20 },
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['TFA-White'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['TFA-White'],
                      },
                    ],
                    fontFamily: [
                      { minWidth: Breakpoints.Laptop, value: 'System' },
                      { minWidth: Breakpoints.Mobile, value: 'Roboto_700Bold' },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 24 },
                      { minWidth: Breakpoints.Mobile, value: 24 },
                    ],
                    fontWeight: { minWidth: Breakpoints.Laptop, value: '700' },
                    textAlign: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  }
                ),
                dimensions.width
              )}
            >
              {'Location Error'}
            </Text>
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 20 },
                borderTopWidth: { minWidth: Breakpoints.Laptop, value: 20 },
                paddingTop: { minWidth: Breakpoints.Desktop, value: 20 },
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['TFA-White'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['TFA-White'],
                      },
                    ],
                    fontFamily: 'Roboto_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 20 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    textAlign: [
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                      { minWidth: Breakpoints.Mobile, value: 'center' },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {location_error}
            </Text>
          </View>
          {/* View 3 */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 20 },
                marginTop: 20,
                paddingTop: { minWidth: Breakpoints.Desktop, value: 20 },
                width: [
                  { minWidth: Breakpoints.Laptop, value: '100%' },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                ],
              },
              dimensions.width
            )}
          >
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  setShow_popup(false);
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    fontFamily: 'Roboto_700Bold',
                    width: { minWidth: Breakpoints.Laptop, value: '100%' },
                  }
                ),
                dimensions.width
              )}
              title={'OK'}
            />
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(CheckLocationServicesScreen);
