import React from 'react';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, ImageBackground, Modal, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const SignUpLogInScreenP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [location, setLocation] = React.useState('');
  const [location_error, setLocation_error] = React.useState('');
  const [show_popup, setShow_popup] = React.useState(false);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.Thriller },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['Background'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { zIndex: 0 }
          ),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', justifyContent: 'center', marginTop: 150 },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['ThrillerFanstayLogo'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 95, position: 'absolute', width: 300 }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'auto',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 130,
              paddingLeft: 60,
              paddingRight: 60,
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                alignSelf: 'auto',
                color: '"rgb(127, 118, 133)"',
                fontFamily: 'Poppins_500Medium',
                fontSize: 16,
                lineHeight: 24,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'Each entry, a thriller in your sports saga'}
          </Text>
        </View>
        {/* View 3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'auto',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 70,
            },
            dimensions.width
          )}
        >
          {/* Log In Button */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('LogInMainScreenP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: '"rgb(46, 40, 50)"',
                  borderRadius: 37,
                  color: palettes.App.White,
                  fontFamily: 'Poppins_500Medium',
                  height: 44,
                  width: 350,
                }
              ),
              dimensions.width
            )}
            title={'Log in'}
          />
        </View>
        {/* View 4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'auto',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          {/* Sign Up Button */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('SIgnUpMainScreenP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App.White,
                  borderRadius: 37,
                  color: palettes.App.Thriller,
                  fontFamily: 'Poppins_600SemiBold',
                  height: 44,
                  width: 350,
                }
              ),
              dimensions.width
            )}
            title={'Sign Up'}
          />
        </View>
      </ImageBackground>

      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        presentationStyle={'formSheet'}
        transparent={true}
        visible={show_popup === true}
      >
        {/* View 4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              alignSelf: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              backgroundColor: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App['TFA-ScreenBG'],
                },
                {
                  minWidth: Breakpoints.Mobile,
                  value: palettes.App['TFA-ScreenBG'],
                },
              ],
              flexWrap: [
                { minWidth: Breakpoints.Laptop, value: 'wrap' },
                { minWidth: Breakpoints.Mobile, value: 'wrap' },
              ],
              justifyContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              marginTop: [
                { minWidth: Breakpoints.Laptop, value: 250 },
                { minWidth: Breakpoints.Mobile, value: 250 },
              ],
              padding: 20,
              position: [
                { minWidth: Breakpoints.Laptop, value: 'absolute' },
                { minWidth: Breakpoints.Mobile, value: 'absolute' },
              ],
              width: [
                { minWidth: Breakpoints.Laptop, value: 500 },
                { minWidth: Breakpoints.Mobile, value: '100%' },
              ],
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: 'rgba(0, 0, 0, 0)',
                },
                paddingTop: { minWidth: Breakpoints.Desktop, value: 20 },
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['TFA-White'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['TFA-White'],
                      },
                    ],
                    fontFamily: [
                      { minWidth: Breakpoints.Laptop, value: 'System' },
                      { minWidth: Breakpoints.Mobile, value: 'System' },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 24 },
                      { minWidth: Breakpoints.Mobile, value: 24 },
                    ],
                    fontWeight: [
                      { minWidth: Breakpoints.Laptop, value: '700' },
                      { minWidth: Breakpoints.Mobile, value: '700' },
                    ],
                    textAlign: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  }
                ),
                dimensions.width
              )}
            >
              {'Location Error'}
            </Text>
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 20 },
                borderTopWidth: { minWidth: Breakpoints.Laptop, value: 20 },
                paddingTop: { minWidth: Breakpoints.Desktop, value: 20 },
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['TFA-White'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['TFA-White'],
                      },
                    ],
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 20 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    textAlign: [
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                      { minWidth: Breakpoints.Mobile, value: 'center' },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {location_error}
            </Text>
          </View>
          {/* View 3 */}
          <View
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 20 },
                marginTop: 20,
                paddingTop: { minWidth: Breakpoints.Desktop, value: 20 },
                width: [
                  { minWidth: Breakpoints.Laptop, value: '100%' },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                ],
              },
              dimensions.width
            )}
          >
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  setShow_popup(false);
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  { width: { minWidth: Breakpoints.Laptop, value: '100%' } }
                ),
                dimensions.width
              )}
              title={'OK'}
            />
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(SignUpLogInScreenP1InProgressScreen);
