import React from 'react';
import { Button, IconButton, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AddProfilePhotoWithPhotoP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.Thriller, marginTop: 50, padding: 12 },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', justifyContent: 'space-between' },
          dimensions.width
        )}
      >
        <IconButton
          onPress={() => {
            try {
              navigation.navigate('AddProfilePhotoScreenP1InProgressScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          color={palettes.App['text placeholder']}
          icon={'AntDesign/arrowleft'}
          size={40}
        />
        <Button
          accessible={true}
          iconPosition={'left'}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              {
                backgroundColor: palettes.App['Form Field BG'],
                borderRadius: 37,
                color: palettes.App.White,
                fontFamily: 'Poppins_500Medium',
                fontSize: 15,
                height: 45,
                width: 90,
              }
            ),
            dimensions.width
          )}
          title={'Skip'}
        />
      </View>
      {/* View 2 */}
      <View style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}>
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: palettes.App.White,
              fontFamily: 'Oswald_700Bold',
              fontSize: 22,
              letterSpacing: 1,
            }),
            dimensions.width
          )}
        >
          {'Add profile photo'}
        </Text>
      </View>
      {/* View 3 */}
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', justifyContent: 'center' },
          dimensions.width
        )}
      >
        <Image
          resizeMode={'cover'}
          source={imageSource(
            'https://static.draftbit.com/images/placeholder-image.png'
          )}
          {...GlobalStyles.ImageStyles(theme)['Image'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'].style, {
              borderRadius: 100,
              height: 180,
              marginTop: 120,
              width: 180,
            }),
            dimensions.width
          )}
        />
      </View>
      {/* View 4 */}
      <View style={StyleSheet.applyWidth({ marginTop: 100 }, dimensions.width)}>
        {/* Create account button */}
        <Button
          accessible={true}
          iconPosition={'left'}
          onPress={() => {
            try {
              navigation.navigate('RootNavigator');
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              {
                backgroundColor: palettes.App.White,
                borderRadius: 37,
                color: palettes.App.Thriller,
                fontFamily: 'Poppins_500Medium',
                fontSize: 15,
                height: 45,
              }
            ),
            dimensions.width
          )}
          title={'Create account'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AddProfilePhotoWithPhotoP1InProgressScreen);
