const month_date_time = time => {
  const timestamp = time; // Replace this with your API value
  const date = new Date(Number(timestamp));

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayOfWeek = days[date.getDay()]; // Get the day name (Sun, Mon, etc.)

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure double digits for minutes
  const ampm = hours >= 12 ? 'pm' : 'am';

  // Convert 24-hour format to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // Adjust hours for 0 (midnight)

  // Format the final time string
  const formattedTime = `${dayOfWeek} ${hours}:${minutes} ${ampm}`;
  return formattedTime;
};

export default month_date_time;
