import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const VerifyYourAccountScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [textAreaValue, setTextAreaValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      {/* top-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* nav-top */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 10,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* nav-top__left */}
          <View style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}>
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'MyProfileMainP1InProgressScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              color={palettes.App['Form Placeholder Text color']}
              icon={'Feather/arrow-left'}
            />
          </View>
          {/* nav-top__center */}
          <View>
            {/* Contact us */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: '"rgb(255, 255, 255)"',
                    fontFamily: 'Oswald_700Bold',
                    fontSize: 24,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Verify your account'}
            </Text>
          </View>
          {/* nav-top__right */}
          <View
            style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
          />
        </View>
        {/* form-area */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
        >
          {/* Content */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['TFA-StatsBlockText'],
                fontFamily: 'Poppins_400Regular',
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {
              'In order to start betting you need to complete identify verification. Select a document to verify from one of the following options:'
            }
          </Text>
          {/* form-wrap */}
          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 20, marginTop: 20 },
              dimensions.width
            )}
          >
            {/* Passport */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  /* Navigate action skipped because passport is hidden */
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: palettes.App['TFA-InputBG'],
                    borderRadius: 30,
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                  }
                ),
                dimensions.width
              )}
              title={'Passport'}
            />
            {/* Identify card */}
            <Button
              accessible={true}
              iconPosition={'left'}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: palettes.App['TFA-InputBG'],
                    borderRadius: 30,
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                    marginTop: 20,
                  }
                ),
                dimensions.width
              )}
              title={'Identify card'}
            />
            {/* Driver’s licence */}
            <Button
              accessible={true}
              iconPosition={'left'}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: palettes.App['TFA-InputBG'],
                    borderRadius: 30,
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                    marginTop: 20,
                  }
                ),
                dimensions.width
              )}
              title={'Driver’s licence'}
            />
          </View>
        </View>
      </View>
      {/* bottom-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginBottom: 60, marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* disclaim */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row' },
            dimensions.width
          )}
        >
          <Icon
            color={palettes.App['#7F7685']}
            name={'AntDesign/exclamationcircleo'}
            size={18}
            style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
          />
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['#7F7685'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 12,
              }),
              dimensions.width
            )}
          >
            {
              'This information is used for identity verification only, and will be kept secure by played'
            }
          </Text>
        </View>
        {/* bottom-button */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 20,
            },
            dimensions.width
          )}
        >
          {/* Send */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                /* Navigate action skipped because passport is hidden */
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['TFA-White'],
                  borderRadius: 30,
                  color: '"rgb(15, 4, 21)"',
                  fontFamily: 'Poppins_500Medium',
                  paddingBottom: 8,
                  paddingTop: 8,
                  width: '100%',
                }
              ),
              dimensions.width
            )}
            title={'Next'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(VerifyYourAccountScreen);
