import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  ImageBackground,
  RefreshControl,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import UserBalanceViewBlock from '../components/UserBalanceViewBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import convertTimestampToDate from '../global-functions/convertTimestampToDate';
import getStatusLabel from '../global-functions/getStatusLabel';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { items: '', object: null };

const BalanceMainP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [page, setPage] = React.useState(2);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [transactions_data, setTransactions_data] = React.useState({});
  const [user_balance, setUser_balance] = React.useState('$ 0');
  const [refreshingkRLU1L70, setRefreshingkRLU1L70] = React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['TopRectangleProfileThrillerFantasy'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { height: 170 }
          ),
          dimensions.width
        )}
      >
        {/* top-view */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 30 }, dimensions.width)}
        >
          {/* top-box */}
          <View
            style={StyleSheet.applyWidth(
              { marginLeft: 20, marginRight: 20 },
              dimensions.width
            )}
          >
            {/* nav-top */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 10,
                  paddingTop: 10,
                },
                dimensions.width
              )}
            >
              {/* nav-top__left */}
              <View
                style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
              >
                <IconButton
                  onPress={() => {
                    try {
                      navigation.navigate('BottomTabNavigator');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  color={palettes.App['Form Placeholder Text color']}
                  icon={'Feather/arrow-left'}
                />
              </View>
              {/* nav-top__center */}
              <View>
                {/* Balance */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: '"rgb(255, 255, 255)"',
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 24,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Balance'}
                </Text>
              </View>
              {/* nav-top__right */}
              <View
                style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
              />
            </View>
          </View>
          {/* view-amount */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                marginTop: 20,
              },
              dimensions.width
            )}
          >
            <UserBalanceViewBlock />
          </View>
          {/* view-btns */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 20,
                marginTop: 10,
                paddingRight: 20,
              },
              dimensions.width
            )}
          >
            {/* btn-left */}
            <View
              style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
            >
              {/* refill-btn */}
              <Button
                accessible={true}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const resp = (
                        await ThrillerXanoApi.userProfileGET(Constants)
                      )?.json;
                      if (resp?._veriff_session?.status === 'approved') {
                        navigation.navigate('PurchasesListScreen');
                      } else {
                        navigation.navigate('StartVerificationScreen');
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                icon={'AntDesign/arrowup'}
                iconPosition={'right'}
                iconSize={14}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: palettes.App['Form Field BG'],
                      borderRadius: 35,
                      color: palettes.App['TFA-White'],
                      fontFamily: 'Roboto_500Medium',
                    }
                  ),
                  dimensions.width
                )}
                title={'Refill  '}
              />
            </View>
            {/* btn-right */}
            <View
              style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
            >
              {/* withdraw-btn */}
              <Button
                accessible={true}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const resp = (
                        await ThrillerXanoApi.userProfileGET(Constants)
                      )?.json;
                      if (resp?._veriff_session?.status === 'approved') {
                        navigation.navigate('WithdrawlsListScreen');
                      } else {
                        navigation.navigate('StartVerificationScreen');
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                icon={'AntDesign/arrowdown'}
                iconPosition={'right'}
                iconSize={14}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: palettes.App['TFA-White'],
                      borderRadius: 35,
                      color: palettes.App.Thriller,
                      fontFamily: 'Roboto_500Medium',
                    }
                  ),
                  dimensions.width
                )}
                title={'Withdraw   '}
              />
            </View>
          </View>
        </View>
        {/* view-transactions */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginRight: 20, marginTop: 30 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['headling-19'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['headling-19'].style,
                { fontFamily: 'Roboto_400Regular', fontSize: 24 }
              ),
              dimensions.width
            )}
          >
            {'Transactions'}
          </Text>

          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth(
              { marginBottom: 50, marginTop: 10, paddingBottom: 300 },
              dimensions.width
            )}
          >
            <ThrillerXanoApi.FetchBalanceDetailsGET
              handlers={{
                onData: fetchData => {
                  try {
                    setTransactions_data(fetchData?.items);
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
              page={1}
            >
              {({ loading, error, data, refetchBalanceDetails }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <SimpleStyleFlatList
                    data={transactions_data}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'kRLU1L70'}
                    nestedScrollEnabled={false}
                    onEndReached={() => {
                      const handler = async () => {
                        try {
                          if (fetchData?.nextPage === null) {
                            return;
                          }
                          setPage(page + 1);
                          const newData = (
                            await ThrillerXanoApi.balanceDetailsGET(Constants, {
                              page: page,
                            })
                          )?.json;
                          setTransactions_data(
                            transactions_data.concat(newData?.items)
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    refreshControl={
                      <RefreshControl
                        refreshing={refreshingkRLU1L70}
                        onRefresh={() => {
                          try {
                            setRefreshingkRLU1L70(true);
                            setPage(1);
                            setRefreshingkRLU1L70(false);
                          } catch (err) {
                            console.error(err);
                            setRefreshingkRLU1L70(false);
                          }
                        }}
                      />
                    }
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* block-refill */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.App['#231C27'],
                                borderRadius: 16,
                                marginTop: 10,
                                padding: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* block-top */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                },
                                dimensions.width
                              )}
                            >
                              {/* block-left */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* block-icon */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor: [
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: palettes.App['TFA-White'],
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value:
                                            listData?.transaction_type ===
                                            'debit'
                                              ? palettes.App['TFA-Green']
                                              : palettes.App['Custom Color_8'],
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value:
                                            listData?.transaction_type ===
                                            'credit'
                                              ? palettes.App['TFA-Green']
                                              : palettes.App['Custom Color_8'],
                                        },
                                      ],
                                      borderRadius: 50,
                                      height: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 32,
                                        },
                                      ],
                                      justifyContent: 'center',
                                      marginRight: 10,
                                      width: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 32,
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Icon
                                    name={
                                      listData?.currency_type === 'money'
                                        ? 'Ionicons/logo-usd'
                                        : 'FontAwesome/plus'
                                    }
                                    size={12}
                                  />
                                </View>
                                {/* Title */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'poppins-14-medium-white'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'poppins-14-medium-white'
                                      ].style,
                                      {
                                        color: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value:
                                              listData?.transaction_type ===
                                              'debit'
                                                ? palettes.App['TFA-Green']
                                                : palettes.App[
                                                    'Custom Color_8'
                                                  ],
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value:
                                              listData?.transaction_type ===
                                              'credit'
                                                ? palettes.App['TFA-Green']
                                                : palettes.App[
                                                    'Custom Color_8'
                                                  ],
                                          },
                                        ],
                                        fontFamily: 'Roboto_500Medium',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {getStatusLabel(
                                    listData?.entry_type,
                                    listData?.currency_type
                                  )}
                                </Text>
                              </View>
                              {/* block-right */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* currency */}
                                <View>
                                  {/* $ */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'poppins-14-medium-white'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          'poppins-14-medium-white'
                                        ].style,
                                        {
                                          color: [
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                listData?.transaction_type ===
                                                'debit'
                                                  ? palettes.App['TFA-Green']
                                                  : palettes.App[
                                                      'Custom Color_8'
                                                    ],
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value:
                                                listData?.transaction_type ===
                                                'credit'
                                                  ? palettes.App['TFA-Green']
                                                  : palettes.App[
                                                      'Custom Color_8'
                                                    ],
                                            },
                                          ],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {' '}
                                    {listData?.transaction_type === 'credit'
                                      ? '+'
                                      : '-'}
                                    {' $'}
                                  </Text>
                                </View>
                                {/* amount */}
                                <View>
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'poppins-14-medium-white'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          'poppins-14-medium-white'
                                        ].style,
                                        {
                                          color: [
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                listData?.transaction_type ===
                                                'debit'
                                                  ? palettes.App['TFA-Green']
                                                  : palettes.App[
                                                      'Custom Color_8'
                                                    ],
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value:
                                                listData?.transaction_type ===
                                                'credit'
                                                  ? palettes.App['TFA-Green']
                                                  : palettes.App[
                                                      'Custom Color_8'
                                                    ],
                                            },
                                          ],
                                          fontFamily: [
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: 'Poppins_700Bold',
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'Roboto_500Medium',
                                            },
                                          ],
                                          fontSize: {
                                            minWidth: Breakpoints.Tablet,
                                            value: 18,
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.amount}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {/* block-bottom */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 10 },
                                dimensions.width
                              )}
                            >
                              {/* Date */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'poppins-14-medium-white'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'poppins-14-medium-white'
                                    ].style,
                                    {
                                      color: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: palettes.App['#7F7685'],
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: palettes.App['TFA-White'],
                                        },
                                      ],
                                      fontFamily: 'Roboto_500Medium',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {convertTimestampToDate(listData?.created_at)}
                              </Text>
                            </View>
                          </View>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    initialNumToRender={20}
                    numColumns={1}
                    onEndReachedThreshold={0.1}
                    scrollEnabled={true}
                    style={StyleSheet.applyWidth(
                      { marginTop: 20, paddingBottom: 100 },
                      dimensions.width
                    )}
                  />
                );
              }}
            </ThrillerXanoApi.FetchBalanceDetailsGET>
          </SimpleStyleScrollView>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(BalanceMainP1InProgressScreen);
