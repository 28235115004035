import React from 'react';
import {
  Button,
  IconButton,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openCameraUtil from '../utils/openCamera';
import useWindowDimensions from '../utils/useWindowDimensions';

const ProfileEditProfilePhotoP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [emailValue, setEmailValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const thrillerXanoUploadUserPicturePOST =
    ThrillerXanoApi.useUploadUserPicturePOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setEmailValue(Constants['User']?.email);
      setTextInputValue2(Constants['User']?.first_name);
      setTextInputValue(Constants['User']?.last_name);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      {/* top-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* nav-top */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 10,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* nav-top__left */}
          <View style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}>
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'MyProfileMainP1InProgressScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              color={palettes.App['Form Placeholder Text color']}
              icon={'Feather/arrow-left'}
            />
          </View>
          {/* nav-top__center */}
          <View>
            {/* Edit profile */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: '"rgb(255, 255, 255)"',
                    fontFamily: 'Oswald_700Bold',
                    fontSize: 24,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Edit profile'}
            </Text>
          </View>
          {/* nav-top__right */}
          <View
            style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
          />
        </View>
        {/* profile */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
        >
          {/* profile__edit-photo */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            {/* Image 2 */}
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(`${Constants['User']?.profile_picture?.url}`)}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['GetFit Orange'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['GetFit Orange'],
                      },
                    ],
                    borderRadius: [
                      { minWidth: Breakpoints.Laptop, value: 100 },
                      { minWidth: Breakpoints.Mobile, value: 100 },
                    ],
                    borderWidth: [
                      { minWidth: Breakpoints.Laptop, value: 5 },
                      { minWidth: Breakpoints.Mobile, value: 5 },
                    ],
                  }
                ),
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-Pink'],
                    fontFamily: 'Poppins_500Medium',
                    marginTop: 20,
                  }
                ),
                dimensions.width
              )}
            >
              {'Edit photo'}
            </Text>
          </View>
          {/* nav-dd */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderRadius: 12,
                left: '50%',
                marginLeft: -100,
                marginTop: -20,
                position: 'absolute',
                top: '100%',
                zIndex: 20,
              },
              dimensions.width
            )}
          >
            {/* nav-dd__wrap */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: '"rgb(35, 28, 39)"',
                  borderRadius: 12,
                  padding: 12,
                  width: 200,
                },
                dimensions.width
              )}
            >
              {/* nav-dd__item-1 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row' },
                  dimensions.width
                )}
              >
                {/* btn-content */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const user_image = await openCameraUtil({
                          mediaTypes: 'Images',
                          allowsEditing: false,
                          cameraType: 'front',
                          videoMaxDuration: undefined,
                          quality: 0.2,
                          permissionErrorMessage:
                            'Sorry, we need camera permissions to make this work.',
                          showAlertOnPermissionError: true,
                          outputBase64: true,
                        });

                        const result_user = (
                          await thrillerXanoUploadUserPicturePOST.mutateAsync({
                            profile_picture: user_image,
                          })
                        )?.json;
                        console.log(result_user);
                        setGlobalVariableValue({
                          key: 'User',
                          value: result_user,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  icon={'EvilIcons/image'}
                  iconSize={24}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: null,
                        color: palettes.App['TFA-White'],
                        fontFamily: 'Poppins_400Regular',
                        height: 20,
                        padding: 0,
                        paddingBottom: 0,
                        paddingTop: 0,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Choose photo'}
                />
              </View>
            </View>
          </View>
        </View>
        {/* edit-form */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 20, zIndex: -1 },
            dimensions.width
          )}
        >
          {/* edit-form__item-1 */}
          <View>
            {/* label-first-name */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Poppins_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'First Name'}
            </Text>
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newTextInputValue => {
                try {
                  setTextInputValue2(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Enter a value...'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              editable={false}
              placeholderTextColor={palettes.App['Form Placeholder Text color']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    backgroundColor: palettes.App['Form Field BG'],
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRadius: 30,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    borderWidth: 0,
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                    paddingBottom: 12,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 12,
                  }
                ),
                dimensions.width
              )}
              value={textInputValue2}
            />
          </View>
          {/* edit-form__item-2 */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* label-last-name */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Poppins_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'Last Name'}
            </Text>
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newTextInputValue => {
                const textInputValue = newTextInputValue;
                try {
                  setTextInputValue(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Enter a value...'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              editable={false}
              placeholderTextColor={palettes.App['Form Placeholder Text color']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    backgroundColor: palettes.App['Form Field BG'],
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRadius: 30,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    borderWidth: 0,
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                    paddingBottom: 12,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 12,
                  }
                ),
                dimensions.width
              )}
              value={textInputValue}
            />
          </View>
          {/* edit-form__item-3 */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* label-email */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Poppins_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'Email'}
            </Text>
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newTextInputValue => {
                try {
                  setEmailValue(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Enter a value...'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              editable={false}
              placeholderTextColor={palettes.App['Form Placeholder Text color']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    backgroundColor: palettes.App['Form Field BG'],
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRadius: 30,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    borderWidth: 0,
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                    paddingBottom: 12,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 12,
                  }
                ),
                dimensions.width
              )}
              value={emailValue}
            />
          </View>
        </View>
      </View>
      {/* bottom-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginBottom: 60, marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* bottom-buttons */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', justifyContent: 'space-between' },
            dimensions.width
          )}
        >
          {/* Save changes */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['TFA-White'],
                  borderRadius: 30,
                  color: '"rgb(15, 4, 21)"',
                  fontFamily: 'Poppins_500Medium',
                  paddingBottom: 8,
                  paddingTop: 8,
                  width: { minWidth: Breakpoints.Laptop, value: '100%' },
                }
              ),
              dimensions.width
            )}
            title={'Done'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ProfileEditProfilePhotoP1InProgressScreen);
