import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  LoadingIndicator,
  NumberInput,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import completeDateTime from '../global-functions/completeDateTime';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getLocationUtil from '../utils/getLocation';
import imageSource from '../utils/imageSource';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const CurrentEntryP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [current_above_below_id, setCurrent_above_below_id] = React.useState(0);
  const [current_entry_fee, setCurrent_entry_fee] = React.useState(0);
  const [entry_error, setEntry_error] = React.useState('');
  const [error_message, setError_message] = React.useState('');
  const [is_above_below_error, setIs_above_below_error] = React.useState(false);
  const [is_place_entry_error, setIs_place_entry_error] = React.useState(false);
  const [processing_id, setProcessing_id] = React.useState(0);
  const [user_balance, setUser_balance] = React.useState('');
  const [user_points, setUser_points] = React.useState('');
  const thrillerXanoUpdateEntryFeePATCH =
    ThrillerXanoApi.useUpdateEntryFeePATCH();
  const thrillerXanoEditLineupSelectionPATCH =
    ThrillerXanoApi.useEditLineupSelectionPATCH();
  const thrillerXanoEditLineupPATCH = ThrillerXanoApi.useEditLineupPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setEntry_error('');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['TFA-ScreenBG'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['TopRectangleProfileThrillerFantasy'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { height: 170 }
          ),
          dimensions.width
        )}
      >
        <SimpleStyleScrollView
          bounces={true}
          horizontal={false}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
        >
          {/* container */}
          <View
            style={StyleSheet.applyWidth(
              { marginLeft: 20, marginRight: 20, marginTop: 30 },
              dimensions.width
            )}
          >
            <ThrillerXanoApi.FetchCurrentLineupGET
              handlers={{
                onData: fetchData => {
                  try {
                    setCurrent_entry_fee(fetchData?.entry_fee);
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
            >
              {({ loading, error, data, refetchCurrentLineup }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* top-box */}
                    <View>
                      {/* nav-top */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingBottom: 10,
                            paddingTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* nav-top__left */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: 65 },
                            dimensions.width
                          )}
                        >
                          <IconButton
                            onPress={() => {
                              try {
                                navigation.navigate('BottomTabNavigator', {
                                  screen: 'FeedMainP1InProgressScreen',
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            size={32}
                            color={palettes.App['Form Placeholder Text color']}
                            icon={'Feather/arrow-left'}
                          />
                        </View>
                        {/* nav-top__center */}
                        <View>
                          {/* Current entry */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App['TFA-White'],
                                  fontFamily: 'Roboto_700Bold',
                                  fontSize: 24,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Current entry'}
                          </Text>
                        </View>
                        {/* nav-top__right */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: 100 },
                            dimensions.width
                          )}
                        />
                      </View>
                    </View>
                    {/* entry-payout */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginTop: 40,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['Form Field BG'],
                            borderColor: error_message
                              ? palettes.App['#FF4141']
                              : undefined,
                            borderRadius: 16,
                            borderWidth: 1,
                            flex: 1,
                            justifyContent: 'space-between',
                            padding: 16,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['headling-19']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['headling-19']
                                  .style,
                                {
                                  alignSelf: 'flex-start',
                                  fontFamily: 'Oswald_700Bold',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'$'}
                          </Text>
                          {/* Enter Amount */}
                          <NumberInput
                            onChangeText={newEnterAmountValue => {
                              const handler = async () => {
                                try {
                                  setCurrent_entry_fee(newEnterAmountValue);
                                  const resp = (
                                    await thrillerXanoUpdateEntryFeePATCH.mutateAsync(
                                      {
                                        entry_fee: newEnterAmountValue,
                                        lineup_id: fetchData?.id,
                                      }
                                    )
                                  )?.json;
                                  if (
                                    extractErrorFromResponse(resp) !== 'false'
                                  ) {
                                    setError_message(
                                      extractErrorFromResponse(resp)
                                    );
                                    setCurrent_entry_fee(newEnterAmountValue);
                                  } else {
                                    setError_message('');
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            webShowOutline={true}
                            {...GlobalStyles.NumberInputStyles(theme)[
                              'Number Input'
                            ].props}
                            changeTextDelay={0}
                            defaultValue={fetchData?.entry_fee}
                            maxLength={4}
                            placeholder={'Enter Amount'}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.NumberInputStyles(theme)[
                                  'Number Input'
                                ].style,
                                {
                                  alignSelf: 'auto',
                                  borderBottomWidth: null,
                                  borderLeftWidth: null,
                                  borderRightWidth: null,
                                  borderTopWidth: null,
                                  color: palettes.App.White,
                                  fontFamily: 'Oswald_400Regular',
                                  fontSize: 19,
                                  paddingBottom: null,
                                  paddingLeft: null,
                                  paddingRight: null,
                                  paddingTop: null,
                                  width: 90,
                                }
                              ),
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['body-content']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['body-content']
                                .style,
                              { marginTop: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Entry'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['TFA-White'],
                            borderRadius: 16,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginLeft: 10,
                            marginRight: 10,
                            paddingBottom: 10,
                            paddingTop: 10,
                            width: 60,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['headling-19']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['headling-19']
                                .style,
                              {
                                color: palettes.App.Thriller,
                                fontFamily: 'Oswald_600SemiBold',
                                lineHeight: 22,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'X '}
                          {fetchData?.multiplier}
                        </Text>
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['Form Field BG'],
                            borderRadius: 16,
                            flex: 1,
                            justifyContent: 'space-between',
                            padding: 16,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['headling-19']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['headling-19']
                                .style,
                              { fontFamily: 'Oswald_700Bold' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'$'}
                          {fetchData?.payout}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['body-content']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['body-content']
                                .style,
                              { marginTop: 10 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Payout'}
                        </Text>
                      </View>
                    </View>
                    {/* Error Message */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 10, marginTop: 10 },
                        dimensions.width
                      )}
                    >
                      {/* error */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['#FF4141'],
                              fontFamily: 'Poppins_400Regular',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {error_message}
                      </Text>
                    </View>
                    {/* btc-wrap */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 20 },
                        dimensions.width
                      )}
                    >
                      <SimpleStyleFlatList
                        data={fetchData?._lineup_selections}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'oOJ8uDeM'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* btc-2 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderRadius: 16,
                                    marginBottom: 10,
                                    marginTop: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <ImageBackground
                                  resizeMode={'cover'}
                                  {...GlobalStyles.ImageBackgroundStyles(theme)[
                                    'Image Background'
                                  ].props}
                                  source={imageSource(Images['BettingCardBg'])}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageBackgroundStyles(theme)[
                                        'Image Background'
                                      ].style,
                                      {
                                        borderRadius: 16,
                                        overflow: 'hidden',
                                        padding: 16,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* btc-top */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* btc-top__left */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flexDirection: 'row' },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginRight: 10 },
                                          dimensions.width
                                        )}
                                      >
                                        <Image
                                          resizeMode={'cover'}
                                          {...GlobalStyles.ImageStyles(theme)[
                                            'Image'
                                          ].props}
                                          source={imageSource(
                                            listData?._player_props
                                              ?._player_data?.player_image?.url
                                              ? listData?._player_props
                                                  ?._player_data?.player_image
                                                  ?.url
                                              : Images['jerseyremovebgpreview']
                                          )}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ImageStyles(theme)[
                                                'Image'
                                              ].style,
                                              {
                                                borderRadius: 50,
                                                height: 48,
                                                width: 48,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        />
                                      </View>
                                      {/* View 2 */}
                                      <View>
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* player_name */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'poppins-16-bold-white'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'poppins-16-bold-white'
                                                ].style,
                                                {
                                                  fontFamily:
                                                    'Roboto_500Medium',
                                                  fontSize: 18,
                                                  marginRight: 10,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              listData?._player_props
                                                ?._player_data?.name
                                            }
                                          </Text>
                                          {/* detail */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'body-content'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'body-content'
                                                ].style,
                                                {
                                                  color:
                                                    palettes.App['TFA-White'],
                                                  fontFamily:
                                                    'Roboto_400Regular',
                                                  fontSize: 12,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              listData?._player_props
                                                ?.player_team?.alias
                                            }
                                            {' - '}
                                            {
                                              listData?._player_props
                                                ?._player_data?.position
                                            }
                                          </Text>
                                        </View>
                                        {/* View 2 */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexDirection: 'row',
                                              marginBottom: 3,
                                              marginTop: 5,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* View 3 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'flex-start',
                                                alignSelf: 'flex-start',
                                                backgroundColor:
                                                  palettes.App['BG Gray'],
                                                borderRadius: 5,
                                                marginRight: 3,
                                                padding: 3,
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* league_name */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  {
                                                    color:
                                                      palettes.App['TFA-White'],
                                                    fontFamily:
                                                      'Roboto_400Regular',
                                                    fontSize: 9,
                                                    textAlign: 'center',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                listData?._player_props
                                                  ?._player_data?._leagues
                                                  ?.alias
                                              }
                                            </Text>
                                          </View>

                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'body-content'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'body-content'
                                                ].style,
                                                {
                                                  alignSelf: 'center',
                                                  color:
                                                    palettes.App['TFA-White'],
                                                  fontFamily:
                                                    'Roboto_400Regular',
                                                  fontSize: 11,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {completeDateTime(
                                              listData?._player_props
                                                ?._seasons_schedule_nfl
                                                ?.scheduled
                                            )}
                                            {' vs '}
                                            {
                                              listData?._player_props
                                                ?.opponent_team?.alias
                                            }
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                    {/* btc-top__right */}
                                    <View>
                                      {/* remove player */}
                                      <Button
                                        accessible={true}
                                        iconPosition={'left'}
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'FeedRemovePlayerP1InProgressScreen',
                                              {
                                                lineup_id: listData?.id,
                                                player_name:
                                                  listData?._player_props
                                                    ?._player_data?.name,
                                                player_image:
                                                  listData?._player_props
                                                    ?._player_data?.player_image
                                                    ?.url,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        {...GlobalStyles.ButtonStyles(theme)[
                                          'Button'
                                        ].props}
                                        icon={'AntDesign/close'}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.ButtonStyles(theme)[
                                              'Button'
                                            ].style,
                                            {
                                              backgroundColor:
                                                palettes.App['Form Field BG'],
                                              borderRadius: 50,
                                              height: 40,
                                              paddingBottom: 1,
                                              paddingRight: 8,
                                              width: 40,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                        title={''}
                                      />
                                    </View>
                                  </View>
                                  {/* btc-bottom */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginTop: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flexDirection: 'row' },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            backgroundColor:
                                              palettes.App['Form Field BG'],
                                            borderRadius: 35,
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            paddingBottom: 8,
                                            paddingLeft: 14,
                                            paddingRight: 14,
                                            paddingTop: 8,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'poppins-14-regular-white'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'poppins-14-regular-white'
                                              ].style,
                                              {
                                                fontFamily: 'Roboto_400Regular',
                                                fontSize: 10,
                                                marginRight: 10,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {
                                            listData?._player_props?._market
                                              ?.title
                                          }
                                        </Text>
                                        {/* Text 2 */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'headling-19'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'headling-19'
                                              ].style,
                                              {
                                                fontFamily: 'Roboto_700Bold',
                                                fontSize: 16,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {
                                            listData?._player_props
                                              ?.projection_point
                                          }
                                        </Text>
                                      </View>
                                    </View>
                                    {/* View 2 */}
                                    <>
                                      {!(processing_id === listData?.id
                                        ? true
                                        : false) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { alignItems: 'center' },
                                            dimensions.width
                                          )}
                                        >
                                          <LoadingIndicator
                                            color={
                                              theme.colors.branding.primary
                                            }
                                            size={30}
                                            style={StyleSheet.applyWidth(
                                              {
                                                marginLeft: 15,
                                                marginRight: 15,
                                              },
                                              dimensions.width
                                            )}
                                            type={'circle'}
                                          />
                                        </View>
                                      )}
                                    </>
                                    {/* View 2 */}
                                    <>
                                      {(
                                        processing_id === listData?.id
                                          ? true
                                          : false
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexDirection: 'row',
                                              justifyContent: 'flex-end',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Button
                                            accessible={true}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setProcessing_id(
                                                    listData?.id
                                                  );
                                                  const resp = (
                                                    await thrillerXanoEditLineupSelectionPATCH.mutateAsync(
                                                      {
                                                        lineup_id:
                                                          listData?.lineup_id,
                                                        lineup_selections_id:
                                                          listData?.id,
                                                        selection: 'Below',
                                                      }
                                                    )
                                                  )?.json;
                                                  if (
                                                    extractErrorFromResponse(
                                                      resp === 'false'
                                                    )
                                                  ) {
                                                  } else {
                                                    showAlertUtil({
                                                      title: 'Oops',
                                                      message:
                                                        'Something Went Wrong',
                                                      buttonText: undefined,
                                                    });
                                                  }

                                                  setProcessing_id(0);
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            {...GlobalStyles.ButtonStyles(
                                              theme
                                            )['Button'].props}
                                            icon={'Ionicons/caret-down'}
                                            iconPosition={'right'}
                                            iconSize={20}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ButtonStyles(
                                                  theme
                                                )['Button'].style,
                                                {
                                                  backgroundColor: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        palettes.App[
                                                          'Form Field BG'
                                                        ],
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        listData?.less_color
                                                          ? palettes.App[
                                                              'Custom Color_21'
                                                            ]
                                                          : undefined,
                                                    },
                                                  ],
                                                  borderRadius: 35,
                                                  borderStyle: 'solid',
                                                  color: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        palettes.App[
                                                          'TFA-White'
                                                        ],
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        listData?.less_color
                                                          ? palettes.App[
                                                              '#FF4141'
                                                            ]
                                                          : undefined,
                                                    },
                                                  ],
                                                  fontFamily:
                                                    'Roboto_500Medium',
                                                  fontSize: 12,
                                                  marginRight: 5,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            title={'Below'}
                                          />
                                          {/* Button 2 */}
                                          <Button
                                            accessible={true}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setProcessing_id(
                                                    listData?.id
                                                  );
                                                  (
                                                    await thrillerXanoEditLineupSelectionPATCH.mutateAsync(
                                                      {
                                                        lineup_id:
                                                          listData?.lineup_id,
                                                        lineup_selections_id:
                                                          listData?.id,
                                                        selection: 'Above',
                                                      }
                                                    )
                                                  )?.json;
                                                  setProcessing_id(0);
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            {...GlobalStyles.ButtonStyles(
                                              theme
                                            )['Button'].props}
                                            icon={'Ionicons/caret-up-outline'}
                                            iconPosition={'right'}
                                            iconSize={20}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ButtonStyles(
                                                  theme
                                                )['Button'].style,
                                                {
                                                  backgroundColor: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        palettes.App[
                                                          'Form Field BG'
                                                        ],
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        listData?.more_color
                                                          ? palettes.App[
                                                              'Custom Color_20'
                                                            ]
                                                          : undefined,
                                                    },
                                                  ],
                                                  borderRadius: 35,
                                                  color: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        palettes.App[
                                                          'TFA-White'
                                                        ],
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value:
                                                        listData?.more_color
                                                          ? palettes.App[
                                                              'TFA-Green'
                                                            ]
                                                          : undefined,
                                                    },
                                                  ],
                                                  fontFamily:
                                                    'Roboto_500Medium',
                                                  fontSize: 12,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            title={'Above'}
                                          />
                                        </View>
                                      )}
                                    </>
                                  </View>
                                </ImageBackground>
                              </View>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                      <>
                        {!entry_error ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 10, marginTop: 10 },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    alignSelf: 'center',
                                    color: palettes.App['#FF4141'],
                                    fontFamily: 'Poppins_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {entry_error}
                            </Text>
                          </View>
                        )}
                      </>
                    </View>
                    {/* bottom-btn-panel */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          marginBottom: 100,
                          marginTop: 30,
                          paddingLeft: 20,
                          paddingRight: 20,
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!is_place_entry_error ? null : (
                          <LoadingIndicator
                            color={theme.colors.branding.primary}
                            size={40}
                            type={'wave'}
                          />
                        )}
                      </>
                      {/* btn-place-entry */}
                      <>
                        {is_place_entry_error ? null : (
                          <Button
                            accessible={true}
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  if (error_message !== '') {
                                    if (error_message !== '') {
                                      return;
                                    }
                                  } else {
                                  }

                                  const location = await getLocationUtil({
                                    accuracy: 'Low',
                                  });
                                  if (location) {
                                    setIs_place_entry_error(true);
                                    const response = (
                                      await thrillerXanoEditLineupPATCH.mutateAsync(
                                        {
                                          entry_fee: current_entry_fee,
                                          lang: location?.longitude,
                                          lat: location?.latitude,
                                          lineup_id: fetchData?.id,
                                        }
                                      )
                                    )?.json;
                                    if (
                                      extractErrorFromResponse(response) !==
                                      'false'
                                    ) {
                                      setEntry_error(
                                        extractErrorFromResponse(response)
                                      );
                                    } else {
                                      navigation.navigate(
                                        'BottomTabNavigator',
                                        {
                                          screen:
                                            'EntriesOpenedP1InProgress2Screen',
                                        }
                                      );
                                    }

                                    setIs_place_entry_error(false);
                                  } else {
                                    setEntry_error(
                                      'We are not able to detect your location'
                                    );
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            iconSize={20}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button']
                                  .style,
                                {
                                  backgroundColor: palettes.App['TFA-White'],
                                  borderRadius: 30,
                                  color: '"rgb(15, 4, 21)"',
                                  fontFamily: 'Poppins_500Medium',
                                  paddingBottom: 8,
                                  paddingTop: 8,
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Place entry'}
                          />
                        )}
                      </>
                    </View>
                  </>
                );
              }}
            </ThrillerXanoApi.FetchCurrentLineupGET>
          </View>
        </SimpleStyleScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(CurrentEntryP1InProgressScreen);
