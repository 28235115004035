import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openCameraUtil from '../utils/openCamera';
import useWindowDimensions from '../utils/useWindowDimensions';

const TakeAPhoto1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [textAreaValue, setTextAreaValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      {/* top-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* nav-top */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 10,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* nav-top__left */}
          <View style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}>
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'MyProfileMainP1InProgressScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              color={palettes.App['Form Placeholder Text color']}
              icon={'Feather/arrow-left'}
            />
          </View>
          {/* nav-top__center */}
          <View>
            {/* Contact us */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: '"rgb(255, 255, 255)"',
                    fontFamily: 'Oswald_700Bold',
                    fontSize: 24,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Take a photo'}
            </Text>
          </View>
          {/* nav-top__right */}
          <View
            style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
          />
        </View>
        {/* content-wrap */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
        >
          {/* Content */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['TFA-StatsBlockText'],
                fontFamily: 'Poppins_400Regular',
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {
              'Upload a photo of yourself holding your ID document. Photo requirements:'
            }
          </Text>
        </View>
        {/* list */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['#231C27'],
              borderRadius: 16,
              marginTop: 20,
              padding: 16,
            },
            dimensions.width
          )}
        >
          {/* list-item-1 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', paddingBottom: 10, paddingTop: 10 },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              color={palettes.App['#D812AE']}
              name={'Entypo/dot-single'}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['body-content'].style,
                  { color: palettes.App['TFA-White'], lineHeight: 22 }
                ),
                dimensions.width
              )}
            >
              {'Take a selfie with a neutral expression'}
            </Text>
          </View>
          {/* list-item-2 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', paddingBottom: 10, paddingTop: 10 },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              color={palettes.App['#D812AE']}
              name={'Entypo/dot-single'}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['body-content'].style,
                  { color: palettes.App['TFA-White'], lineHeight: 22 }
                ),
                dimensions.width
              )}
            >
              {
                'Make sure your whole face is visible, centred, and your eyes are open'
              }
            </Text>
          </View>
          {/* list-item-3 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', paddingBottom: 10, paddingTop: 10 },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              color={palettes.App['#D812AE']}
              name={'Entypo/dot-single'}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['body-content'].style,
                  { color: palettes.App['TFA-White'], lineHeight: 22 }
                ),
                dimensions.width
              )}
            >
              {'Do not crop your ID or screenshots of ID'}
            </Text>
          </View>
          {/* list-item-4 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', paddingBottom: 10, paddingTop: 10 },
              dimensions.width
            )}
          >
            <Icon
              size={24}
              color={palettes.App['#D812AE']}
              name={'Entypo/dot-single'}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['body-content'].style,
                  { color: palettes.App['TFA-White'], lineHeight: 22 }
                ),
                dimensions.width
              )}
            >
              {'Do not hide or alter parts of your face'}
            </Text>
          </View>
        </View>
        {/* form-area */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
        >
          {/* form-wrap */}
          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 20, marginTop: 20 },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App['#231C27'],
                  borderColor: palettes.App['TFA-Pink'],
                  borderRadius: 16,
                  borderStyle: 'dashed',
                  borderWidth: 1,
                  height: 200,
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={palettes.App['TFA-Pink']}
                name={'AntDesign/smile-circle'}
              />
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      await openCameraUtil({
                        mediaTypes: 'Images',
                        allowsEditing: false,
                        cameraType: 'front',
                        videoMaxDuration: undefined,
                        quality: 0.2,
                        permissionErrorMessage:
                          'Sorry, we need camera permissions to make this work.',
                        showAlertOnPermissionError: true,
                        outputBase64: true,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: null,
                      color: palettes.App['TFA-StatsBlockText'],
                      fontFamily: 'Poppins_400Regular',
                      height: 20,
                      marginTop: 20,
                      padding: 0,
                      paddingBottom: 0,
                      paddingTop: 0,
                    }
                  ),
                  dimensions.width
                )}
                title={'Tap to upload a selfie'}
              />
            </View>
          </View>
        </View>
      </View>
      {/* bottom-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginBottom: 60, marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* bottom-button */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 20,
            },
            dimensions.width
          )}
        >
          {/* Send */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('TakeAPhoto2Screen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['TFA-White'],
                  borderRadius: 30,
                  color: '"rgb(15, 4, 21)"',
                  fontFamily: 'Poppins_500Medium',
                  paddingBottom: 8,
                  paddingTop: 8,
                  width: '100%',
                }
              ),
              dimensions.width
            )}
            title={'Complete'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(TakeAPhoto1Screen);
