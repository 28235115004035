// This import is required if you are defining react components in this module.
import React from 'react';

// Add any other imports you need here. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section.
import { useState, useEffect } from 'react';
import { Text, View, StyleSheet } from 'react-native';

// Define and export your components as named exports here.

function CountdownTimer({ unixTimes }) {
  let unixTime = unixTimes; // Don't reassign unixTime later

  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    // Create a separate variable to handle milliseconds conversion
    let time = unixTime;

    // Check if Unix time is in milliseconds or seconds
    if (time.toString().length === 13) {
      time = Math.floor(time / 1000); // Convert milliseconds to seconds
    }

    const targetTime = time * 1000; // Convert Unix time to milliseconds

    function updateCountdown() {
      const currentTime = Date.now();
      const timeRemaining = Math.floor((targetTime - currentTime) / 1000);

      if (timeRemaining >= 0) {
        const h = Math.floor(timeRemaining / 3600);
        const m = Math.floor((timeRemaining % 3600) / 60);
        const s = timeRemaining % 60;
        const formattedTime = `${h.toString().padStart(2, '0')}:${m
          .toString()
          .padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
        setTimeLeft(formattedTime); // Update the state with the formatted time
      } else {
        setTimeLeft("Time's up!"); // Set to "Time's up!" when countdown finishes
      }
    }

    // Start the interval to update the countdown every second
    const intervalId = setInterval(updateCountdown, 1000);

    // Return initial value immediately
    updateCountdown();

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [unixTime]); // Dependency list includes unixTime

  return (
    <View style={styles.container}>
      <Text style={styles.timerText}>{timeLeft}</Text>
    </View>
  );
}

// Define styles using StyleSheet
const styles = StyleSheet.create({
  container: {
    // You can add any additional container styles here if needed
  },
  timerText: {
    fontSize: 20, // Set font size to 20px
    fontFamily: 'roboto-regular', // Use Poppins font
    marginTop: 5, // Add margin top of 5pt (5px equivalent)
    color: 'white',
  },
});

// You can use components exported from this file within a Custom Code component as
// <CustomCode.MyExampleComponent />
export { CountdownTimer };
