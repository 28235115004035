import React from 'react';
import {
  Button,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openCameraUtil from '../utils/openCamera';
import useWindowDimensions from '../utils/useWindowDimensions';

const AddProfilePhotoScreenP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const thrillerXanoUploadUserPicturePOST =
    ThrillerXanoApi.useUploadUserPicturePOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.Thriller,
          marginTop: 50,
          padding: [
            { minWidth: Breakpoints.Tablet, value: 12 },
            { minWidth: Breakpoints.Mobile, value: 12 },
          ],
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', justifyContent: 'flex-end' },
          dimensions.width
        )}
      >
        <Button
          accessible={true}
          onPress={() => {
            try {
              navigation.navigate('LogInMainScreenP1InProgressScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          iconPosition={'right'}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              {
                backgroundColor: palettes.App['Form Field BG'],
                borderRadius: 37,
                color: palettes.App.White,
                fontFamily: 'Poppins_500Medium',
                fontSize: 15,
                height: 45,
                position: 'relative',
                width: 90,
              }
            ),
            dimensions.width
          )}
          title={'Skip'}
        />
      </View>
      {/* View 2 */}
      <View style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}>
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: palettes.App.White,
              fontFamily: 'Oswald_700Bold',
              fontSize: 22,
              letterSpacing: 1,
            }),
            dimensions.width
          )}
        >
          {'Add profile photo'}
        </Text>
      </View>
      {/* View 3 */}
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', justifyContent: 'center' },
          dimensions.width
        )}
      >
        <Touchable
          onPress={() => {
            const handler = async () => {
              try {
                const pickerValue = await openCameraUtil({
                  mediaTypes: 'Images',
                  allowsEditing: true,
                  cameraType: 'front',
                  videoMaxDuration: undefined,
                  quality: 0.2,
                  permissionErrorMessage:
                    'Sorry, we need camera permissions to make this work.',
                  showAlertOnPermissionError: true,
                  outputBase64: true,
                });

                setGlobalVariableValue({
                  key: 'imageUrl',
                  value: pickerValue,
                });
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth({ marginTop: 40 }, dimensions.width)}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App.Studily_Silver_White}
              name={'MaterialIcons/add'}
              size={40}
              style={StyleSheet.applyWidth(
                { position: 'absolute', zIndex: 5 },
                dimensions.width
              )}
            />
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(`${Constants['imageUrl']}`)}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    borderRadius: 100,
                    height: 180,
                    position: 'relative',
                    width: 180,
                  }
                ),
                dimensions.width
              )}
            />
          </View>
        </Touchable>
      </View>
      {/* View 4 */}
      <View style={StyleSheet.applyWidth({ marginTop: 100 }, dimensions.width)}>
        {/* Create account button */}
        <Button
          accessible={true}
          iconPosition={'left'}
          onPress={() => {
            const handler = async () => {
              try {
                (
                  await thrillerXanoUploadUserPicturePOST.mutateAsync({
                    profile_picture: Constants['imageUrl'],
                  })
                )?.json;
                navigation.navigate('LogInMainScreenP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              {
                backgroundColor: palettes.App.White,
                borderRadius: 37,
                color: palettes.App.Thriller,
                fontFamily: 'Poppins_500Medium',
                fontSize: 15,
                height: 45,
              }
            ),
            dimensions.width
          )}
          title={'Upload'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AddProfilePhotoScreenP1InProgressScreen);
