import React from 'react';
import {
  Button,
  Checkbox,
  IconButton,
  LottieAnimation,
  ScreenContainer,
  TextInput,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { Image, Modal, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import Animations from '../config/Animations';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import extractAuthTokenAndUser from '../global-functions/extractAuthTokenAndUser';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const SIgnUpMainScreenP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [checkboxValue, setCheckboxValue] = React.useState(true);
  const [error_message, setError_message] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [show_modal, setShow_modal] = React.useState(false);
  const [success_message, setSuccess_message] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [textInputValue3, setTextInputValue3] = React.useState('');
  const [textInputValue4, setTextInputValue4] = React.useState('');
  const [textInputValue5, setTextInputValue5] = React.useState('');
  const confirmPasswordInput22S6ViTXRef = React.useRef();

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
          alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
          backgroundColor: palettes.App.Thriller,
          width: { minWidth: Breakpoints.Laptop, value: 500 },
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'stretch',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 50,
            padding: 12,
          },
          dimensions.width
        )}
      >
        <IconButton
          onPress={() => {
            try {
              navigation.navigate('SignUpLogInScreenP1InProgressScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          color={palettes.App['Form Placeholder Text color']}
          icon={'AntDesign/arrowleft'}
        />
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: palettes.App.White,
              fontFamily: 'Roboto_700Bold',
              fontSize: 25,
              letterSpacing: 2,
              lineHeight: 27,
              marginLeft: 115,
              textAlign: 'auto',
            }),
            dimensions.width
          )}
        >
          {'Sign up'}
        </Text>
      </View>
      {/* Signup Box */}
      <View
        style={StyleSheet.applyWidth(
          { marginTop: 20, paddingLeft: 30, paddingRight: 30 },
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 20, paddingLeft: 12, paddingRight: 12 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['Form Placeholder Text color'],
                fontFamily: 'Roboto_500Medium',
                fontSize: 15,
              }),
              dimensions.width
            )}
          >
            {'Your First name'}
          </Text>
          {/* First name input */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newFirstNameInputValue => {
              const textInputValue = newFirstNameInputValue;
              try {
                setTextInputValue(newFirstNameInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            autoComplete={'given-name'}
            clearButtonMode={'while-editing'}
            placeholder={'Enter your first name'}
            placeholderTextColor={palettes.App['Form Placeholder Text color']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                {
                  backgroundColor: palettes.App['Form Field BG'],
                  borderColor: null,
                  borderRadius: 28,
                  color: palettes.App.White,
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 15,
                  height: 50,
                  marginTop: 5,
                  textAlign: 'auto',
                }
              ),
              dimensions.width
            )}
            value={textInputValue}
          />
        </View>
        {/* View 3 */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 10, paddingLeft: 12, paddingRight: 12 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['Form Placeholder Text color'],
                fontFamily: 'Roboto_500Medium',
                fontSize: 15,
              }),
              dimensions.width
            )}
          >
            {'Your last name'}
          </Text>
          {/* Last name input */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newLastNameInputValue => {
              const textInputValue = newLastNameInputValue;
              try {
                setTextInputValue2(newLastNameInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            autoComplete={'family-name'}
            autoFocus={false}
            clearButtonMode={'never'}
            clearTextOnFocus={false}
            placeholder={'Enter your last name'}
            placeholderTextColor={palettes.App['Form Placeholder Text color']}
            secureTextEntry={false}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                {
                  backgroundColor: palettes.App['Form Field BG'],
                  borderColor: null,
                  borderRadius: 28,
                  color: palettes.App.White,
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 15,
                  height: 50,
                  marginTop: 5,
                }
              ),
              dimensions.width
            )}
            value={textInputValue2}
          />
        </View>
        {/* View 4 */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 10, paddingLeft: 12, paddingRight: 12 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App.TextPlaceholder,
                fontFamily: 'Roboto_400Regular',
                fontSize: 15,
              }),
              dimensions.width
            )}
          >
            {'Email'}
          </Text>
          {/* Email input */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newEmailInputValue => {
              const textInputValue = newEmailInputValue;
              try {
                setTextInputValue3(newEmailInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            autoComplete={'email'}
            placeholder={'Enter your email'}
            placeholderTextColor={palettes.App['text placeholder']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                {
                  backgroundColor: palettes.App['Form Field BG'],
                  borderColor: null,
                  borderRadius: 28,
                  color: palettes.App.White,
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 15,
                  height: 50,
                }
              ),
              dimensions.width
            )}
            value={textInputValue3}
          />
        </View>
        {/* View 5 */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 10, paddingLeft: 12, paddingRight: 12 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['text placeholder'],
                fontFamily: 'Roboto_500Medium',
                fontSize: 15,
                lineHeight: 22,
              }),
              dimensions.width
            )}
          >
            {'Password'}
          </Text>
          {/* Password input */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newPasswordInputValue => {
              const textInputValue = newPasswordInputValue;
              try {
                setTextInputValue4(newPasswordInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            autoComplete={'new-password'}
            placeholder={'Create Password'}
            placeholderTextColor={palettes.App['text placeholder']}
            secureTextEntry={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                {
                  backgroundColor: palettes.App['Form Field BG'],
                  borderColor: null,
                  borderRadius: 28,
                  color: palettes.App.White,
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 15,
                  height: 50,
                }
              ),
              dimensions.width
            )}
            value={textInputValue4}
          />
        </View>
        {/* View 6 */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 10, paddingLeft: 12, paddingRight: 12 },
            dimensions.width
          )}
        >
          {/* Confirm password text */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['text placeholder'],
                fontFamily: 'Roboto_500Medium',
                fontSize: 15,
              }),
              dimensions.width
            )}
          >
            {'Confirm password'}
          </Text>
          {/* Confirm password input */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newConfirmPasswordInputValue => {
              const textInputValue = newConfirmPasswordInputValue;
              try {
                setTextInputValue5(newConfirmPasswordInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            placeholder={'Confirm password'}
            placeholderTextColor={palettes.App['text placeholder']}
            ref={confirmPasswordInput22S6ViTXRef}
            secureTextEntry={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                {
                  backgroundColor: palettes.App['Form Field BG'],
                  borderColor: null,
                  borderRadius: 28,
                  color: palettes.App.White,
                  fontFamily: 'Roboto_500Medium',
                  fontSize: 15,
                  height: 50,
                }
              ),
              dimensions.width
            )}
            value={textInputValue5}
          />
        </View>
        {/* View 7 */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
        >
          {/* Error Message */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: palettes.App.Peoplebit_Salmon_Red,
                  },
                  { minWidth: Breakpoints.Laptop, value: palettes.Red[600] },
                ],
                fontFamily: 'Roboto_400Regular',
                fontSize: 18,
                paddingBottom: 10,
                textDecorationLine: 'none',
              }),
              dimensions.width
            )}
          >
            {' '}
            {error_message}
          </Text>
        </View>
        {/* View 8 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'flex-start',
              alignSelf: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              marginLeft: [
                { minWidth: Breakpoints.Tablet, value: 12 },
                { minWidth: Breakpoints.Mobile, value: 12 },
              ],
              marginRight: [
                { minWidth: Breakpoints.Tablet, value: 12 },
                { minWidth: Breakpoints.Mobile, value: 12 },
              ],
              marginTop: { minWidth: Breakpoints.Tablet, value: 15 },
              width: [
                { minWidth: Breakpoints.Tablet, value: '100%' },
                { minWidth: Breakpoints.Mobile, value: '100%' },
              ],
            },
            dimensions.width
          )}
        >
          <Checkbox
            onPress={newCheckboxValue => {
              const checkboxValue = newCheckboxValue;
              try {
                setCheckboxValue(newCheckboxValue);
              } catch (err) {
                console.error(err);
              }
            }}
            status={checkboxValue}
            style={StyleSheet.applyWidth(
              { marginLeft: 12, marginTop: 15 },
              dimensions.width
            )}
          />
          <WebView
            allowFileAccessFromFileURLs={false}
            allowUniversalAccessFromFileURLs={false}
            cacheEnabled={true}
            incognito={false}
            javaScriptCanOpenWindowsAutomatically={false}
            javaScriptEnabled={true}
            mediaPlaybackRequiresUserAction={false}
            startInLoadingState={false}
            {...GlobalStyles.WebViewStyles(theme)['HTML View'].props}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            source={{
              html: '<p style="color: white; font-family: \'Roboto\', sans-serif;">\n  By checking this box, you agree to our \n  <a href="https://thrillerfantasy.com/terms-of-services" target="_blank" style="color: #00bfff;">Terms of Service</a> and \n  <a href="https://thrillerfantasy.com/privacy-policy" target="_blank" style="color: #00bfff;">Privacy Policy</a>, as well as our partner \n  Dwolla\'s <a href="https://www.dwolla.com/legal/dwolla-account-terms-of-service" target="_blank" style="color: #00bfff;">Terms of Service</a> and \n  <a href="https://www.dwolla.com/legal/privacy" target="_blank" style="color: #00bfff;">Privacy Policy</a> (TOS/PP).\n</p>\n',
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.WebViewStyles(theme)['HTML View'].style,
                {
                  alignSelf: 'stretch',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  flex: null,
                  width: [
                    { minWidth: Breakpoints.Tablet, value: '90%' },
                    { minWidth: Breakpoints.Mobile, value: '100%' },
                  ],
                }
              ),
              dimensions.width
            )}
          />
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'stretch' },
              ],
              alignSelf: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              justifyContent: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
            },
            dimensions.width
          )}
        >
          <>
            {!(isLoading === true) ? null : (
              <LottieAnimation
                autoPlay={true}
                loop={true}
                speed={1}
                {...GlobalStyles.LottieAnimationStyles(theme)[
                  'Lottie Animation'
                ].props}
                source={imageSource(Animations['loader'])}
                style={StyleSheet.applyWidth(
                  GlobalStyles.LottieAnimationStyles(theme)['Lottie Animation']
                    .style,
                  dimensions.width
                )}
              />
            )}
          </>
        </View>
        {/* View 9 */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 20, paddingLeft: 12, paddingRight: 12 },
            dimensions.width
          )}
        >
          {/* Sign Up Button */}
          <>
            {!(isLoading === false) ? null : (
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      setIsLoading(true);
                      if (textInputValue4 === textInputValue5) {
                        const resp_signup = (
                          await ThrillerXanoApi.userSignupPOST(Constants, {
                            email: textInputValue3,
                            first_name: textInputValue,
                            image: Constants['imageUrl'],
                            last_name: textInputValue2,
                            password: textInputValue4,
                          })
                        )?.json;
                        if (extractErrorFromResponse(resp_signup) === 'false') {
                          setGlobalVariableValue({
                            key: 'AuthToken',
                            value: extractAuthTokenAndUser(resp_signup),
                          });
                          setShow_modal(true);
                          setTextInputValue('');
                          setTextInputValue2('');
                          setTextInputValue3('');
                          setTextInputValue4('');
                          setTextInputValue5('');
                          setError_message('');
                          setSuccess_message(
                            'We have sent an email to verify your account.you must verify your email before Login.'
                          );
                        } else {
                          setError_message(
                            extractErrorFromResponse(resp_signup)
                          );
                        }
                      } else {
                        confirmPasswordInput22S6ViTXRef.current.focus();
                        setError_message(
                          'Password and Confirm Password is not same'
                        );
                      }

                      setIsLoading(false);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                disabled={checkboxValue === false}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: palettes.App.White,
                      borderRadius: 37,
                      color: palettes.App.Thriller,
                      fontFamily: 'Roboto_400Regular',
                      fontSize: 15,
                    }
                  ),
                  dimensions.width
                )}
                title={'Create Account'}
              />
            )}
          </>
        </View>
        <>
          {!(show_modal === true) ? null : (
            <Modal
              animationType={'none'}
              supportedOrientations={['portrait', 'landscape']}
              transparent={false}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={imageSource(Images['thrillerfantasylogo300x93'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: 300, width: null }
                    ),
                    dimensions.width
                  )}
                />
                {/* Text 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        alignSelf: 'center',
                        color: palettes.App['Custom Color_9'],
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 22,
                        margin: { minWidth: Breakpoints.Laptop, value: 20 },
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Account Successfully Created'}
                </Text>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        alignSelf: 'center',
                        fontFamily: 'Roboto_400Regular',
                        margin: { minWidth: Breakpoints.Laptop, value: 20 },
                        marginBottom: 30,
                        marginTop: 30,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {success_message}
                </Text>
                {/* Log in Button 2 */}
                <>
                  {!(isLoading === false) ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setShow_modal(false);
                          navigation.navigate(
                            'LogInMainScreenP1InProgressScreen'
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: palettes.App['TFA-ScreenBG'],
                            borderRadius: 37,
                            color: palettes.App.Studily_Silver_White,
                            fontFamily: 'Roboto_500Medium',
                            fontSize: 15,
                            margin: { minWidth: Breakpoints.Laptop, value: 20 },
                            marginTop: 40,
                            width: 200,
                          }
                        ),
                        dimensions.width
                      )}
                      title={'OK'}
                    />
                  )}
                </>
              </View>
            </Modal>
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(SIgnUpMainScreenP1InProgressScreen);
