import React from 'react';
import {
  Button,
  IconButton,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const ProfileEditProfileP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [emailValue, setEmailValue] = React.useState('');
  const [error_message, setError_message] = React.useState('');
  const [firstNameValue, setFirstNameValue] = React.useState('');
  const [lastNameValue, setLastNameValue] = React.useState('');
  const [phoneNumberValue, setPhoneNumberValue] = React.useState('');
  const [profileImage, setProfileImage] = React.useState('');
  const thrillerXanoUpdateUserPOST = ThrillerXanoApi.useUpdateUserPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setEmailValue(Constants['User']?.email);
      setFirstNameValue(Constants['User']?.first_name);
      setLastNameValue(Constants['User']?.last_name);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: '"rgb(15, 4, 21)"',
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      {/* top-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* nav-top */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 10,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* nav-top__left */}
          <View style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}>
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'MyProfileMainP1InProgressScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              color={palettes.App['Form Placeholder Text color']}
              icon={'Feather/arrow-left'}
            />
          </View>
          {/* nav-top__center */}
          <View>
            {/* Edit profile */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_500Medium',
                    fontSize: 24,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Edit profile'}
            </Text>
          </View>
          {/* nav-top__right */}
          <View
            style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
          />
        </View>
        {/* profile */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
        >
          {/* profile__edit-photo */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(`${Constants['User']?.profile_picture?.url}`)}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['GetFit Orange'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['GetFit Orange'],
                      },
                    ],
                    borderRadius: [
                      { minWidth: Breakpoints.Laptop, value: 100 },
                      { minWidth: Breakpoints.Mobile, value: 100 },
                    ],
                    borderWidth: [
                      { minWidth: Breakpoints.Laptop, value: 2 },
                      { minWidth: Breakpoints.Mobile, value: 5 },
                    ],
                  }
                ),
                dimensions.width
              )}
            />
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  navigation.navigate(
                    'ProfileEditProfilePhotoP1InProgressScreen'
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: null,
                    color: palettes.App['Thriller Pink'],
                    fontFamily: 'Roboto_500Medium',
                    height: 20,
                    padding: '0%',
                  }
                ),
                dimensions.width
              )}
              title={'Edit photo'}
            />
          </View>
        </View>
        {/* edit-form */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
        >
          {/* edit-form__item-1 */}
          <View>
            {/* label-first-name */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Roboto_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'First Name'}
            </Text>
            {/* first_name */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newFirstNameValue => {
                try {
                  setFirstNameValue(newFirstNameValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              placeholder={'Add your first name'}
              placeholderTextColor={palettes.App['Form Placeholder Text color']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    backgroundColor: palettes.App['Form Field BG'],
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRadius: 30,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    borderWidth: 0,
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_500Medium',
                    paddingBottom: 12,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 12,
                  }
                ),
                dimensions.width
              )}
              value={firstNameValue}
            />
          </View>
          {/* edit-form__item-2 */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* label-last-name */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Roboto_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'Last Name'}
            </Text>
            {/* last_name */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newLastNameValue => {
                try {
                  setLastNameValue(newLastNameValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              placeholder={'Add your last name'}
              placeholderTextColor={palettes.App['Form Placeholder Text color']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    backgroundColor: palettes.App['Form Field BG'],
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRadius: 30,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    borderWidth: 0,
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_500Medium',
                    paddingBottom: 12,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 12,
                  }
                ),
                dimensions.width
              )}
              value={lastNameValue}
            />
          </View>
          {/* edit-form__item-3 */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* label-email */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Roboto_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'Email'}
            </Text>
            {/* email */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newEmailValue => {
                try {
                  setEmailValue(newEmailValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              autoComplete={'email'}
              disabled={true}
              editable={false}
              placeholder={'Add your email address'}
              placeholderTextColor={palettes.App['Form Placeholder Text color']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    backgroundColor:
                      palettes.App['Form Placeholder Text color'],
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRadius: 30,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                    borderWidth: 0,
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_500Medium',
                    paddingBottom: 12,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 12,
                  }
                ),
                dimensions.width
              )}
              value={emailValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth({ marginTop: 30 }, dimensions.width)}
          >
            {/* Error Text */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['Custom Color_8'],
                    fontFamily: 'Roboto_400Regular',
                    fontSize: 18,
                    marginBottom: 10,
                    marginTop: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {error_message}
            </Text>
          </View>
        </View>
      </View>
      {/* bottom-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginBottom: 40, marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* bottom-buttons */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', justifyContent: 'space-between' },
            dimensions.width
          )}
        >
          {/* Cancel */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('BottomTabNavigator', {
                  screen: 'MyProfileMainP1InProgressScreen',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: '"rgb(46, 40, 50)"',
                  borderRadius: 30,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Roboto_400Regular',
                  paddingBottom: 8,
                  paddingTop: 8,
                  width: '48%',
                }
              ),
              dimensions.width
            )}
            title={'Cancel'}
          />
          {/* Save changes */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  const resp = (
                    await thrillerXanoUpdateUserPOST.mutateAsync({
                      first_name: firstNameValue,
                      last_name: lastNameValue,
                    })
                  )?.json;
                  if (extractErrorFromResponse(resp) === 'false') {
                    navigation.navigate('BottomTabNavigator', {
                      screen: 'MyProfileMainP1InProgressScreen',
                    });
                    setGlobalVariableValue({
                      key: 'User',
                      value: resp,
                    });
                  } else {
                    setError_message(extractErrorFromResponse(resp));
                  }
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['TFA-White'],
                  borderRadius: 30,
                  color: '"rgb(15, 4, 21)"',
                  fontFamily: 'Roboto_400Regular',
                  paddingBottom: 8,
                  paddingTop: 8,
                  width: '48%',
                }
              ),
              dimensions.width
            )}
            title={'Save changes'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ProfileEditProfileP1InProgressScreen);
