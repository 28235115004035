import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  ImageBackground,
  RefreshControl,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import convertTimestampToDate from '../global-functions/convertTimestampToDate';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { extra: null };

const WithdrawlsListScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [is_verified, setIs_verified] = React.useState(false);
  const [page, setPage] = React.useState(2);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [withdrawalData, setWithdrawalData] = React.useState({});
  const [refreshinguecqSplY, setRefreshinguecqSplY] = React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['TopRectangleProfileThrillerFantasy'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { height: 170 }
          ),
          dimensions.width
        )}
      >
        {/* top-view */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 30 }, dimensions.width)}
        >
          {/* top-box */}
          <View
            style={StyleSheet.applyWidth(
              { marginLeft: 20, marginRight: 20 },
              dimensions.width
            )}
          >
            {/* nav-top */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 10,
                  paddingTop: 10,
                },
                dimensions.width
              )}
            >
              {/* nav-top__left */}
              <View
                style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
              >
                <IconButton
                  onPress={() => {
                    try {
                      navigation.navigate('BalanceMainP1InProgressScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  color={palettes.App['Form Placeholder Text color']}
                  icon={'Feather/arrow-left'}
                />
              </View>
              {/* nav-top__center */}
              <View>
                {/* Balance */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: '"rgb(255, 255, 255)"',
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 24,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Withdrawls'}
                </Text>
              </View>
              {/* nav-top__right */}
              <View
                style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
              />
            </View>
          </View>
        </View>
        {/* view-transactions */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginRight: 20, marginTop: 30 },
            dimensions.width
          )}
        >
          {/* Button 2 */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('BalanceWithdrawP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            icon={'MaterialCommunityIcons/cash-minus'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  fontFamily: 'Roboto_700Bold',
                  fontSize: { minWidth: Breakpoints.Laptop, value: 18 },
                  width: { minWidth: Breakpoints.Laptop, value: 120 },
                }
              ),
              dimensions.width
            )}
            title={'Withdraw'}
          />
          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth(
              { marginBottom: 150, marginTop: 50, paddingBottom: 150 },
              dimensions.width
            )}
          >
            <ThrillerXanoApi.FetchWithrawListGET
              handlers={{
                onData: fetchData => {
                  try {
                    setWithdrawalData(fetchData?.items);
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
              page={1}
            >
              {({ loading, error, data, refetchWithrawList }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <SimpleStyleFlatList
                    data={fetchData?.items}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'uecqSplY'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReached={() => {
                      const handler = async () => {
                        try {
                          if (fetchData?.nextPage === null) {
                            return;
                          }
                          setPage(page + 1);
                          const newData = (
                            await ThrillerXanoApi.withrawListGET(Constants, {
                              page: page,
                            })
                          )?.json;
                          setWithdrawalData(
                            withdrawalData.concat(newData?.items)
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onEndReachedThreshold={0.5}
                    refreshControl={
                      <RefreshControl
                        refreshing={refreshinguecqSplY}
                        onRefresh={() => {
                          try {
                            setRefreshinguecqSplY(true);
                            setPage(1);
                            setRefreshinguecqSplY(false);
                          } catch (err) {
                            console.error(err);
                            setRefreshinguecqSplY(false);
                          }
                        }}
                      />
                    }
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* block-winnings */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.App['#231C27'],
                                borderRadius: 16,
                                marginTop: 10,
                                padding: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* block-top */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                },
                                dimensions.width
                              )}
                            >
                              {/* block-left */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* block-icon */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      backgroundColor: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: listData?.color,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value:
                                            listData?.status === 'COMPLETED'
                                              ? palettes.App['TFA-Green']
                                              : palettes.App.White,
                                        },
                                      ],
                                      borderRadius: 50,
                                      height: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 20,
                                        },
                                      ],
                                      justifyContent: 'center',
                                      marginLeft: 5,
                                      marginRight: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 10,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 5,
                                        },
                                      ],
                                      width: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 20,
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Icon name={'Ionicons/logo-usd'} size={12} />
                                </View>
                                {/* Title */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'poppins-14-medium-white'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'poppins-14-medium-white'
                                      ].style,
                                      {
                                        color: [
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: null,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: listData?.color,
                                          },
                                        ],
                                        fontFamily: 'Roboto_700Bold',
                                        fontSize: 24,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.amount}
                                </Text>
                              </View>
                              {/* block-right */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* amount */}
                                <View>
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'poppins-14-medium-white'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          'poppins-14-medium-white'
                                        ].style,
                                        {
                                          color: [
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: null,
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: null,
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: listData?.color,
                                            },
                                          ],
                                          fontFamily: [
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: 'Poppins_700Bold',
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'Roboto_700Bold',
                                            },
                                          ],
                                          fontSize: 18,
                                          textTransform: 'uppercase',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.display_status}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {/* block-bottom */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 10 },
                                dimensions.width
                              )}
                            >
                              {/* Date */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'poppins-14-medium-white'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'poppins-14-medium-white'
                                    ].style,
                                    { fontFamily: 'Roboto_500Medium' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {convertTimestampToDate(listData?.created_at)}
                              </Text>
                            </View>
                          </View>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                );
              }}
            </ThrillerXanoApi.FetchWithrawListGET>
          </SimpleStyleScrollView>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(WithdrawlsListScreen);
