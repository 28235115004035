import React from 'react';
import { Button, Icon, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, ImageBackground, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const EntriesLostP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'flex-start',
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['bgwon'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { justifyContent: 'flex-end' }
          ),
          dimensions.width
        )}
      >
        {/* bottom-panel */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: palettes.App.Thriller,
              borderTopLeftRadius: 32,
              borderTopRightRadius: 32,
              padding: 20,
            },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['PlayerLg1'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { borderRadius: 50, height: 72, marginTop: -60, width: 72 }
              ),
              dimensions.width
            )}
          />
          {/* Remove Player? */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['headling-19'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['headling-19'].style,
                {
                  color: palettes.App['#FF4141'],
                  fontFamily: 'Oswald_700Bold',
                  fontSize: 24,
                  marginTop: 10,
                }
              ),
              dimensions.width
            )}
          >
            {"You've lost!"}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', marginTop: 10 },
              dimensions.width
            )}
          >
            {/* text */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                .props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['poppins-14-medium-white'].style,
                dimensions.width
              )}
            >
              {'B. Beal'}
            </Text>
            {/* text */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                dimensions.width
              )}
            >
              {' lost the game ORL vs BAL with a score'}
            </Text>
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 10,
              },
              dimensions.width
            )}
          >
            {/* text */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                .props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['poppins-14-medium-white'].style,
                dimensions.width
              )}
            >
              {'5:4'}
            </Text>
            {/* text */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                dimensions.width
              )}
            >
              {' of 5:3'}
            </Text>
          </View>
        </View>
        {/* bottom-btn-panel */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App.Thriller,
              marginBottom: 40,
              paddingLeft: 20,
              paddingRight: 20,
            },
            dimensions.width
          )}
        >
          {/* btn-add-card */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('RootNavigator');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            iconSize={20}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['TFA-White'],
                  borderRadius: 30,
                  color: '"rgb(15, 4, 21)"',
                  fontFamily: 'Poppins_500Medium',
                  paddingBottom: 8,
                  paddingTop: 8,
                  width: '100%',
                }
              ),
              dimensions.width
            )}
            title={'Go to my entries'}
          />
          {/* Close */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('RootNavigator');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['Form Field BG'],
                  borderRadius: 35,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_500Medium',
                  marginTop: 10,
                }
              ),
              dimensions.width
            )}
            title={'Cancel'}
          />
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(EntriesLostP1InProgressScreen);
