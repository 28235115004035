const generateACHDropDown = response => {
  return response
    .filter(
      item =>
        item.platform_name.toLowerCase() === 'ach' &&
        item.status.toLowerCase() === 'verified'
    )
    .map(item => ({
      label:
        item.platform_name.toUpperCase() + ' ' + item.payout_details.bankName,
      value: item.id,
    }));
};

export default generateACHDropDown;
