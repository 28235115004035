import React from 'react';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import extractUserDataFromObject from '../global-functions/extractUserDataFromObject';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const Onboarding01P1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [textAreaValue, setTextAreaValue] = React.useState('');

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.Thriller },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: 'flex-end',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'flex-end',
            marginTop: 15,
          },
          dimensions.width
        )}
      >
        {/* Skip Button */}
        <Button
          accessible={true}
          iconPosition={'left'}
          onPress={() => {
            try {
              setGlobalVariableValue({
                key: 'onboarding_complete',
                value: true,
              });
              navigation.navigate('CheckLocationServicesScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              {
                backgroundColor: '"rgb(46, 40, 50)"',
                borderColor: 'rgba(0, 0, 0, 0)',
                borderRadius: 25,
                color: '"rgb(255, 255, 255)"',
                fontFamily: 'Poppins_500Medium',
                height: 44,
                marginRight: 15,
                width: 90,
              }
            ),
            dimensions.width
          )}
          title={'Skip'}
        />
      </View>
      {/* View 2 */}
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', justifyContent: 'center', marginTop: 50 },
          dimensions.width
        )}
      >
        <Image
          resizeMode={'cover'}
          {...GlobalStyles.ImageStyles(theme)['Image'].props}
          source={imageSource(Images['OnboardingScreen01Image'])}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'].style, {
              height: 290,
              width: 350,
            }),
            dimensions.width
          )}
        />
      </View>
      {/* View 3 */}
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', justifyContent: 'center' },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              alignSelf: 'auto',
              color: '"rgb(255, 255, 255)"',
              fontFamily: 'Oswald_700Bold',
              fontSize: 50,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {'Welcome\n'}
          {/* Text 2 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: '"rgb(255, 255, 255)"',
                fontFamily: 'Oswald_700Bold',
                fontSize: 50,
              }),
              dimensions.width
            )}
          >
            {'to Thriller!'}
          </Text>
        </Text>
      </View>

      <Text
        accessible={true}
        selectable={false}
        {...GlobalStyles.TextStyles(theme)['Text'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
            color: '"rgb(127, 118, 133)"',
            fontFamily: 'Poppins_500Medium',
            fontSize: 16,
            lineHeight: 24,
            marginTop: 20,
            textAlign: 'center',
          }),
          dimensions.width
        )}
      >
        {
          "Test fantasy skills: pick players, predict\nif they'll exceed or fall below their fantasy\nscores."
        }
      </Text>
      {/* View 5 */}
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10 },
          dimensions.width
        )}
      >
        {/* Next Button */}
        <Button
          accessible={true}
          iconPosition={'left'}
          onPress={() => {
            try {
              navigation.navigate('Onboarding02P1InProgressScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              {
                backgroundColor: '"rgb(255, 255, 255)"',
                borderColor: 'rgba(0, 0, 0, 0)',
                borderRadius: 25,
                color: '"rgb(15, 4, 21)"',
                fontFamily: 'Poppins_500Medium',
                height: 44,
                marginRight: 15,
                width: 168,
              }
            ),
            dimensions.width
          )}
          title={'Next'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(Onboarding01P1InProgressScreen);
