import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  LinearProgress,
  LoadingIndicator,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  RefreshControl,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import completeDateTime from '../global-functions/completeDateTime';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const EntriesPastP1InProgressScreen2 = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [page, setPage] = React.useState(2);
  const [pastEntriesData, setPastEntriesData] = React.useState({});
  const [screen_active, setScreen_active] = React.useState(false);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [refreshings8SYWROB, setRefreshings8SYWROB] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setScreen_active(true);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasLeftSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['TFA-ScreenBG'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['TopRectangleProfileThrillerFantasy'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { height: 140 }
          ),
          dimensions.width
        )}
      >
        {/* container */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: 40,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 30,
            },
            dimensions.width
          )}
        >
          {/* top-box */}
          <View>
            {/* nav-top */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  paddingBottom: 10,
                  paddingTop: 10,
                },
                dimensions.width
              )}
            >
              {/* nav-top__left */}
              <View
                style={StyleSheet.applyWidth(
                  { alignSelf: 'flex-start', width: '33%' },
                  dimensions.width
                )}
              >
                <IconButton
                  onPress={() => {
                    try {
                      navigation.navigate('BottomTabNavigator', {
                        screen: 'EntriesOpenedP1InProgress2Screen',
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  color={palettes.App['Form Placeholder Text color']}
                  icon={'Feather/arrow-left'}
                />
              </View>
              {/* nav-top__center */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '33%' },
                  dimensions.width
                )}
              >
                {/* Entries */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: '"rgb(255, 255, 255)"',
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 24,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Entries'}
                </Text>
              </View>
              {/* nav-top__right */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '33%' },
                  dimensions.width
                )}
              />
            </View>
          </View>
          {/* entry-tab */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 10,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* open */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  if (navigation.canGoBack()) {
                    navigation.popToTop();
                  }
                  navigation.replace('BottomTabNavigator', {
                    screen: 'EntriesOpenedP1InProgress2Screen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: null,
                    borderRadius: 35,
                    color: palettes.App['#7F7685'],
                    fontFamily: 'Roboto_500Medium',
                    paddingBottom: 11,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 11,
                  }
                ),
                dimensions.width
              )}
              title={'Open'}
            />
            {/* past */}
            <Button
              accessible={true}
              iconPosition={'left'}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: palettes.App['Form Field BG'],
                    borderColor: palettes.App.White,
                    borderRadius: 35,
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_500Medium',
                    paddingBottom: 11,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 11,
                  }
                ),
                dimensions.width
              )}
              title={'Past'}
            />
          </View>

          <ThrillerXanoApi.FetchGetPastEntriesGET
            handlers={{
              onData: fetchData => {
                try {
                  setScreen_active(false);
                  setPastEntriesData(fetchData?.data);
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            page={1}
            refetchInterval={screen_active ? 1 : undefined}
          >
            {({ loading, error, data, refetchGetPastEntries }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <>
                    {!screen_active ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { alignSelf: 'center', marginTop: 40 },
                          dimensions.width
                        )}
                      >
                        <LoadingIndicator
                          color={theme.colors.branding.primary}
                          size={30}
                          type={'circleFade'}
                        />
                      </View>
                    )}
                  </>
                  {/* no-entries */}
                  <>
                    {!(fetchData?.itemReceived < 1) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            height: 500,
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={palettes.App['#7F7685']}
                          name={'AntDesign/clockcircle'}
                          size={38}
                        />
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App['TFA-White'],
                                fontFamily: 'Oswald_700Bold',
                                fontSize: 29,
                                lineHeight: 48,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'No entries yet'}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['body-content']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['body-content']
                                .style,
                              {
                                fontFamily: 'Poppins_500Medium',
                                lineHeight: 24,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Your Past entries will appear here'}
                        </Text>
                        {/* btn-start-entry */}
                        <Button
                          accessible={true}
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              navigation.navigate('BottomTabNavigator', {
                                screen: 'FeedMainP1InProgressScreen',
                              });
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          iconSize={20}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: palettes.App['TFA-White'],
                                borderRadius: 30,
                                color: '"rgb(15, 4, 21)"',
                                fontFamily: 'Poppins_500Medium',
                                marginTop: 30,
                                paddingBottom: 11,
                                paddingLeft: 22,
                                paddingRight: 22,
                                paddingTop: 11,
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Start an entry'}
                        />
                      </View>
                    )}
                  </>
                  <>
                    {!(fetchData?.itemReceived >= 1) ? null : (
                      <SimpleStyleScrollView
                        bounces={true}
                        horizontal={false}
                        keyboardShouldPersistTaps={'never'}
                        nestedScrollEnabled={false}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        style={StyleSheet.applyWidth(
                          { marginBottom: 50, marginTop: 30 },
                          dimensions.width
                        )}
                      >
                        <SimpleStyleFlatList
                          data={pastEntriesData}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'s8SYWROB'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReached={() => {
                            const handler = async () => {
                              try {
                                if (fetchData?.nextPage === null) {
                                  return;
                                }
                                setPage(page + 1);
                                const newData = (
                                  await ThrillerXanoApi.getPastEntriesGET(
                                    Constants,
                                    { page: page }
                                  )
                                )?.json;
                                setPastEntriesData(
                                  pastEntriesData.concat(newData?.data)
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          onEndReachedThreshold={0.5}
                          refreshControl={
                            <RefreshControl
                              refreshing={refreshings8SYWROB}
                              onRefresh={() => {
                                try {
                                  setRefreshings8SYWROB(true);
                                  setPage(1);
                                  setRefreshings8SYWROB(false);
                                } catch (err) {
                                  console.error(err);
                                  setRefreshings8SYWROB(false);
                                }
                              }}
                            />
                          }
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* Card */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor: 'rgb(4, 6, 19)',
                                      borderColor: palettes.App.White,
                                      borderRadius: 10,
                                      borderWidth: 0.1,
                                      marginBottom: 10,
                                      marginTop: 20,
                                      minWidth:
                                        dimensions.width >= Breakpoints.Desktop
                                          ? 700
                                          : undefined,
                                      padding: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Game Data */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: 'row',
                                        marginBottom: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Left */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { width: '50%' },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              color: palettes.App['TFA-White'],
                                              fontFamily: 'Roboto_400Regular',
                                              fontSize: 18,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          listData?._payout_chart
                                            ?.no_of_selection
                                        }
                                        {' Picks Lineup\n'}
                                      </Text>
                                      {/* Text 2 */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              color: palettes.App['TFA-White'],
                                              fontFamily: 'Roboto_400Regular',
                                              fontSize: 12,
                                              marginTop: 4,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Entry Fees $'}
                                        {listData?.entry_fee}
                                      </Text>
                                      {/* Text 2 */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              alignSelf: 'flex-start',
                                              color: palettes.App['TFA-White'],
                                              fontFamily: 'Roboto_400Regular',
                                              fontSize: 10,
                                              marginTop: 5,
                                              textAlign: 'auto',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {completeDateTime(
                                          listData?.submitted_at
                                        )}
                                      </Text>
                                    </View>
                                    {/* Right */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'stretch',
                                          justifyContent: 'flex-start',
                                          width: '50%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            alignSelf: 'flex-end',
                                            backgroundColor: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: palettes.App['#aaa7a3'],
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: listData?.entry_color,
                                              },
                                            ],
                                            borderRadius: 10,
                                            justifyContent: 'flex-end',
                                            marginBottom: 7,
                                            maxWidth: 80,
                                            paddingBottom: 5,
                                            paddingTop: 5,
                                            width: '100%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                alignSelf: 'center',
                                                color: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value:
                                                      palettes.App['TFA-White'],
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value:
                                                      palettes.App['TFA-White'],
                                                  },
                                                ],
                                                fontFamily: 'Roboto_700Bold',
                                                fontSize: 12,
                                                textAlign: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.entry_status?.toUpperCase()}
                                          {'!'}
                                        </Text>
                                      </View>

                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              color: [
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value:
                                                    palettes.App['TFA-White'],
                                                },
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value:
                                                    palettes.App['TFA-White'],
                                                },
                                              ],
                                              fontFamily: 'Roboto_700Bold',
                                              fontSize: 25,
                                              textAlign: 'right',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.amount_to_display}
                                      </Text>
                                    </View>
                                  </View>
                                  {/* List2 */}
                                  <SimpleStyleFlatList
                                    data={listData?.lineup_selection}
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(list2Data, index) =>
                                      list2Data?.id ??
                                      list2Data?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(list2Data)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={JSON.stringify(
                                      listData?.lineup_selection
                                    )}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const list2Data = item;
                                      return (
                                        <>
                                          {/* player-card */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginBottom: 20,
                                                marginTop: 15,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* card left */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  marginRight: 10,
                                                  width: '65%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 10 },
                                                  dimensions.width
                                                )}
                                              >
                                                <Image
                                                  resizeMode={'cover'}
                                                  {...GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'].props}
                                                  source={imageSource(
                                                    list2Data?._player_props
                                                      ?._player_data
                                                      ?.player_image?.url
                                                      ? list2Data?._player_props
                                                          ?._player_data
                                                          ?.player_image?.url
                                                      : Images[
                                                          'jerseyremovebgpreview'
                                                        ]
                                                  )}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.ImageStyles(
                                                        theme
                                                      )['Image'].style,
                                                      {
                                                        borderRadius: 50,
                                                        height: 48,
                                                        width: 48,
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                />
                                              </View>
                                              {/* View 2 */}
                                              <View>
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      flexDirection: 'row',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['poppins-16-bold-white']
                                                      .props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'poppins-16-bold-white'
                                                        ].style,
                                                        {
                                                          fontFamily:
                                                            'Roboto_700Bold',
                                                          fontSize: 14,
                                                          marginRight: 3,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      list2Data?._player_props
                                                        ?._player_data?.name
                                                    }
                                                  </Text>
                                                  {/* Text 2 */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['body-content'].props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['body-content'].style,
                                                        {
                                                          color:
                                                            palettes.App[
                                                              'TFA-White'
                                                            ],
                                                          fontFamily:
                                                            'Roboto_400Regular',
                                                          fontSize: 9,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      list2Data?._player_props
                                                        ?.player_team?.alias
                                                    }
                                                    {' - '}
                                                    {
                                                      list2Data?._player_props
                                                        ?._player_data?.position
                                                    }
                                                  </Text>
                                                </View>
                                                {/* View 2 */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { marginTop: 2 },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['body-content'].props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['body-content'].style,
                                                        {
                                                          color:
                                                            palettes.App[
                                                              'TFA-White'
                                                            ],
                                                          fontFamily:
                                                            'Roboto_400Regular',
                                                          fontSize: 10,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {completeDateTime(
                                                      list2Data?._player_props
                                                        ?._seasons_schedule_nfl
                                                        ?.scheduled
                                                    )}
                                                    {' vs '}
                                                    {
                                                      list2Data?._player_props
                                                        ?.opponent_team?.alias
                                                    }
                                                  </Text>
                                                </View>
                                                {/* View 3 2 */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flexDirection: 'row',
                                                      marginTop: 5,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* View 2 */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        backgroundColor:
                                                          palettes.App[
                                                            'BG Gray'
                                                          ],
                                                        borderRadius: 1,
                                                        marginRight: 5,
                                                        padding: 2,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          {
                                                            color:
                                                              palettes.App[
                                                                'TFA-White'
                                                              ],
                                                            fontFamily:
                                                              'Roboto_400Regular',
                                                            fontSize: 10,
                                                            textTransform:
                                                              'uppercase',
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        list2Data?._player_props
                                                          ?._player_data
                                                          ?._leagues?.alias
                                                      }
                                                    </Text>
                                                  </View>

                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        backgroundColor:
                                                          palettes.App[
                                                            'BG Gray'
                                                          ],
                                                        borderRadius: 1,
                                                        marginRight: 5,
                                                        padding: 2,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          {
                                                            color:
                                                              palettes.App[
                                                                'TFA-White'
                                                              ],
                                                            fontFamily:
                                                              'Roboto_400Regular',
                                                            fontSize: 10,
                                                            textTransform:
                                                              'uppercase',
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {list2Data?._player_props
                                                        ?.status
                                                        ? list2Data
                                                            ?._player_props
                                                            ?.status
                                                        : 'Not Started'}
                                                    </Text>
                                                  </View>
                                                </View>
                                                {/* View 4 */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignContent:
                                                        'space-between',
                                                      flexDirection: 'row',
                                                      marginBottom: 5,
                                                      marginTop: 10,
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Text 3 */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'].style,
                                                        {
                                                          alignSelf: 'center',
                                                          color: [
                                                            {
                                                              minWidth:
                                                                Breakpoints.BigScreen,
                                                              value:
                                                                palettes.App[
                                                                  'TFA-White'
                                                                ],
                                                            },
                                                            {
                                                              minWidth:
                                                                Breakpoints.Mobile,
                                                              value:
                                                                palettes.App[
                                                                  'TFA-White'
                                                                ],
                                                            },
                                                          ],
                                                          fontFamily:
                                                            'Poppins_400Regular',
                                                          fontSize: 9,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      list2Data?._player_props
                                                        ?._players_stat?.total
                                                    }
                                                  </Text>
                                                  <LinearProgress
                                                    animationDuration={500}
                                                    indeterminate={false}
                                                    isAnimated={true}
                                                    trackColor={
                                                      theme.colors.border.brand
                                                    }
                                                    trackLineCap={'round'}
                                                    color={
                                                      list2Data?.color ??
                                                      palettes.App['#aaa7a3']
                                                    }
                                                    lineCap={'square'}
                                                    maximumValue={
                                                      list2Data?.locked_projection_point
                                                    }
                                                    showTrack={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignSelf: 'center',
                                                        marginLeft: 3,
                                                        marginRight: 3,
                                                        maxWidth: 100,
                                                        width: '100%',
                                                      },
                                                      dimensions.width
                                                    )}
                                                    thickness={10}
                                                    value={
                                                      list2Data?._player_props
                                                        ?._players_stat?.total
                                                    }
                                                  />
                                                  {/* Text 2 */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'].style,
                                                        {
                                                          alignSelf: 'center',
                                                          color: [
                                                            {
                                                              minWidth:
                                                                Breakpoints.BigScreen,
                                                              value:
                                                                palettes.App[
                                                                  'TFA-White'
                                                                ],
                                                            },
                                                            {
                                                              minWidth:
                                                                Breakpoints.Mobile,
                                                              value:
                                                                palettes.App[
                                                                  'TFA-White'
                                                                ],
                                                            },
                                                          ],
                                                          fontFamily:
                                                            'Poppins_400Regular',
                                                          fontSize: 9,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      list2Data?.locked_projection_point
                                                    }
                                                  </Text>
                                                </View>
                                              </View>
                                            </View>
                                            {/* card-right */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  backgroundColor:
                                                    'rgb(19, 20, 31)',
                                                  borderColor:
                                                    'rgb(19, 20, 31)',
                                                  borderRadius: 10,
                                                  flexDirection: 'row',
                                                  justifyContent: 'center',
                                                  paddingLeft: 10,
                                                  paddingRight: 10,
                                                  width: '32%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    borderColor:
                                                      palettes.App.White,
                                                    justifyContent: 'center',
                                                    padding: 10,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Text 3 */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].style,
                                                      {
                                                        alignSelf: 'center',
                                                        color: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value:
                                                              palettes.App[
                                                                'TFA-White'
                                                              ],
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value:
                                                              palettes.App[
                                                                'TFA-White'
                                                              ],
                                                          },
                                                        ],
                                                        fontFamily:
                                                          'Roboto_400Regular',
                                                        fontSize: 10,
                                                        textAlign: 'center',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {list2Data?._player_props?._market?.title?.toUpperCase()}
                                                </Text>

                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['poppins-14-regular-white']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'poppins-14-regular-white'
                                                      ].style,
                                                      {
                                                        alignSelf: 'center',
                                                        color: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value:
                                                              list2Data?.color,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Tablet,
                                                            value:
                                                              list2Data?.color,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Laptop,
                                                            value:
                                                              list2Data?.color,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value:
                                                              list2Data?.color,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value:
                                                              list2Data?.color,
                                                          },
                                                        ],
                                                        fontFamily:
                                                          'Roboto_700Bold',
                                                        fontSize: 18,
                                                        marginBottom: 4,
                                                        marginRight: 0,
                                                        marginTop: 4,
                                                        textAlign: 'center',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    list2Data?.locked_projection_point
                                                  }
                                                </Text>
                                                {/* Text 2 */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['headling-19'].props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['headling-19'].style,
                                                      {
                                                        alignSelf: 'center',
                                                        fontFamily:
                                                          'Roboto_400Regular',
                                                        fontSize: 12,
                                                        textAlign: 'center',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {list2Data?.selection?.toUpperCase()}
                                                </Text>
                                              </View>
                                            </View>
                                          </View>
                                        </>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                  />
                                </View>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.BigScreen,
                                value: 'center',
                              },
                              alignSelf: {
                                minWidth: Breakpoints.BigScreen,
                                value: 'center',
                              },
                              justifyContent: {
                                minWidth: Breakpoints.BigScreen,
                                value: 'center',
                              },
                              marginBottom: 60,
                              width: {
                                minWidth: Breakpoints.BigScreen,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        />
                      </SimpleStyleScrollView>
                    )}
                  </>
                </>
              );
            }}
          </ThrillerXanoApi.FetchGetPastEntriesGET>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(EntriesPastP1InProgressScreen2);
