import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const StartVerificationScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [current_status, setCurrent_status] = React.useState('');
  const [show_old_status, setShow_old_status] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [verif_status, setVerif_status] = React.useState(true);
  const [verification_url, setVerification_url] = React.useState('');
  const thrillerXanoStartVerificationGET =
    ThrillerXanoApi.useStartVerificationGET();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      /* hidden 'Set Variable' action */
      /* hidden 'API Request' action */
      /* hidden 'If/Else' action */
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      {/* top-box */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
            marginLeft: 20,
            marginRight: 20,
            marginTop: 30,
          },
          dimensions.width
        )}
      >
        {/* nav-top */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 10,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* nav-top__left */}
          <View style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}>
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'MyProfileMainP1InProgressScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              color={palettes.App['Form Placeholder Text color']}
              icon={'Feather/arrow-left'}
            />
          </View>
          {/* nav-top__center */}
          <View>
            {/* Contact us */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: '"rgb(255, 255, 255)"',
                    fontFamily: 'Roboto_700Bold',
                    fontSize: 24,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Verification Status'}
            </Text>
          </View>
          {/* nav-top__right */}
          <View
            style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
          />
        </View>
        {/* form-area */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 50 }, dimensions.width)}
        >
          <ThrillerXanoApi.FetchUserSessionGET
            handlers={{
              onData: fetchData => {
                try {
                  /* hidden 'Set Variable' action */
                } catch (err) {
                  console.error(err);
                }
              },
            }}
          >
            {({ loading, error, data, refetchUserSession }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Content */}
                  <>
                    {!(fetchData?.verification_status !== 'approved') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['TFA-White'],
                              fontFamily: 'Roboto_400Regular',
                              fontSize: 16,
                              lineHeight: 25,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'In order to start betting you need to complete identify verification.'
                        }
                      </Text>
                    )}
                  </>
                  {/* form-wrap */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Desktop,
                          value: 'center',
                        },
                        borderRadius: 20,
                        marginBottom: 30,
                        marginTop: 30,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Start Verification */}
                    <>
                      {!(
                        fetchData?.verification_status === 'not_started'
                      ) ? null : (
                        <Button
                          accessible={true}
                          iconPosition={'left'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const resp = (
                                  await thrillerXanoStartVerificationGET.mutateAsync()
                                )?.json;
                                await WebBrowser.openBrowserAsync(
                                  `${resp?.url}`
                                );
                                setShow_old_status(true);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: palettes.App['TFA-InputBG'],
                                borderRadius: 30,
                                color: palettes.App['TFA-White'],
                                fontFamily: 'Roboto_400Regular',
                                letterSpacing: 1,
                                padding: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 10,
                                },
                                width: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 200,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Start Verification'}
                        />
                      )}
                    </>
                    {/* Complete Verification */}
                    <>
                      {!(
                        fetchData?.verification_status === 'started'
                      ) ? null : (
                        <Button
                          accessible={true}
                          iconPosition={'left'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                await WebBrowser.openBrowserAsync(
                                  `${fetchData?._veriff_session?.url}`
                                );
                                /* hidden 'Set Variable' action */
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: palettes.App['TFA-InputBG'],
                                borderRadius: 30,
                                color: palettes.App['TFA-White'],
                                fontFamily: 'Roboto_400Regular',
                                letterSpacing: 1,
                                padding: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 10,
                                },
                                width: {
                                  minWidth: Breakpoints.Desktop,
                                  value: 200,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Complete Verification'}
                        />
                      )}
                    </>
                    {/* Request Submitted text */}
                    <>
                      {!(
                        fetchData?.verification_status === 'submitted'
                      ) ? null : (
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                alignSelf: 'center',
                                color: palettes.App['TFA-White'],
                                fontFamily: 'Roboto_400Regular',
                                fontSize: 20,
                                lineHeight: 30,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            'We have recieved verification information and we are currently verifing it.'
                          }
                        </Text>
                      )}
                    </>
                    {/* retry view */}
                    <>
                      {!(fetchData?.verification_status === 'retry') ? null : (
                        <View>
                          {/* Retry Verification */}
                          <Button
                            accessible={true}
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const resp = (
                                    await thrillerXanoStartVerificationGET.mutateAsync()
                                  )?.json;
                                  await WebBrowser.openBrowserAsync(
                                    `${resp?.url}`
                                  );
                                  setShow_old_status(true);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button']
                                  .style,
                                {
                                  backgroundColor: palettes.App['TFA-InputBG'],
                                  borderRadius: 30,
                                  color: palettes.App['TFA-White'],
                                  fontFamily: 'Roboto_400Regular',
                                  letterSpacing: 1,
                                  marginBottom: 20,
                                  padding: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 10,
                                  },
                                  width: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 200,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Retry Verification'}
                          />
                          {/* Content 2 */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App['TFA-White'],
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'Roboto_400Regular',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'Poppins_700Bold',
                                    },
                                  ],
                                  fontSize: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 24,
                                  },
                                  marginBottom: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 20,
                                  },
                                  marginTop: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 20,
                                  },
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Your Last Verification Status Is '}
                            {fetchData?._veriff_session?.status}
                          </Text>
                        </View>
                      )}
                    </>
                    {/* Approved */}
                    <>
                      {!(
                        fetchData?.verification_status === 'approved'
                      ) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignSelf: 'center' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  alignSelf: 'center',
                                  color: palettes.App['TFA-White'],
                                  fontFamily: 'Roboto_400Regular',
                                  fontSize: 30,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Congratulations!!\nYour Account is verified!'}
                          </Text>
                        </View>
                      )}
                    </>
                  </View>
                </>
              );
            }}
          </ThrillerXanoApi.FetchUserSessionGET>
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: [
                  {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App['TFA-White'],
                  },
                  {
                    minWidth: Breakpoints.Mobile,
                    value: palettes.App['TFA-White'],
                  },
                ],
                fontFamily: 'Roboto_400Regular',
                marginTop: 15,
                paddingTop: { minWidth: Breakpoints.Laptop, value: 10 },
                textAlign: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
              }),
              dimensions.width
            )}
          >
            {'Please contact support for more information'}
          </Text>
        </View>
      </View>
      {/* bottom-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginBottom: 60, marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* disclaim */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row' },
            dimensions.width
          )}
        >
          <Icon
            color={palettes.App['#7F7685']}
            name={'AntDesign/exclamationcircleo'}
            size={18}
            style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
          />
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['#7F7685'],
                fontFamily: 'Roboto_400Regular',
                fontSize: 12,
              }),
              dimensions.width
            )}
          >
            {
              'This information is used for identity verification only, and will be kept secure by played'
            }
          </Text>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(StartVerificationScreen);
