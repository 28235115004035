import React from 'react';
import {
  Button,
  IconButton,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const ForgotPasswordScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [error_message, setError_message] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const thrillerXanoForgotPasswordPOST =
    ThrillerXanoApi.useForgotPasswordPOST();

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
          alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
          backgroundColor: palettes.App.Thriller,
          justifyContent: { minWidth: Breakpoints.Laptop, value: 'flex-start' },
          width: { minWidth: Breakpoints.Laptop, value: 500 },
        },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'flex-start',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginTop: 50,
            padding: 12,
            paddingLeft: 10,
            paddingRight: 10,
          },
          dimensions.width
        )}
      >
        <IconButton
          onPress={() => {
            try {
              navigation.navigate('LogInMainScreenP1InProgressScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          color={palettes.App['Form Placeholder Text color']}
          icon={'AntDesign/arrowleft'}
        />
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              alignSelf: 'center',
              color: palettes.App.White,
              fontFamily: 'Roboto_700Bold',
              fontSize: 25,
              letterSpacing: 2,
              lineHeight: 27,
              marginLeft: 115,
              textAlign: 'auto',
            }),
            dimensions.width
          )}
        >
          {'Forgot Password'}
        </Text>
      </View>
      {/* View 2 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'auto' },
            justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
            marginTop: 35,
            paddingLeft: 40,
            paddingRight: 40,
          },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: palettes.App['TFA-White'],
              fontFamily: 'Roboto_500Medium',
              fontSize: 15,
            }),
            dimensions.width
          )}
        >
          {'Email'}
        </Text>
        {/* Email input */}
        <TextInput
          autoCapitalize={'none'}
          autoCorrect={true}
          changeTextDelay={500}
          onChangeText={newEmailInputValue => {
            const textInputValue = newEmailInputValue;
            try {
              setTextInputValue(newEmailInputValue);
            } catch (err) {
              console.error(err);
            }
          }}
          webShowOutline={true}
          {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
          autoComplete={'email'}
          clearButtonMode={'while-editing'}
          placeholder={'Enter your email'}
          placeholderTextColor={palettes.App['Form Placeholder Text color']}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextInputStyles(theme)['Text Input'].style,
              {
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'stretch' },
                backgroundColor: palettes.App['Form Field BG'],
                borderColor: null,
                borderRadius: 28,
                color: palettes.App.White,
                fontFamily: 'Roboto_400Regular',
                fontSize: 15,
                height: 50,
                marginTop: 5,
                textAlign: 'auto',
              }
            ),
            dimensions.width
          )}
          value={textInputValue}
        />
        {/* View 4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                { minWidth: Breakpoints.Mobile, value: 'flex-start' },
              ],
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'auto' },
              flexDirection: [
                { minWidth: Breakpoints.Mobile, value: 'row' },
                { minWidth: Breakpoints.Laptop, value: 'row' },
              ],
              justifyContent: [
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                { minWidth: Breakpoints.Mobile, value: 'flex-start' },
              ],
              marginTop: 5,
              padding: 12,
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['Custom Color_8'],
                fontFamily: 'Roboto_400Regular',
                fontSize: 20,
                marginBottom: 10,
              }),
              dimensions.width
            )}
          >
            {error_message}
          </Text>
        </View>
        {/* View 4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'stretch' },
                { minWidth: Breakpoints.Laptop, value: 'stretch' },
              ],
              alignSelf: [
                { minWidth: Breakpoints.Mobile, value: 'stretch' },
                { minWidth: Breakpoints.Laptop, value: 'auto' },
              ],
              justifyContent: [
                { minWidth: Breakpoints.Mobile, value: 'center' },
                { minWidth: Breakpoints.Laptop, value: 'flex-start' },
              ],
              marginTop: 200,
            },
            dimensions.width
          )}
        >
          {/* Log in Button */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  const resp_forgot = (
                    await thrillerXanoForgotPasswordPOST.mutateAsync({
                      email: textInputValue,
                    })
                  )?.json;
                  if (extractErrorFromResponse(resp_forgot) === 'false') {
                    navigation.navigate('OtpChangePasswordScreen');
                  } else {
                    setError_message(extractErrorFromResponse(resp_forgot));
                  }
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App.White,
                  borderRadius: 37,
                  color: palettes.App.Thriller,
                  fontFamily: 'Roboto_500Medium',
                  fontSize: 15,
                  textAlign: { minWidth: Breakpoints.Laptop, value: 'auto' },
                }
              ),
              dimensions.width
            )}
            title={'Submit'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ForgotPasswordScreen);
