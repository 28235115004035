const generateDropdownOptions = response => {
  return response
    .filter(item => item.status.toLowerCase() === 'verified')
    .map(item => ({
      label:
        item.platform_name.toUpperCase() +
        ' ' +
        (item.platform_name === 'ach'
          ? item.payout_details.bankName
          : item.payout_details.account),
      value: item.id,
    }));
};

export default generateDropdownOptions;
