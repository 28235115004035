export default {
  MasterCard: require('../assets/images/MasterCard.png'),
  BettingCardBg: require('../assets/images/BettingCardBg.png'),
  PlayerLg1: require('../assets/images/PlayerLg1.png'),
  Sp2: require('../assets/images/Sp2.png'),
  Sp1: require('../assets/images/Sp1.png'),
  Sp3: require('../assets/images/Sp3.png'),
  PlayerCardBg: require('../assets/images/PlayerCardBg.png'),
  PlayerBg: require('../assets/images/PlayerBg.png'),
  PlayerXl: require('../assets/images/PlayerXl.png'),
  bgwon: require('../assets/images/BgWon.png'),
  joiningbg: require('../assets/images/JoiningBg.jpg'),
  depositbg: require('../assets/images/DepositBg.jpg'),
  depositbg1: require('../assets/images/DepositBg1.jpg'),
  IconGoogle: require('../assets/images/IconGoogle.png'),
  IconApple: require('../assets/images/IconApple.png'),
  OnboardingScreen01Image: require('../assets/images/OnboardingScreen01Image.png'),
  OnboardingScreen02: require('../assets/images/OnboardingScreen02.png'),
  OnboardingScreen03: require('../assets/images/OnboardingScreen03.png'),
  Background: require('../assets/images/Background.png'),
  ThrillerFanstayLogo: require('../assets/images/ThrillerFanstayLogo.png'),
  TopRectangleProfileThrillerFantasy: require('../assets/images/TopRectangleProfileThrillerFantasy.png'),
  AccountVerificationStatus: require('../assets/images/AccountVerificationStatus.jpg'),
  ImageTrophy: require('../assets/images/ImageTrophy.png'),
  ImageBag: require('../assets/images/ImageBag.png'),
  ContactBgSuccess: require('../assets/images/ContactBgSuccess.png'),
  CheckCircle: require('../assets/images/CheckCircle.png'),
  Football: require('../assets/images/Football.png'),
  CardGradientBg: require('../assets/images/CardGradientBg.png'),
  ThumbsDown: require('../assets/images/ThumbsDown.png'),
  ThumbsUp: require('../assets/images/ThumbsUp.png'),
  User: require('../assets/images/User.png'),
  ReferBg: require('../assets/images/ReferBg.png'),
  Girl: require('../assets/images/Girl.png'),
  Victory: require('../assets/images/Victory.png'),
  Visa: require('../assets/images/Visa.png'),
  Player1: require('../assets/images/Player1.png'),
  notavailable: require('../assets/images/Notavailable.png'),
  paypallogo: require('../assets/images/PayPalLogo.jpg'),
  paypalcard: require('../assets/images/PayPalCard.png'),
  redjerseywithwordadidasit2removebgpreview: require('../assets/images/RedJerseyWithWordAdidasIt2RemovebgPreview.png'),
  jerseyremovebgpreview: require('../assets/images/JerseyRemovebgPreview.png'),
  payoneerstackedlogoonwhitergb: require('../assets/images/PayoneerStackedLogoOnWhiteRGB.png'),
  pngwingcom: require('../assets/images/Pngwingcom.png'),
  navidorpaymentsolutionsach: require('../assets/images/NavidorPaymentSolutionsAch.png'),
  thrillerfantasylogo300x93: require('../assets/images/ThrillerFantasyLogo300x93.png'),
};
