import React from 'react';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, ImageBackground, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const PromoDepositBonusScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'flex-end',
        },
        dimensions.width
      )}
    >
      {/* top-box */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App.Thriller,
            borderTopLeftRadius: 32,
            borderTopRightRadius: 32,
            padding: 20,
          },
          dimensions.width
        )}
      >
        {/* Deposit bonus */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['headling-19'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['headling-19'].style,
              { fontSize: 24 }
            ),
            dimensions.width
          )}
        >
          {'Deposit bonus'}
        </Text>
        {/* Text 2 */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['body-content'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['body-content'].style,
              { marginTop: 10 }
            ),
            dimensions.width
          )}
        >
          {
            'Congratulations! You signed up for instant cash and kickstarted your Thriller adventure. Stay tuned for more surprises and enjoy the ride '
          }
        </Text>
        {/* bottom-box */}
        <View
          style={StyleSheet.applyWidth(
            { height: 122, marginTop: 20 },
            dimensions.width
          )}
        >
          <ImageBackground
            {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background']
              .props}
            resizeMode={'cover'}
            source={imageSource(Images['depositbg1'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageBackgroundStyles(theme)['Image Background']
                  .style,
                { borderRadius: 16, overflow: 'hidden', padding: 20 }
              ),
              dimensions.width
            )}
          >
            {/* reward */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row' },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['ImageBag'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: 24, marginRight: 10, width: 24 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['poppins-14-regular-white']
                  .props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['poppins-14-regular-white']
                      .style,
                    { letterSpacing: 1 }
                  ),
                  dimensions.width
                )}
              >
                {'Reward'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['headling-19'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['headling-19'].style,
                    { fontFamily: 'Oswald_700Bold', fontSize: 49 }
                  ),
                  dimensions.width
                )}
              >
                {'$150'}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  { justifyContent: 'center', width: '50%' },
                  dimensions.width
                )}
              >
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  iconSize={14}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: palettes.App['TFA-White'],
                        borderRadius: 35,
                        color: palettes.App.Thriller,
                        fontFamily: 'Poppins_500Medium',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Get bonus'}
                />
              </View>
            </View>
          </ImageBackground>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PromoDepositBonusScreen);
