const completeDateTime = timestamp => {
  const date = new Date(Number(timestamp));

  // Define options for date formatting
  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  // Format the date into the specified format
  const formattedDate = date.toLocaleString('en-GB', options).replace(',', '');

  return formattedDate;
};

export default completeDateTime;
