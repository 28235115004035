import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const intiateOpenBankingPOST = async (
  Constants,
  { account_type },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['account_type'] = account_type !== undefined ? account_type : '';
  const url = `https://api.thrillerfantasy.com/api:wnCg56_Q/banking/intiate${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    body: JSON.stringify({ key: 'value' }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'x-data-source': Constants['X-Data-Source'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useIntiateOpenBankingPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      intiateOpenBankingPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('banking', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('banking');
        queryClient.invalidateQueries('bankings');
      },
    }
  );
};

export const FetchIntiateOpenBankingPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  account_type,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useIntiateOpenBankingPOST(
    { account_type },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchIntiateOpenBanking: refetch });
};

export const paymentDepositPOST = async (
  Constants,
  { amount, payment_method },
  handlers = {}
) => {
  const paramsDict = {};
  if (amount !== undefined) {
    paramsDict['amount'] = amount;
  }
  if (payment_method !== undefined) {
    paramsDict['payment_method'] = payment_method;
  }
  const url = `https://api.thrillerfantasy.com/api:wnCg56_Q/payments/refill${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    body: JSON.stringify({ key: 'value' }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'x-data-source': Constants['X-Data-Source'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePaymentDepositPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      paymentDepositPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Deposit', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Deposit');
        queryClient.invalidateQueries('Deposits');
      },
    }
  );
};

export const FetchPaymentDepositPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  amount,
  payment_method,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePaymentDepositPOST(
    { amount, payment_method },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPaymentDeposit: refetch });
};

export const verifyBankAccountPOST = async (
  Constants,
  { first_transaction_amount, funding_id, second_transaction_amount },
  handlers = {}
) => {
  const paramsDict = {};
  if (funding_id !== undefined) {
    paramsDict['funding_id'] = funding_id;
  }
  if (first_transaction_amount !== undefined) {
    paramsDict['first_transaction_amount'] = first_transaction_amount;
  }
  if (second_transaction_amount !== undefined) {
    paramsDict['second_transaction_amount'] = second_transaction_amount;
  }
  const url = `https://api.thrillerfantasy.com/api:wnCg56_Q/dwolla/verify-micro-deposit${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    body: JSON.stringify({ key: 'value' }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AuthToken'],
      'Content-Type': 'application/json',
      'x-data-source': Constants['X-Data-Source'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useVerifyBankAccountPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      verifyBankAccountPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('verification', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('verification');
        queryClient.invalidateQueries('verifications');
      },
    }
  );
};

export const FetchVerifyBankAccountPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  first_transaction_amount,
  funding_id,
  second_transaction_amount,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useVerifyBankAccountPOST(
    { first_transaction_amount, funding_id, second_transaction_amount },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchVerifyBankAccount: refetch });
};
