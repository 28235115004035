import React from 'react';
import {
  Button,
  IconButton,
  Picker,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as DwollaXanoCallsApi from '../apis/DwollaXanoCallsApi.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import generateACHDropDown from '../global-functions/generateACHDropDown';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const TopUpRefillScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [error_message, setError_message] = React.useState('');
  const [is_payment_method_available, setIs_payment_method_available] =
    React.useState(false);
  const [max_withdraw_amount, setMax_withdraw_amount] = React.useState(0);
  const [payment_methods, setPayment_methods] = React.useState([]);
  const [pickerValue, setPickerValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const dwollaXanoCallsPaymentDepositPOST =
    DwollaXanoCallsApi.usePaymentDepositPOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      {/* top-view */}
      <View style={StyleSheet.applyWidth({ marginTop: 30 }, dimensions.width)}>
        {/* top-box */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginRight: 20 },
            dimensions.width
          )}
        >
          {/* nav-top */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 10,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            {/* nav-top__left */}
            <View
              style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
            >
              <IconButton
                onPress={() => {
                  try {
                    navigation.navigate('PurchasesListScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                color={palettes.App['Form Placeholder Text color']}
                icon={'Feather/arrow-left'}
              />
            </View>
            {/* nav-top__center */}
            <View>
              {/* Refill */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: '"rgb(255, 255, 255)"',
                      fontFamily: 'Roboto_700Bold',
                      fontSize: 24,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Refill'}
              </Text>
            </View>
            {/* nav-top__right */}
            <View
              style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
            />
          </View>
        </View>
        {/* form */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginRight: 20, marginTop: 40 },
            dimensions.width
          )}
        >
          {/* Amount of money */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['poppins-14-regular-white']
              .props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['poppins-14-regular-white']
                  .style,
                {
                  color: palettes.App['#7F7685'],
                  fontFamily: 'Roboto_400Regular',
                }
              ),
              dimensions.width
            )}
          >
            {'Amount of money'}
          </Text>
          {/* field-1 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: palettes.App['Form Field BG'],
                borderRadius: 35,
                flexDirection: 'row',
                marginTop: 10,
                paddingLeft: 15,
                paddingRight: 15,
              },
              dimensions.width
            )}
          >
            {/* $ */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                .props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                    .style,
                  { fontFamily: 'Poppins_400Regular' }
                ),
                dimensions.width
              )}
            >
              {'$'}
            </Text>
            {/* input-amount */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newInputAmountValue => {
                const textInputValue = newInputAmountValue;
                try {
                  setTextInputValue2(newInputAmountValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              placeholder={'Enter The Amount'}
              placeholderTextColor={palettes.App['TFA-White']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    borderColor: 'rgba(0, 0, 0, 0)',
                    borderTopWidth: 0,
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_500Medium',
                    width: '100%',
                  }
                ),
                dimensions.width
              )}
              value={textInputValue2}
            />
          </View>
          {/* field-description */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', marginTop: 15 },
              dimensions.width
            )}
          >
            <ThrillerXanoApi.FetchUserBalanceGET
              handlers={{
                onData: fetchData => {
                  try {
                    setMax_withdraw_amount(fetchData?.amount);
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
            >
              {({ loading, error, data, refetchUserBalance }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* Your Balance: */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['TFA-StatsBlockText'],
                            fontFamily: 'Roboto_400Regular',
                            marginBottom: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Your Balance: '}
                    </Text>
                    {/* $5,384.00 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['TFA-White'],
                            fontFamily: 'Roboto_500Medium',
                            marginBottom: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'$ ' + (fetchData?.balance + fetchData?.bonus_money)}
                    </Text>
                  </>
                );
              }}
            </ThrillerXanoApi.FetchUserBalanceGET>
          </View>
          {/* +$100 deposit bonus will be credited to your account 2 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['poppins-14-regular-white']
              .props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['poppins-14-regular-white']
                  .style,
                {
                  color: palettes.App['Thriller Pink'],
                  fontFamily: 'Roboto_500Medium',
                  fontSize: 12,
                  marginTop: 10,
                }
              ),
              dimensions.width
            )}
          >
            {'Get Double Cash on First Recharge (max $250)'}
          </Text>
          {/* withdraw-form__item-2 */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 30 }, dimensions.width)}
          >
            <>
              {!(is_payment_method_available === false) ? null : (
                <View>
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App.Peoplebit_Salmon_Red,
                          fontFamily: 'Roboto_400Regular',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'You dont have any verified payment method yet. Click on below link to add one.'
                    }
                  </Text>
                </View>
              )}
            </>
            <ThrillerXanoApi.FetchPayoutDetailsGET
              handlers={{
                onData: fetchData => {
                  try {
                    if (fetchData?.length >= 1) {
                      setIs_payment_method_available(true);
                      setPayment_methods(generateACHDropDown(fetchData));
                    } else {
                      setIs_payment_method_available(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
            >
              {({ loading, error, data, refetchPayoutDetails }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {!is_payment_method_available ? null : (
                      <View>
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App['TFA-StatsBlockText'],
                                fontFamily: 'Poppins_500Medium',
                                marginBottom: 10,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Select Payment Method'}
                        </Text>
                        <Picker
                          autoDismissKeyboard={true}
                          dropDownBorderRadius={8}
                          dropDownBorderWidth={1}
                          iconSize={24}
                          leftIconMode={'inset'}
                          onValueChange={newPickerValue => {
                            try {
                              setPickerValue(newPickerValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          selectedIconName={'Feather/check'}
                          selectedIconSize={20}
                          type={'solid'}
                          dropDownBackgroundColor={StyleSheet.getWidthValue(
                            [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App['Custom Color 2'],
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: palettes.App['Custom Color 2'],
                              },
                            ],
                            dimensions.width
                          )}
                          dropDownBorderColor={StyleSheet.getWidthValue(
                            [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App['Custom Color 2'],
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: palettes.App['Custom Color 2'],
                              },
                            ],
                            dimensions.width
                          )}
                          dropDownTextColor={palettes.App['TFA-White']}
                          iconSize={StyleSheet.getWidthValue(
                            [{ minWidth: Breakpoints.Laptop, value: 18 }],
                            dimensions.width
                          )}
                          mode={'dropdown-modal'}
                          options={payment_methods}
                          placeholder={'Select Payment Method'}
                          placeholderTextColor={palettes.App['TFA-White']}
                          rightIconName={'AntDesign/caretdown'}
                          selectedIconColor={palettes.App['TFA-White']}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['TFA-White'],
                              fontFamily: 'Roboto_400Regular',
                              fontSize: 14,
                            },
                            dimensions.width
                          )}
                          value={pickerValue}
                        />
                      </View>
                    )}
                  </>
                );
              }}
            </ThrillerXanoApi.FetchPayoutDetailsGET>
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('AddRefillMethodsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App.Studily_Blue_Night,
                      fontFamily: 'Roboto_400Regular',
                      fontSize: 16,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Manage Payment Method'}
              </Text>
            </Touchable>
          </View>
          {/* error-text */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['poppins-14-regular-white']
              .props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['poppins-14-regular-white']
                  .style,
                {
                  color: palettes.App['Custom Color_8'],
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 18,
                  marginTop: 40,
                }
              ),
              dimensions.width
            )}
          >
            {error_message}
          </Text>
        </View>
      </View>
      {/* bottom-view */}
      <View
        style={StyleSheet.applyWidth(
          { marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* Buy */}
        <Button
          accessible={true}
          iconPosition={'left'}
          onPress={() => {
            const handler = async () => {
              try {
                const resp1 = (
                  await dwollaXanoCallsPaymentDepositPOST.mutateAsync({
                    amount: textInputValue2,
                    payment_method: pickerValue,
                  })
                )?.json;
                if (extractErrorFromResponse(resp1) === 'false') {
                  console.log('going on true');
                  navigation.navigate('RefillSuccessP1InProgressScreen', {
                    payment_id: resp1?.id,
                  });
                } else {
                  console.log(resp1);
                  setError_message(extractErrorFromResponse(resp1));
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
          iconSize={14}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button'].style,
              {
                backgroundColor: palettes.App['TFA-White'],
                borderRadius: 35,
                color: palettes.App.Thriller,
                fontFamily: 'Roboto_500Medium',
                marginBottom: 30,
              }
            ),
            dimensions.width
          )}
          title={'Buy'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(TopUpRefillScreen);
