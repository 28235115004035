import React from 'react';
import {
  Button,
  Divider,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import Images from '../config/Images';
import date_month from '../global-functions/date_month';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  market_id: null,
  player_id: null,
  player_image: null,
  player_name: null,
  player_position: null,
  player_prop_id: null,
  player_team: null,
  schedule_id: null,
};

const CurrentEntryPlayerP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['TFA-ScreenBG'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['PlayerBg'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { height: 250 }
          ),
          dimensions.width
        )}
      >
        <SimpleStyleScrollView
          bounces={true}
          horizontal={false}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
        >
          <ThrillerXanoApi.FetchGetPlayerStatsGET
            player_prop_id={
              props.route?.params?.player_prop_id ?? defaultProps.player_prop_id
            }
          >
            {({ loading, error, data, refetchGetPlayerStats }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* container */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        marginLeft: 20,
                        marginRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {/* profile-img */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', marginTop: 40 },
                        dimensions.width
                      )}
                    >
                      {/* Image 2 */}
                      <Image
                        resizeMode={'cover'}
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        source={imageSource(
                          fetchData?.player?._player_data?.player_image?.url
                            ? fetchData?.player?._player_data?.player_image?.url
                            : Images['jerseyremovebgpreview']
                        )}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['headling-19'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['headling-19'].style,
                            {
                              fontFamily: 'Oswald_700Bold',
                              fontSize: 34,
                              marginTop: 10,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchData?.player?._player_data?.name}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'poppins-16-bold-white'
                        ].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)[
                              'poppins-16-bold-white'
                            ].style,
                            {
                              color: palettes.App['TFA-StatsBlockText'],
                              fontFamily: 'Poppins_500Medium',
                              marginTop: 10,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchData?.player?._teams_nfl?.alias}
                        {' - '}
                        {fetchData?.player?._player_data?.position}
                      </Text>
                    </View>
                    {/* points */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          gap: 10,
                          justifyContent: 'space-between',
                          marginTop: 20,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['Form Field BG'],
                            borderRadius: 16,
                            flex: 1,
                            flexGrow: 1,
                            justifyContent: 'space-between',
                            padding: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['body-content']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['body-content']
                                .style,
                              { fontFamily: 'Roboto_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Average Last '}
                          {fetchData?.data?.length}
                        </Text>

                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['headling-19']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['headling-19']
                                .style,
                              {
                                fontFamily: 'Roboto_700Bold',
                                fontSize: 40,
                                marginTop: 15,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchData?.average}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['Form Field BG'],
                            borderRadius: 16,
                            flex: 1,
                            flexGrow: 1,
                            justifyContent: 'space-between',
                            padding: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['body-content']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['body-content']
                                .style,
                              { fontFamily: 'Roboto_400Regular' }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Projection Points \n('}
                          {fetchData?.player?._market?.title}
                          {')'}
                        </Text>

                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['headling-19']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['headling-19']
                                .style,
                              {
                                fontFamily: 'Roboto_700Bold',
                                fontSize: 40,
                                marginTop: 15,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchData?.player?.projection_point}
                        </Text>
                      </View>
                    </View>
                    {/* details */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App['TFA-InputBG'],
                          borderRadius: 16,
                          marginTop: 20,
                          padding: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {/* 2024 */}
                      <View>
                        {/* Year */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'poppins-14-regular-white'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)[
                                'poppins-14-regular-white'
                              ].style,
                              {
                                color: palettes.App['Custom Color_19'],
                                fontFamily: 'Roboto_700Bold',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Previous Games'}
                        </Text>
                        <Divider
                          {...GlobalStyles.DividerStyles(theme)['Divider']
                            .props}
                          color={palettes.App['TFA-Point-Divider']}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.DividerStyles(theme)['Divider']
                                .style,
                              { marginBottom: 10, marginTop: 10 }
                            ),
                            dimensions.width
                          )}
                        />
                        {/* tr-title */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: 10,
                            },
                            dimensions.width
                          )}
                        >
                          {/* td-1 */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['body-content']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['body-content']
                                    .style,
                                  { fontFamily: 'Roboto_400Regular' }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Day'}
                            </Text>
                          </View>
                          {/* td-2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['body-content']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['body-content']
                                    .style,
                                  {
                                    alignSelf: 'center',
                                    fontFamily: 'Roboto_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Opponents'}
                            </Text>
                          </View>
                          {/* td-3 */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['body-content']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['body-content']
                                    .style,
                                  {
                                    alignSelf: 'flex-end',
                                    fontFamily: 'Roboto_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {fetchData?.player?._market?.title}
                            </Text>
                          </View>
                        </View>
                        <SimpleStyleFlatList
                          data={fetchData?.data}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'OfbPQQJ4'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* td-content-1 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      marginBottom: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* td-1 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'poppins-14-medium-white'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'poppins-14-medium-white'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {date_month(
                                        listData?._seasons_schedule_nfl
                                          ?.scheduled
                                      )}
                                    </Text>
                                  </View>
                                  {/* td-2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'poppins-14-medium-white'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'poppins-14-medium-white'
                                          ].style,
                                          { alignSelf: 'center' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.alias}
                                    </Text>
                                  </View>
                                  {/* td-3 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'poppins-14-medium-white'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'poppins-14-medium-white'
                                          ].style,
                                          { alignSelf: 'flex-end' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.total}
                                    </Text>
                                  </View>
                                </View>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                      </View>
                    </View>
                  </View>
                </>
              );
            }}
          </ThrillerXanoApi.FetchGetPlayerStatsGET>
          {/* bottom-box */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginBottom: 20,
                marginLeft: 20,
                marginRight: 20,
                marginTop: 20,
              },
              dimensions.width
            )}
          >
            {/* Return to slate */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'FeedMainP1InProgressScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: palettes.App['TFA-White'],
                    borderRadius: 30,
                    color: '"rgb(15, 4, 21)"',
                    fontFamily: 'Roboto_500Medium',
                    paddingBottom: 8,
                    paddingTop: 8,
                    width: '100%',
                  }
                ),
                dimensions.width
              )}
              title={'Return to slate'}
            />
          </View>
        </SimpleStyleScrollView>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(CurrentEntryPlayerP1InProgressScreen);
