import React from 'react';
import { IconButton, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const QuestionsP1InProgressScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      {/* top-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* nav-top */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 10,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* nav-top__left */}
          <View style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}>
            <IconButton
              size={32}
              color={palettes.App['Form Placeholder Text color']}
              icon={'Feather/arrow-left'}
            />
          </View>
          {/* nav-top__center */}
          <View>
            {/* Question */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: '"rgb(255, 255, 255)"',
                    fontFamily: 'Oswald_700Bold',
                    fontSize: 24,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Question'}
            </Text>
          </View>
          {/* nav-top__right */}
          <View
            style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
          />
        </View>
        {/* content-area */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
        >
          {/* headline-1 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['TFA-White'],
                fontFamily: 'Oswald_700Bold',
                fontSize: 24,
                marginBottom: 20,
              }),
              dimensions.width
            )}
          >
            {'How do I create my content?'}
          </Text>
          {/* Content-1 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['TFA-StatsBlockText'],
                fontFamily: 'Poppins_400Regular',
              }),
              dimensions.width
            )}
          >
            {
              'We may collect personal information that you provide directly to us, such as your name, email address, and any other information you choose to share. Additionally, we may automatically collect certain information when you use our App, including device information, IP address, and usage patterns.'
            }
          </Text>
          {/* Content-2 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['TFA-StatsBlockText'],
                fontFamily: 'Poppins_400Regular',
                marginTop: 20,
              }),
              dimensions.width
            )}
          >
            {
              'We may use the information we collect for various purposes, including:\n\n1. Providing, maintaining, and improving our App.\n2. Sending you updates, notifications, and other communications.\n3. Responding to your inquiries and providing customer support.\n4. Analyzing usage patterns to enhance user experience.'
            }
          </Text>
        </View>
      </View>
      {/* botttom-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginBottom: 60, marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['body-content'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.TextStyles(theme)['body-content'].style,
              { textAlign: 'center' }
            ),
            dimensions.width
          )}
        >
          {'Have we answered your question?'}
        </Text>

        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 20,
            },
            dimensions.width
          )}
        >
          {/* circle-btn-1 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: '"rgb(46, 40, 50)"',
                borderRadius: 100,
                height: 87,
                justifyContent: 'center',
                overflow: 'hidden',
                width: 87,
              },
              dimensions.width
            )}
          >
            {/* thumbs-down */}
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(Images['ThumbsDown'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { height: 28, width: 28 }
                ),
                dimensions.width
              )}
            />
            {/* No */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['body-content'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                    marginTop: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'No'}
            </Text>
          </View>
          {/* circle-btn-2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: '"rgb(46, 40, 50)"',
                borderRadius: 100,
                height: 87,
                justifyContent: 'center',
                overflow: 'hidden',
                width: 87,
              },
              dimensions.width
            )}
          >
            {/* thumbs-up */}
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(Images['ThumbsUp'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { height: 28, width: 28 }
                ),
                dimensions.width
              )}
            />
            {/* Yes */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['body-content'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                    marginTop: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'Yes'}
            </Text>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(QuestionsP1InProgressScreen);
