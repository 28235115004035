const askLocationPermission = async () => {
  try {
    const locationData = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        locationData => resolve(locationData),
        error => reject(error.code),
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    });

    // Extract latitude and longitude from location data
    const { latitude, longitude } = locationData.coords;
    console.log('Latitude:', latitude, 'Longitude:', longitude);
    return 'success';
  } catch (errorCode) {
    // Alerting the user about the specific error
    let errorMessage;
    switch (errorCode) {
      case 1:
        errorMessage =
          'Location Permission denied: Please enable location services.';
        break;
      case 2:
        errorMessage = 'Location unavailable: Unable to determine location.';
        break;
      case 3:
        errorMessage = 'Timeout: The request to get location timed out.';
        break;
      default:
        errorMessage =
          'You must turn on Location Services from Browser Settings.';
    }

    return errorMessage; // Returns the error code
  }
};

export default askLocationPermission;
