import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { ImageBackground, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const BalanceEmptyP1InProgressScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['TopRectangleProfileThrillerFantasy'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { height: 170 }
          ),
          dimensions.width
        )}
      >
        {/* top-view */}
        <View>
          {/* top-box */}
          <View
            style={StyleSheet.applyWidth(
              { marginLeft: 20, marginRight: 20 },
              dimensions.width
            )}
          >
            {/* nav-top */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 10,
                  paddingTop: 10,
                },
                dimensions.width
              )}
            >
              {/* nav-top__left */}
              <View
                style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
              >
                <IconButton
                  size={32}
                  color={palettes.App['Form Placeholder Text color']}
                  icon={'Feather/arrow-left'}
                />
              </View>
              {/* nav-top__center */}
              <View>
                {/* Balance */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: '"rgb(255, 255, 255)"',
                        fontFamily: 'Oswald_700Bold',
                        fontSize: 24,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Balance'}
                </Text>
              </View>
              {/* nav-top__right */}
              <View
                style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
              />
            </View>
          </View>
          {/* view-amount */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 30 }, dimensions.width)}
          >
            {/* amount */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-end',
                  flexDirection: 'row',
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App['TFA-White'],
                      fontFamily: 'Oswald_700Bold',
                      fontSize: 48,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'$0'}
              </Text>
            </View>
            {/* btn-wrap */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              {/* btn-view-details */}
              <Button
                accessible={true}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                icon={'Feather/chevron-right'}
                iconPosition={'right'}
                iconSize={18}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderRadius: 0,
                      color: palettes.App['Thriller Pink'],
                      fontFamily: 'Poppins_500Medium',
                      lineHeight: 0,
                      width: '40%',
                    }
                  ),
                  dimensions.width
                )}
                title={'View details'}
              />
            </View>
          </View>
          {/* view-btns */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 20,
                marginTop: 10,
                paddingRight: 20,
              },
              dimensions.width
            )}
          >
            {/* btn-left */}
            <View
              style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
            >
              {/* Cancel */}
              <Button
                accessible={true}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                icon={'AntDesign/arrowup'}
                iconPosition={'right'}
                iconSize={14}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: palettes.App['Form Field BG'],
                      borderRadius: 35,
                      color: palettes.App['TFA-White'],
                      fontFamily: 'Poppins_500Medium',
                    }
                  ),
                  dimensions.width
                )}
                title={'Refill  '}
              />
            </View>
            {/* btn-right */}
            <View
              style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
            >
              {/* Go to my wallet */}
              <Button
                accessible={true}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                icon={'AntDesign/arrowdown'}
                iconPosition={'right'}
                iconSize={14}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: palettes.App['TFA-White'],
                      borderRadius: 35,
                      color: palettes.App.Thriller,
                      fontFamily: 'Poppins_500Medium',
                    }
                  ),
                  dimensions.width
                )}
                title={'Withdraw   '}
              />
            </View>
          </View>
        </View>
        {/* view-transactions */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginRight: 20, marginTop: 30 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['headling-19'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['headling-19'].style,
                { fontSize: 24 }
              ),
              dimensions.width
            )}
          >
            {'Transactions'}
          </Text>
        </View>
        {/* empty-box */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', height: '60%', justifyContent: 'center' },
            dimensions.width
          )}
        >
          <Icon
            size={24}
            color={palettes.App['TFA-StatsBlockText']}
            name={'AntDesign/clockcircle'}
          />
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['poppins-14-medium-white'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['poppins-14-medium-white'].style,
                {
                  color: palettes.App['TFA-StatsBlockText'],
                  marginTop: 10,
                  textAlign: 'center',
                }
              ),
              dimensions.width
            )}
          >
            {'The history of your transactions\nwill appear here'}
          </Text>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(BalanceEmptyP1InProgressScreen);
