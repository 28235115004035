import React from 'react';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, ImageBackground, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const ContactUsSuccessP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [textAreaValue, setTextAreaValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          height: 375,
          justifyContent: 'space-evenly',
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['ContactBgSuccess'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { height: 375, justifyContent: 'space-evenly' }
          ),
          dimensions.width
        )}
      >
        <View />
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginLeft: 20, marginRight: 20 },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={imageSource(Images['CheckCircle'])}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { height: 48, width: 48 }
              ),
              dimensions.width
            )}
          />
          {/* Your feedback has been sent */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['TFA-White'],
                fontFamily: 'Oswald_700Bold',
                fontSize: 29,
              }),
              dimensions.width
            )}
          >
            {'Your feedback has been sent'}
          </Text>

          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['TFA-StatsBlockText'],
                fontFamily: 'Poppins_400Regular',
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {
              'The answer to your message will be sent to the email connected to your account'
            }
          </Text>
        </View>
      </ImageBackground>
      {/* bottom-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginBottom: 60, marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* bottom-button */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', justifyContent: 'space-between' },
            dimensions.width
          )}
        >
          {/* Return to slate */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('BottomTabNavigator', {
                  screen: 'FeedMainP1InProgressScreen',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['TFA-White'],
                  borderRadius: 30,
                  color: '"rgb(15, 4, 21)"',
                  fontFamily: 'Poppins_500Medium',
                  paddingBottom: 8,
                  paddingTop: 8,
                  width: '100%',
                }
              ),
              dimensions.width
            )}
            title={'Return to slate'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ContactUsSuccessP1InProgressScreen);
