const date_month = time => {
  const timestamp = time; // Replace this with your API value
  const date = new Date(Number(timestamp));

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = months[date.getMonth()]; // Get the month name (Jan, Feb, etc.)
  const day = date.getDate(); // Get the day of the month

  // Format the final date string as "Jan 22"
  const formattedDate = `${month} ${day}`;
  return formattedDate;
};

export default date_month;
