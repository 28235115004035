export default {
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Oswald_700Bold: require('../assets/fonts/Oswald_700Bold.ttf'),
  Oswald_400Regular: require('../assets/fonts/Oswald_400Regular.ttf'),
  Oswald_600SemiBold: require('../assets/fonts/Oswald_600SemiBold.ttf'),
  Poppins_500Medium: require('../assets/fonts/Poppins_500Medium.ttf'),
  Poppins_700Bold: require('../assets/fonts/Poppins_700Bold.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_200ExtraLight: require('../assets/fonts/Poppins_200ExtraLight.ttf'),
  Poppins_600SemiBold: require('../assets/fonts/Poppins_600SemiBold.ttf'),
  Roboto_400Regular: require('../assets/fonts/Roboto_400Regular.ttf'),
  Roboto_700Bold: require('../assets/fonts/Roboto_700Bold.ttf'),
  Roboto_500Medium: require('../assets/fonts/Roboto_500Medium.ttf'),
  RobotoFlex_400Regular: require('../assets/fonts/RobotoFlex_400Regular.ttf'),
  RobotoMono_500Medium: require('../assets/fonts/RobotoMono_500Medium.ttf'),
  RobotoMono_400Regular: require('../assets/fonts/RobotoMono_400Regular.ttf'),
};
