import React from 'react';
import {
  Button,
  CheckboxRow,
  IconButton,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const LogInMainScreenP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [checkboxRowValue, setCheckboxRowValue] = React.useState('');
  const [error_message, setError_message] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setError_message('');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.Thriller },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: [
              { minWidth: Breakpoints.Mobile, value: 'stretch' },
              { minWidth: Breakpoints.Laptop, value: 'stretch' },
            ],
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            flexDirection: 'row',
            justifyContent: [
              { minWidth: Breakpoints.Mobile, value: 'flex-start' },
              { minWidth: Breakpoints.Laptop, value: 'flex-start' },
            ],
            marginTop: 50,
            padding: 12,
            width: { minWidth: Breakpoints.Laptop, value: 500 },
          },
          dimensions.width
        )}
      >
        <IconButton
          onPress={() => {
            try {
              navigation.navigate('SignUpLogInScreenP1InProgressScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          color={palettes.App['Form Placeholder Text color']}
          icon={'AntDesign/arrowleft'}
        />
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: palettes.App.White,
              fontFamily: 'Roboto_700Bold',
              fontSize: 25,
              letterSpacing: 2,
              lineHeight: 27,
              marginLeft: 115,
              textAlign: 'auto',
            }),
            dimensions.width
          )}
        >
          {'Log in'}
        </Text>
      </View>
      {/* Login Box */}
      <View
        style={StyleSheet.applyWidth(
          { marginTop: 40, paddingLeft: 20, paddingRight: 20 },
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              marginTop: 35,
              paddingLeft: 12,
              paddingRight: 12,
              width: { minWidth: Breakpoints.Laptop, value: 500 },
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['TFA-White'],
                fontFamily: 'Roboto_500Medium',
                fontSize: 15,
              }),
              dimensions.width
            )}
          >
            {'Email'}
          </Text>
          {/* Email input */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newEmailInputValue => {
              const textInputValue = newEmailInputValue;
              try {
                setTextInputValue(newEmailInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            autoComplete={'email'}
            clearButtonMode={'while-editing'}
            placeholder={'Enter your email'}
            placeholderTextColor={palettes.App['Form Placeholder Text color']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                {
                  backgroundColor: palettes.App['Form Field BG'],
                  borderColor: null,
                  borderRadius: 28,
                  color: palettes.App.White,
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 15,
                  height: 50,
                  marginTop: 5,
                  textAlign: 'auto',
                }
              ),
              dimensions.width
            )}
            value={textInputValue}
          />
        </View>
        {/* View 3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              marginTop: 20,
              paddingLeft: 12,
              paddingRight: 12,
              width: { minWidth: Breakpoints.Laptop, value: 500 },
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['TFA-White'],
                fontFamily: 'Roboto_500Medium',
                fontSize: 15,
              }),
              dimensions.width
            )}
          >
            {'Password'}
          </Text>
          {/* password */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newPasswordValue => {
              const textInputValue = newPasswordValue;
              try {
                setTextInputValue2(newPasswordValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
            autoFocus={false}
            clearButtonMode={'never'}
            clearTextOnFocus={false}
            placeholder={'Enter your password'}
            placeholderTextColor={palettes.App['Form Placeholder Text color']}
            secureTextEntry={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                {
                  backgroundColor: palettes.App['Form Field BG'],
                  borderColor: null,
                  borderRadius: 28,
                  color: palettes.App.White,
                  fontFamily: 'Roboto_400Regular',
                  fontSize: 15,
                  height: 50,
                  marginTop: 5,
                }
              ),
              dimensions.width
            )}
            value={textInputValue2}
          />
        </View>
        {/* View 5 */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', marginTop: 10 },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', width: '50%' },
              dimensions.width
            )}
          >
            <CheckboxRow
              onPress={newCheckboxRowValue => {
                const checkboxRowValue = newCheckboxRowValue;
                try {
                  setCheckboxRowValue(newCheckboxRowValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row'].props}
              direction={'row-reverse'}
              label={''}
              size={25}
              status={checkboxRowValue}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.CheckboxRowStyles(theme)['Checkbox Row'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_400Regular',
                    paddingRight: 10,
                  }
                ),
                dimensions.width
              )}
              uncheckedColor={palettes.App.Studily_Gray_Machine}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_400Regular',
                    marginTop: 18,
                  }
                ),
                dimensions.width
              )}
            >
              {'Remember Me'}
            </Text>
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth({ width: '50%' }, dimensions.width)}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('ForgotPasswordScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* View 4 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-end',
                    },
                    alignSelf: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    flexDirection: [
                      { minWidth: Breakpoints.Mobile, value: 'row' },
                      { minWidth: Breakpoints.Laptop, value: 'row' },
                    ],
                    justifyContent: [
                      { minWidth: Breakpoints.Mobile, value: 'flex-end' },
                      { minWidth: Breakpoints.Laptop, value: 'flex-end' },
                    ],
                    marginTop: 5,
                    padding: 12,
                    width: { minWidth: Breakpoints.Laptop, value: 500 },
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Thriller Pink'],
                        fontFamily: 'Roboto_400Regular',
                        fontSize: 15,
                        textDecorationLine: 'underline',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Forgot password?'}
                </Text>
              </View>
            </Touchable>
          </View>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginTop: 30 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['Custom Color_8'],
                fontFamily: 'Roboto_400Regular',
                fontSize: 18,
                marginBottom: 10,
              }),
              dimensions.width
            )}
          >
            {error_message}
          </Text>
        </View>
        {/* View 4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'stretch' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
              ],
              alignSelf: [
                { minWidth: Breakpoints.Mobile, value: 'stretch' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
              ],
              justifyContent: 'center',
              marginTop: 200,
              paddingLeft: 12,
              paddingRight: 12,
              width: { minWidth: Breakpoints.Laptop, value: 500 },
            },
            dimensions.width
          )}
        >
          {/* Log in Button */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  const resp_login = (
                    await ThrillerXanoApi.userLoginPOST(Constants, {
                      email: textInputValue,
                      password: textInputValue2,
                    })
                  )?.json;
                  if (extractErrorFromResponse(resp_login) === 'false') {
                    setGlobalVariableValue({
                      key: 'AuthToken',
                      value: resp_login?.token,
                    });
                    setGlobalVariableValue({
                      key: 'User',
                      value: resp_login?.user_data,
                    });
                    navigation.navigate('BottomTabNavigator', {
                      screen: 'FeedMainP1InProgressScreen',
                    });
                  } else {
                    setError_message(extractErrorFromResponse(resp_login));
                  }
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App.White,
                  borderRadius: 37,
                  color: palettes.App.Thriller,
                  fontFamily: 'Roboto_500Medium',
                  fontSize: 15,
                  width: { minWidth: Breakpoints.Laptop, value: '100%' },
                }
              ),
              dimensions.width
            )}
            title={'Log in'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(LogInMainScreenP1InProgressScreen);
