import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  Picker,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const TopUpP1InProgressP1InProgressScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const [inputCurrentPassValue, setInputCurrentPassValue] = React.useState('');
  const [inputCurrentPassValue2, setInputCurrentPassValue2] =
    React.useState('');
  const [inputNewPassValue, setInputNewPassValue] = React.useState('');
  const [multiSelectPickerValue, setMultiSelectPickerValue] = React.useState(
    []
  );
  const [pickerValue, setPickerValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      {/* top-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginLeft: 20, marginRight: 20, marginTop: 30 },
          dimensions.width
        )}
      >
        {/* nav-top */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 10,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* nav-top__left */}
          <View style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}>
            <IconButton
              size={32}
              color={palettes.App['Form Placeholder Text color']}
              icon={'Feather/arrow-left'}
            />
          </View>
          {/* nav-top__center */}
          <View>
            {/* Refill */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: '"rgb(255, 255, 255)"',
                    fontFamily: 'Oswald_700Bold',
                    fontSize: 24,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Refill'}
            </Text>
          </View>
          {/* nav-top__right */}
          <View
            style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
          />
        </View>
        {/* withdraw-form */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 20, zIndex: -1 },
            dimensions.width
          )}
        >
          {/* withdraw-form__item-1 */}
          <View>
            {/* Amount of money */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Poppins_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'Amount of money'}
            </Text>
            {/* amount */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App['TFA-InputBG'],
                  borderRadius: 30,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingRight: 10,
                },
                dimensions.width
              )}
            >
              {/* inputAmountOfMoney */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={'Enter amount of money'}
                placeholderTextColor={
                  palettes.App['Form Placeholder Text color']
                }
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    {
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      borderRadius: 30,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderWidth: 0,
                      color: palettes.App['TFA-White'],
                      fontFamily: 'Poppins_500Medium',
                      paddingBottom: 12,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 12,
                      width: '100%',
                    }
                  ),
                  dimensions.width
                )}
              />
            </View>
          </View>
          {/* withdraw-form__item-2 */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* Card */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Poppins_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'Card'}
            </Text>
            {/* select-card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App['TFA-InputBG'],
                  borderRadius: 30,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
                dimensions.width
              )}
            >
              <Picker
                autoDismissKeyboard={true}
                dropDownBorderColor={theme.colors.border.brand}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                dropDownTextColor={theme.colors.text.strong}
                iconSize={24}
                leftIconMode={'inset'}
                mode={'native'}
                onValueChange={newPickerValue => {
                  const pickerValue = newPickerValue;
                  try {
                    setPickerValue(newPickerValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectedIconColor={theme.colors.text.strong}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                dropDownBackgroundColor={'rgba(0, 0, 0, 0)'}
                placeholder={'Select card'}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderRadius: 35,
                    borderWidth: 0,
                    color: palettes.App['Form Placeholder Text color'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 14,
                    paddingBottom: 10,
                    paddingTop: 10,
                    width: '90%',
                  },
                  dimensions.width
                )}
                value={pickerValue}
              />
              <Icon
                color={palettes.App['TFA-StatsBlockText']}
                name={'Entypo/chevron-thin-down'}
                size={20}
                style={StyleSheet.applyWidth(
                  { marginRight: 10 },
                  dimensions.width
                )}
              />
            </View>
          </View>
        </View>
      </View>
      {/* bottom-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginBottom: 60, marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* bottom-button */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', justifyContent: 'space-between' },
            dimensions.width
          )}
        >
          {/* Buy */}
          <Button
            accessible={true}
            iconPosition={'left'}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['TFA-White'],
                  borderRadius: 30,
                  color: '"rgb(15, 4, 21)"',
                  fontFamily: 'Poppins_500Medium',
                  paddingBottom: 8,
                  paddingTop: 8,
                  width: '100%',
                }
              ),
              dimensions.width
            )}
            title={'Buy'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(TopUpP1InProgressP1InProgressScreen);
