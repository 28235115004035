import React from 'react';
import {
  Button,
  Divider,
  Icon,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const BalanceEntryDetailsP1InProgressScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'flex-end',
        },
        dimensions.width
      )}
    >
      {/* wrapper */}
      <View
        style={StyleSheet.applyWidth(
          { marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* top */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center' },
            dimensions.width
          )}
        >
          {/* circle */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: palettes.App['#7F7685'],
                borderRadius: 50,
                height: 80,
                justifyContent: 'center',
                width: 80,
              },
              dimensions.width
            )}
          >
            {/* $ */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 60,
                  }
                ),
                dimensions.width
              )}
            >
              {'$'}
            </Text>
          </View>
          {/* Winnings */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['body-content'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                { fontFamily: 'Poppins_500Medium', marginTop: 20 }
              ),
              dimensions.width
            )}
          >
            {'Winnings'}
          </Text>
          {/* Amount */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['Thriller Pink'],
                fontFamily: 'Oswald_700Bold',
                fontSize: 29,
                marginTop: 20,
              }),
              dimensions.width
            )}
          >
            {'+6,600$'}
          </Text>
        </View>
        <Divider
          {...GlobalStyles.DividerStyles(theme)['Divider'].props}
          color={palettes.App['TFA-Balance-Divider']}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.DividerStyles(theme)['Divider'].style,
              { marginBottom: 20, marginTop: 20 }
            ),
            dimensions.width
          )}
        />
        {/* entry-details */}
        <View>
          {/* Entry details */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['TFA-White'],
                fontFamily: 'Oswald_700Bold',
                fontSize: 19,
              }),
              dimensions.width
            )}
          >
            {'Entry details'}
          </Text>
          {/* entry-player */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                bottom: 5,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 10,
                paddingTop: 10,
                top: 5,
              },
              dimensions.width
            )}
          >
            {/* Player */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                dimensions.width
              )}
            >
              {'Player'}
            </Text>
            {/* entry-player__right */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row' },
                dimensions.width
              )}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={imageSource(Images['Player1'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 50, height: 32, marginRight: 10, width: 32 }
                  ),
                  dimensions.width
                )}
              />
              {/* content */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                    .style,
                  dimensions.width
                )}
              >
                {'O. Bitim'}
              </Text>
            </View>
          </View>
          {/* entry-game */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                bottom: 5,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 10,
                paddingTop: 10,
                top: 5,
              },
              dimensions.width
            )}
          >
            {/* Game */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                dimensions.width
              )}
            >
              {'Game'}
            </Text>
            {/* entry-player__right */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row' },
                dimensions.width
              )}
            >
              {/* content */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                    .style,
                  dimensions.width
                )}
              >
                {'MIL - C'}
              </Text>
            </View>
          </View>
          {/* entry-opponent */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                bottom: 5,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 10,
                paddingTop: 10,
                top: 5,
              },
              dimensions.width
            )}
          >
            {/* Opponent */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                dimensions.width
              )}
            >
              {'Opponent'}
            </Text>
            {/* entry-player__right */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row' },
                dimensions.width
              )}
            >
              {/* opponent-box */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['TFA-Pink'],
                    borderRadius: 10,
                    padding: 5,
                  },
                  dimensions.width
                )}
              >
                {/* content */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['headling-19'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['headling-19'].style,
                      { fontSize: 10 }
                    ),
                    dimensions.width
                  )}
                >
                  {'ORL'}
                </Text>
              </View>
            </View>
          </View>
          {/* entry-date-time */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                bottom: 5,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 10,
                paddingTop: 10,
                top: 5,
              },
              dimensions.width
            )}
          >
            {/* Date and time */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['body-content'].style,
                dimensions.width
              )}
            >
              {'Date and time'}
            </Text>
            {/* entry-player__right */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row' },
                dimensions.width
              )}
            >
              {/* icon-clock */}
              <Icon
                color={palettes.App['TFA-White']}
                name={'AntDesign/clockcircle'}
                size={20}
                style={StyleSheet.applyWidth(
                  { marginRight: 10 },
                  dimensions.width
                )}
              />
              {/* time */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                    .style,
                  dimensions.width
                )}
              >
                {'05:12'}
              </Text>
              {/* icon-dot */}
              <Icon
                color={palettes.App['TFA-White']}
                name={'Entypo/dot-single'}
                size={20}
                style={StyleSheet.applyWidth(
                  { marginLeft: 5, marginRight: 5 },
                  dimensions.width
                )}
              />
              {/* icon-calendar */}
              <Icon
                color={palettes.App['TFA-White']}
                name={'Ionicons/calendar-outline'}
                size={20}
                style={StyleSheet.applyWidth(
                  { marginRight: 10 },
                  dimensions.width
                )}
              />
              {/* date */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['poppins-14-medium-white']
                    .style,
                  dimensions.width
                )}
              >
                {'Jan 28'}
              </Text>
            </View>
          </View>
        </View>
        {/* bottom */}
        <View
          style={StyleSheet.applyWidth(
            { marginBottom: 40, marginTop: 20 },
            dimensions.width
          )}
        >
          {/* Close */}
          <Button
            accessible={true}
            iconPosition={'left'}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['Form Field BG'],
                  borderRadius: 35,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_500Medium',
                  marginTop: 10,
                }
              ),
              dimensions.width
            )}
            title={'Close'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(BalanceEntryDetailsP1InProgressScreen);
