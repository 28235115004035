import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Image, ImageBackground, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const HelpCenterP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          marginLeft: 20,
          marginRight: 20,
        },
        dimensions.width
      )}
    >
      {/* top-box */}
      <View>
        {/* nav-top */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 10,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* nav-top__left */}
          <View style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}>
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'MyProfileMainP1InProgressScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              color={palettes.App['Form Placeholder Text color']}
              icon={'Feather/arrow-left'}
            />
          </View>
          {/* nav-top__center */}
          <View>
            {/* Help Center */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: '"rgb(255, 255, 255)"',
                    fontFamily: 'Oswald_700Bold',
                    fontSize: 24,
                    opacity: 0,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Help Center'}
            </Text>
          </View>
          {/* nav-top__right */}
          <View
            style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
          />
        </View>
      </View>
      {/* search */}
      <View style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}>
        {/* search__title */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: palettes.App['TFA-White'],
              fontFamily: 'Oswald_700Bold',
              fontSize: 30,
            }),
            dimensions.width
          )}
        >
          {'Hello! How can we help you?'}
        </Text>
        {/* search__wrap */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: palettes.App['TFA-InputBG'],
              borderRadius: 30,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 20,
              paddingLeft: 10,
              paddingRight: 10,
            },
            dimensions.width
          )}
        >
          {/* search__left */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', flexGrow: 1 },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App['TFA-StatsBlockText']}
              name={'AntDesign/search1'}
              size={20}
            />
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newTextInputValue => {
                const textInputValue = newTextInputValue;
                try {
                  setTextInputValue(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              placeholder={'Search'}
              placeholderTextColor={palettes.App['TFA-StatsBlockText']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    borderColor: palettes.App['TFA-InputBG'],
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_400Regular',
                    width: '88%',
                  }
                ),
                dimensions.width
              )}
              value={textInputValue}
            />
          </View>
          {/* search__right */}
          <View>
            <Icon
              color={palettes.App['TFA-StatsBlockText']}
              name={'Ionicons/funnel-outline'}
              size={20}
            />
          </View>
        </View>
      </View>
      {/* how-to-play */}
      <View
        style={StyleSheet.applyWidth(
          { borderRadius: 20, marginTop: 60 },
          dimensions.width
        )}
      >
        <ImageBackground
          {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background']
            .props}
          resizeMode={'cover'}
          source={imageSource(Images['CardGradientBg'])}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ImageBackgroundStyles(theme)['Image Background']
                .style,
              { borderRadius: 20, overflow: 'hidden', padding: 20 }
            ),
            dimensions.width
          )}
        >
          {/* how-to-play__item-1 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(Images['Football'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { height: 24, marginRight: 10, width: 24 }
                ),
                dimensions.width
              )}
            />
            {/* How to play? */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['headling-19'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['headling-19'].style,
                dimensions.width
              )}
            >
              {'How to play?'}
            </Text>
          </View>
          {/* how-to-play__item-2 */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['body-content'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['body-content'].style,
                  { color: palettes.App['TFA-White'] }
                ),
                dimensions.width
              )}
            >
              {
                'Engage in our trial game – recruit players and make predictions. Witness the outcome of your bets and determine if you win. '
              }
            </Text>
          </View>
          {/* how-to-play__item-3 */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* View instruction */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  navigation.navigate('HowToPlayP1InProgressScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: palettes.App['TFA-White'],
                    borderRadius: 30,
                    color: '"rgb(15, 4, 21)"',
                    fontFamily: 'Poppins_500Medium',
                    paddingBottom: 8,
                    paddingTop: 8,
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '60%' },
                      { minWidth: Breakpoints.Tablet, value: '30%' },
                    ],
                  }
                ),
                dimensions.width
              )}
              title={'View instruction'}
            />
          </View>
        </ImageBackground>
      </View>
      {/* questions */}
      <View style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}>
        {/* questions__item-1 */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['TFA-BlockBG'],
              borderRadius: 12,
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: 12,
              paddingRight: 12,
            },
            dimensions.width
          )}
        >
          {/* btn-content */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('QuestionsP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: null,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_400Regular',
                  height: 20,
                  padding: 0,
                  paddingBottom: 0,
                  paddingTop: 0,
                }
              ),
              dimensions.width
            )}
            title={'How do I create my content?'}
          />
          {/* btn-icon */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('QuestionsP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            icon={'Entypo/chevron-thin-right'}
            iconSize={18}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: null,
                  color: palettes.App['Form Placeholder Text color'],
                  fontFamily: null,
                  padding: 0,
                }
              ),
              dimensions.width
            )}
            title={''}
          />
        </View>
        {/* questions__item-2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['TFA-BlockBG'],
              borderRadius: 12,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 10,
              paddingLeft: 12,
              paddingRight: 12,
            },
            dimensions.width
          )}
        >
          {/* btn-content */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('QuestionsP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: null,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_400Regular',
                  height: 20,
                  padding: 0,
                  paddingBottom: 0,
                  paddingTop: 0,
                }
              ),
              dimensions.width
            )}
            title={'How to withdraw money?'}
          />
          {/* btn-icon */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('QuestionsP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            icon={'Entypo/chevron-thin-right'}
            iconSize={18}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: null,
                  color: palettes.App['Form Placeholder Text color'],
                  fontFamily: null,
                  padding: 0,
                }
              ),
              dimensions.width
            )}
            title={''}
          />
        </View>
        {/* questions__item-3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['TFA-BlockBG'],
              borderRadius: 12,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 10,
              paddingLeft: 12,
              paddingRight: 12,
            },
            dimensions.width
          )}
        >
          {/* btn-content */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('QuestionsP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: null,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_400Regular',
                  height: 20,
                  padding: 0,
                  paddingBottom: 0,
                  paddingTop: 0,
                }
              ),
              dimensions.width
            )}
            title={'How to donate a user for the first time?'}
          />
          {/* btn-icon */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('QuestionsP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            icon={'Entypo/chevron-thin-right'}
            iconSize={18}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: null,
                  color: palettes.App['Form Placeholder Text color'],
                  fontFamily: null,
                  padding: 0,
                }
              ),
              dimensions.width
            )}
            title={''}
          />
        </View>
        {/* questions__item-4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['TFA-BlockBG'],
              borderRadius: 12,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 10,
              paddingLeft: 12,
              paddingRight: 12,
            },
            dimensions.width
          )}
        >
          {/* btn-content */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('QuestionsP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: null,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_400Regular',
                  height: 20,
                  padding: 0,
                  paddingBottom: 0,
                  paddingTop: 0,
                }
              ),
              dimensions.width
            )}
            title={'I donate a user for the first time?'}
          />
          {/* btn-icon */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('QuestionsP1InProgressScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            icon={'Entypo/chevron-thin-right'}
            iconSize={18}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: null,
                  color: palettes.App['Form Placeholder Text color'],
                  fontFamily: null,
                  padding: 0,
                }
              ),
              dimensions.width
            )}
            title={''}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(HelpCenterP1InProgressScreen);
