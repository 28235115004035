const extractErrorFromResponse = response => {
  if (response && response.message) {
    console.log('if chal gaya');
    console.log(response.message);
    let error = response.message;
    // Check if the error starts with "Missing param"
    if (error.startsWith('Missing param:')) {
      // Split the error message to isolate the parameter name
      const parts = error.split(': ');

      if (parts.length < 2) {
        // If the format is not as expected, return the original error
        return error;
      }

      const param = parts[1].trim();

      // Convert the parameter name to a proper case with spaces
      const formattedParam = param
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      return `${formattedParam} is Required`;
    } else {
      // Return the original error message if it's not a "Missing param" error
      return error;
    }
  } else return 'false';
};

export default extractErrorFromResponse;
