import React from 'react';
import { Icon, Touchable, useTheme } from '@draftbit/ui';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { systemWeights } from 'react-native-typography';
import LinkingConfiguration from './LinkingConfiguration';
import * as ThrillerXanoApi from './apis/ThrillerXanoApi.js';
import * as GlobalVariables from './config/GlobalVariableContext';
import askLocationPermission from './global-functions/askLocationPermission';
import extractErrorFromResponse from './global-functions/extractErrorFromResponse';
import generateDropdownOptions from './global-functions/generateDropdownOptions';
import getPlatformColumn from './global-functions/getPlatformColumn';
import AccountDeletionScreen from './screens/AccountDeletionScreen';
import AddProfilePhotoScreenP1InProgressScreen from './screens/AddProfilePhotoScreenP1InProgressScreen';
import AddProfilePhotoWithPhotoP1InProgressScreen from './screens/AddProfilePhotoWithPhotoP1InProgressScreen';
import AddRefillMethodsScreen from './screens/AddRefillMethodsScreen';
import BalanceDipositBonusScreen from './screens/BalanceDipositBonusScreen';
import BalanceEmptyP1InProgressScreen from './screens/BalanceEmptyP1InProgressScreen';
import BalanceEntryDetailsP1InProgressScreen from './screens/BalanceEntryDetailsP1InProgressScreen';
import BalanceEntryDetailsSeveralPlayerP1InProgressScreen from './screens/BalanceEntryDetailsSeveralPlayerP1InProgressScreen';
import BalanceMainP1InProgressScreen from './screens/BalanceMainP1InProgressScreen';
import BalanceWithdrawP1InProgressScreen from './screens/BalanceWithdrawP1InProgressScreen';
import BalanceWithdrawalDetailsP1InProgressScreen from './screens/BalanceWithdrawalDetailsP1InProgressScreen';
import BankTransferP1InProgressScreen from './screens/BankTransferP1InProgressScreen';
import CheckLocationServicesScreen from './screens/CheckLocationServicesScreen';
import ContactUsP1InProgressScreen from './screens/ContactUsP1InProgressScreen';
import ContactUsSuccessP1InProgressScreen from './screens/ContactUsSuccessP1InProgressScreen';
import CurrentEntryP1InProgressScreen from './screens/CurrentEntryP1InProgressScreen';
import CurrentEntryPlayerP1InProgressScreen from './screens/CurrentEntryPlayerP1InProgressScreen';
import EntriesLostP1InProgressScreen from './screens/EntriesLostP1InProgressScreen';
import EntriesOpenedP1InProgress2Screen from './screens/EntriesOpenedP1InProgress2Screen';
import EntriesPastP1InProgressScreen2 from './screens/EntriesPastP1InProgressScreen2';
import EntriesWonP1InProgressScreen from './screens/EntriesWonP1InProgressScreen';
import FeedMainP1InProgressScreen from './screens/FeedMainP1InProgressScreen';
import FeedRemovePlayerP1InProgressScreen from './screens/FeedRemovePlayerP1InProgressScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import HelpCenterP1InProgressScreen from './screens/HelpCenterP1InProgressScreen';
import HowToPlayP1InProgressScreen from './screens/HowToPlayP1InProgressScreen';
import LogInMainScreenP1InProgressScreen from './screens/LogInMainScreenP1InProgressScreen';
import MyProfileMainP1InProgressScreen from './screens/MyProfileMainP1InProgressScreen';
import NotAvailableScrenScreen from './screens/NotAvailableScrenScreen';
import Onboarding01P1InProgressScreen from './screens/Onboarding01P1InProgressScreen';
import Onboarding02P1InProgressScreen from './screens/Onboarding02P1InProgressScreen';
import Onboarding03P1InProgressScreen from './screens/Onboarding03P1InProgressScreen';
import OtpChangePasswordScreen from './screens/OtpChangePasswordScreen';
import ProfileChangePasswordP1InProgressScreen from './screens/ProfileChangePasswordP1InProgressScreen';
import ProfileEditProfileP1InProgressScreen from './screens/ProfileEditProfileP1InProgressScreen';
import ProfileEditProfilePhotoP1InProgressScreen from './screens/ProfileEditProfilePhotoP1InProgressScreen';
import ProfileShareScreen from './screens/ProfileShareScreen';
import PromoDepositBonusScreen from './screens/PromoDepositBonusScreen';
import PromoJoiningBonusScreen from './screens/PromoJoiningBonusScreen';
import PurchasesListScreen from './screens/PurchasesListScreen';
import QuestionsP1InProgressScreen from './screens/QuestionsP1InProgressScreen';
import RefillSuccessP1InProgressScreen from './screens/RefillSuccessP1InProgressScreen';
import SIgnUpMainScreenP1InProgressScreen from './screens/SIgnUpMainScreenP1InProgressScreen';
import SignUpLogInScreenP1InProgressScreen from './screens/SignUpLogInScreenP1InProgressScreen';
import StartVerificationScreen from './screens/StartVerificationScreen';
import TakeAPhoto1Screen from './screens/TakeAPhoto1Screen';
import TakeAPhoto2Screen from './screens/TakeAPhoto2Screen';
import TopUpP1InProgressP1InProgressScreen from './screens/TopUpP1InProgressP1InProgressScreen';
import TopUpRefillScreen from './screens/TopUpRefillScreen';
import VerificationSentScreen from './screens/VerificationSentScreen';
import VerifyBankAccountScreen from './screens/VerifyBankAccountScreen';
import VerifyYourAccountScreen from './screens/VerifyYourAccountScreen';
import WithdrawalRequestSuccessScreen from './screens/WithdrawalRequestSuccessScreen';
import WithdrawlsListScreen from './screens/WithdrawlsListScreen';
import palettes from './themes/palettes';
import Breakpoints from './utils/Breakpoints';
import getLocationUtil from './utils/getLocation';
import useWindowDimensions from './utils/useWindowDimensions';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function DefaultDrawerIcon({ tintColor, navigation }) {
  return (
    <Touchable
      onPress={() => navigation.toggleDrawer()}
      style={[styles.headerContainer, styles.headerContainerLeft]}
    >
      <Icon
        name="EvilIcons/navicon"
        size={27}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </Touchable>
  );
}

function BottomTabNavigator() {
  const theme = useTheme();

  const tabBarOrDrawerIcons = {
    MyProfileMainP1InProgressScreen: 'FontAwesome/user',
    FeedMainP1InProgressScreen: 'FontAwesome/users',
    EntriesOpenedP1InProgress2Screen: 'AntDesign/pluscircle',
  };

  return (
    <Tab.Navigator
      screenOptions={({ navigation }) => ({
        headerShown: false,
        tabBarActiveTintColor: palettes.App['TFA-White'],
        tabBarInactiveTintColor: palettes.App['#7F7685'],
        tabBarLabelStyle: { fontFamily: 'Poppins_500Medium', fontSize: 11 },
        tabBarStyle: {
          backgroundColor: palettes.App['nav background'],
          borderTopColor: 'transparent',
        },
      })}
    >
      <Tab.Screen
        name="MyProfileMainP1InProgressScreen"
        component={MyProfileMainP1InProgressScreen}
        options={({ navigation }) => ({
          headerTitle: 'Profile',
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="FontAwesome/user"
              size={25}
              color={
                focused ? palettes.App['TFA-White'] : palettes.App['#7F7685']
              }
            />
          ),
          tabBarLabel: 'Profile',
          title: 'My Profile Main - P1 - In Progress',
        })}
      />
      <Tab.Screen
        name="FeedMainP1InProgressScreen"
        component={FeedMainP1InProgressScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="FontAwesome/users"
              size={25}
              color={
                focused ? palettes.App['TFA-White'] : palettes.App['#7F7685']
              }
            />
          ),
          tabBarLabel: 'Slate',
          title: 'feed / main - P1 - In Progress',
        })}
      />
      <Tab.Screen
        name="EntriesOpenedP1InProgress2Screen"
        component={EntriesOpenedP1InProgress2Screen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="AntDesign/pluscircle"
              size={25}
              color={
                focused ? palettes.App['TFA-White'] : palettes.App['#7F7685']
              }
            />
          ),
          tabBarLabel: 'Entries',
          title: 'entries-opened - P1 - In Progress 2',
        })}
      />
    </Tab.Navigator>
  );
}

export default function RootAppNavigator() {
  const theme = useTheme();

  const Constants = GlobalVariables.useValues();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#0f0415ff',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="CheckLocationServicesScreen"
        screenOptions={({ navigation }) => ({
          animationEnabled: true,
          cardStyle: { flex: 1 },
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
          headerShown: false,
        })}
      >
        <Stack.Screen
          name="Onboarding01P1InProgressScreen"
          component={Onboarding01P1InProgressScreen}
          options={({ navigation }) => ({
            title: 'Onboarding 01 - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="Onboarding02P1InProgressScreen"
          component={Onboarding02P1InProgressScreen}
          options={({ navigation }) => ({
            title: 'Onboarding 02 - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="Onboarding03P1InProgressScreen"
          component={Onboarding03P1InProgressScreen}
          options={({ navigation }) => ({
            title: 'Onboarding 03 - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="SignUpLogInScreenP1InProgressScreen"
          component={SignUpLogInScreenP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'Sign Up/Log in Screen - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="LogInMainScreenP1InProgressScreen"
          component={LogInMainScreenP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'Log in Main Screen - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="SIgnUpMainScreenP1InProgressScreen"
          component={SIgnUpMainScreenP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'SIgn Up Main Screen - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="AddProfilePhotoScreenP1InProgressScreen"
          component={AddProfilePhotoScreenP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'Add Profile Photo Screen - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="AddProfilePhotoWithPhotoP1InProgressScreen"
          component={AddProfilePhotoWithPhotoP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'Add Profile Photo-with photo - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="ProfileEditProfileP1InProgressScreen"
          component={ProfileEditProfileP1InProgressScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'profile / edit-profile - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="ProfileEditProfilePhotoP1InProgressScreen"
          component={ProfileEditProfilePhotoP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'profile / edit-profile-photo - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="ProfileChangePasswordP1InProgressScreen"
          component={ProfileChangePasswordP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'profile / change-password - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="ContactUsP1InProgressScreen"
          component={ContactUsP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'contact-us - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="ContactUsSuccessP1InProgressScreen"
          component={ContactUsSuccessP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'contact-us / success - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="HelpCenterP1InProgressScreen"
          component={HelpCenterP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'help-center - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="QuestionsP1InProgressScreen"
          component={QuestionsP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'questions - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="VerifyYourAccountScreen"
          component={VerifyYourAccountScreen}
          options={({ navigation }) => ({
            title: 'verify-your-account',
          })}
        />
        <Stack.Screen
          name="TakeAPhoto1Screen"
          component={TakeAPhoto1Screen}
          options={({ navigation }) => ({
            title: 'take-a-photo-1',
          })}
        />
        <Stack.Screen
          name="TakeAPhoto2Screen"
          component={TakeAPhoto2Screen}
          options={({ navigation }) => ({
            title: 'take-a-photo-2',
          })}
        />
        <Stack.Screen
          name="VerificationSentScreen"
          component={VerificationSentScreen}
          options={({ navigation }) => ({
            title: 'verification / sent',
          })}
        />
        <Stack.Screen
          name="ProfileShareScreen"
          component={ProfileShareScreen}
          options={({ navigation }) => ({
            title: 'profile / share',
          })}
        />
        <Stack.Screen
          name="HowToPlayP1InProgressScreen"
          component={HowToPlayP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'how-to-play - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="PromoJoiningBonusScreen"
          component={PromoJoiningBonusScreen}
          options={({ navigation }) => ({
            title: 'promo / joining-bonus',
          })}
        />
        <Stack.Screen
          name="PromoDepositBonusScreen"
          component={PromoDepositBonusScreen}
          options={({ navigation }) => ({
            title: 'promo / deposit-bonus',
          })}
        />
        <Stack.Screen
          name="TopUpRefillScreen"
          component={TopUpRefillScreen}
          options={({ navigation }) => ({
            title: 'top-up / refill',
          })}
        />
        <Stack.Screen
          name="BalanceMainP1InProgressScreen"
          component={BalanceMainP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'balance / main - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="PurchasesListScreen"
          component={PurchasesListScreen}
          options={({ navigation }) => ({
            title: 'purchases / list',
          })}
        />
        <Stack.Screen
          name="BalanceEmptyP1InProgressScreen"
          component={BalanceEmptyP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'balance / empty - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="BalanceEntryDetailsSeveralPlayerP1InProgressScreen"
          component={BalanceEntryDetailsSeveralPlayerP1InProgressScreen}
          options={({ navigation }) => ({
            title:
              'balance / entry-details / several-player - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="BalanceEntryDetailsP1InProgressScreen"
          component={BalanceEntryDetailsP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'balance / entry-details - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="BalanceWithdrawalDetailsP1InProgressScreen"
          component={BalanceWithdrawalDetailsP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'balance / withdrawal-details - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="BalanceDipositBonusScreen"
          component={BalanceDipositBonusScreen}
          options={({ navigation }) => ({
            title: 'balance / diposit-bonus',
          })}
        />
        <Stack.Screen
          name="BalanceWithdrawP1InProgressScreen"
          component={BalanceWithdrawP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'balance / withdraw - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="TopUpP1InProgressP1InProgressScreen"
          component={TopUpP1InProgressP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'top-up - P1 - In Progress - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="BankTransferP1InProgressScreen"
          component={BankTransferP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'bank-transfer - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="RefillSuccessP1InProgressScreen"
          component={RefillSuccessP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'refill / success - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="FeedRemovePlayerP1InProgressScreen"
          component={FeedRemovePlayerP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'feed / remove-player - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="CurrentEntryP1InProgressScreen"
          component={CurrentEntryP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'current-entry - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="CurrentEntryPlayerP1InProgressScreen"
          component={CurrentEntryPlayerP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'current-entry / player - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="EntriesWonP1InProgressScreen"
          component={EntriesWonP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'entries / won - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="EntriesLostP1InProgressScreen"
          component={EntriesLostP1InProgressScreen}
          options={({ navigation }) => ({
            title: 'entries / lost - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="ForgotPasswordScreen"
          component={ForgotPasswordScreen}
          options={({ navigation }) => ({
            title: 'Forgot Password Screen',
          })}
        />
        <Stack.Screen
          name="OtpChangePasswordScreen"
          component={OtpChangePasswordScreen}
          options={({ navigation }) => ({
            title: 'otp / change-password',
          })}
        />
        <Stack.Screen
          name="NotAvailableScrenScreen"
          component={NotAvailableScrenScreen}
          options={({ navigation }) => ({
            title: 'Not Available Scren',
          })}
        />
        <Stack.Screen
          name="StartVerificationScreen"
          component={StartVerificationScreen}
          options={({ navigation }) => ({
            title: 'Start verification',
          })}
        />
        <Stack.Screen
          name="EntriesPastP1InProgressScreen2"
          component={EntriesPastP1InProgressScreen2}
          options={({ navigation }) => ({
            title: 'entries--past - P1 - In Progress',
          })}
        />
        <Stack.Screen
          name="WithdrawlsListScreen"
          component={WithdrawlsListScreen}
          options={({ navigation }) => ({
            title: 'withdrawls / list',
          })}
        />
        <Stack.Screen
          name="CheckLocationServicesScreen"
          component={CheckLocationServicesScreen}
          options={({ navigation }) => ({
            title: 'Check Location Services',
          })}
        />
        <Stack.Screen
          name="WithdrawalRequestSuccessScreen"
          component={WithdrawalRequestSuccessScreen}
          options={({ navigation }) => ({
            title: 'withdrawal / Request success ',
          })}
        />
        <Stack.Screen
          name="VerifyBankAccountScreen"
          component={VerifyBankAccountScreen}
          options={({ navigation }) => ({
            title: 'Verify Bank Account',
          })}
        />
        <Stack.Screen
          name="AddRefillMethodsScreen"
          component={AddRefillMethodsScreen}
          options={({ navigation }) => ({
            title: 'Add Refill Methods',
          })}
        />
        <Stack.Screen
          name="AccountDeletionScreen"
          component={AccountDeletionScreen}
          options={({ navigation }) => ({
            title: 'Account Deletion',
          })}
        />
        <Stack.Screen
          name="BottomTabNavigator"
          component={BottomTabNavigator}
          options={({ navigation }) => ({
            title: 'Bottom Tab Navigator',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
});
