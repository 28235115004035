import React from 'react';
import {
  Button,
  IconButton,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const ProfileChangePasswordP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [error_message, setError_message] = React.useState('');
  const [inputCurrentPassValue, setInputCurrentPassValue] = React.useState('');
  const [inputCurrentPassValue2, setInputCurrentPassValue2] =
    React.useState('');
  const [inputNewPassValue, setInputNewPassValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const thrillerXanoChangePasswordPOST =
    ThrillerXanoApi.useChangePasswordPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setError_message('');
      setInputCurrentPassValue('');
      setInputNewPassValue('');
      setInputCurrentPassValue2('');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      {/* top-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginLeft: 20, marginRight: 20, marginTop: 40 },
          dimensions.width
        )}
      >
        {/* nav-top */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 10,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* nav-top__left */}
          <View style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}>
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'MyProfileMainP1InProgressScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              color={palettes.App['Form Placeholder Text color']}
              icon={'Feather/arrow-left'}
            />
          </View>
          {/* nav-top__center */}
          <View>
            {/* Change password */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: '"rgb(255, 255, 255)"',
                    fontFamily: 'Roboto_700Bold',
                    fontSize: 24,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Change password'}
            </Text>
          </View>
          {/* nav-top__right */}
          <View
            style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
          />
        </View>
        {/* change-password-form */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 20, zIndex: -1 },
            dimensions.width
          )}
        >
          {/* change-password-form__item-1 */}
          <View>
            {/* Current password (updated 01.01.24) */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Roboto_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'Current password'}
            </Text>
            {/* change-password-form__input-wrap */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App['TFA-InputBG'],
                  borderRadius: 30,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
                dimensions.width
              )}
            >
              {/* input_current_pass */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newInputCurrentPassValue => {
                  try {
                    setInputCurrentPassValue(newInputCurrentPassValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={'Enter your current password'}
                placeholderTextColor={
                  palettes.App['Form Placeholder Text color']
                }
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    {
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      borderRadius: 30,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderWidth: 0,
                      color: palettes.App['TFA-White'],
                      fontFamily: 'Roboto_500Medium',
                      paddingBottom: 12,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 12,
                      width: '100%',
                    }
                  ),
                  dimensions.width
                )}
                value={inputCurrentPassValue}
              />
            </View>
          </View>
          {/* change-password-form__item-2 */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* New password */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Roboto_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'New password'}
            </Text>
            {/* change-password-form__input-wrap */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App['TFA-InputBG'],
                  borderRadius: 30,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
                dimensions.width
              )}
            >
              {/* input_new_pass */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newInputNewPassValue => {
                  try {
                    setInputNewPassValue(newInputNewPassValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={'Create new password'}
                placeholderTextColor={
                  palettes.App['Form Placeholder Text color']
                }
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    {
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      borderRadius: 30,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderWidth: 0,
                      color: palettes.App['TFA-White'],
                      fontFamily: 'Roboto_500Medium',
                      paddingBottom: 12,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 12,
                      width: '100%',
                    }
                  ),
                  dimensions.width
                )}
                value={inputNewPassValue}
              />
            </View>
          </View>
          {/* change-password-form__item-3 */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* Confirm new password */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Roboto_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'Confirm new password'}
            </Text>
            {/* change-password-form__input-wrap */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App['TFA-InputBG'],
                  borderRadius: 30,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
                dimensions.width
              )}
            >
              {/* input_current_pass */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newInputCurrentPassValue => {
                  try {
                    setInputCurrentPassValue2(newInputCurrentPassValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={'Confirm new password'}
                placeholderTextColor={
                  palettes.App['Form Placeholder Text color']
                }
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    {
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      borderRadius: 30,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderWidth: 0,
                      color: palettes.App['TFA-White'],
                      fontFamily: 'Roboto_500Medium',
                      paddingBottom: 12,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 12,
                      width: '100%',
                    }
                  ),
                  dimensions.width
                )}
                value={inputCurrentPassValue2}
              />
            </View>
          </View>

          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* error_messages */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['Custom Color_8'],
                    fontFamily: 'Roboto_400Regular',
                    fontSize: 18,
                    marginBottom: 10,
                    marginTop: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {error_message}
            </Text>
          </View>
        </View>
      </View>
      {/* bottom-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginBottom: 60, marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* bottom-button */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'column', justifyContent: 'space-between' },
            dimensions.width
          )}
        >
          {/* Save changes */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  if (
                    inputNewPassValue !== '' &&
                    inputCurrentPassValue2 !== ''
                  ) {
                    if (inputNewPassValue === inputCurrentPassValue2) {
                      const resp = (
                        await thrillerXanoChangePasswordPOST.mutateAsync({
                          old_password: inputCurrentPassValue,
                          password: inputNewPassValue,
                        })
                      )?.json;
                      if (extractErrorFromResponse(resp) === 'false') {
                        navigation.navigate('BottomTabNavigator', {
                          screen: 'MyProfileMainP1InProgressScreen',
                        });
                      } else {
                        setError_message(extractErrorFromResponse(resp));
                      }
                    } else {
                      setError_message(
                        'New Password and Confirm Password does not match'
                      );
                    }
                  } else {
                    setError_message('Password and New Password are required');
                  }
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['TFA-White'],
                  borderRadius: 30,
                  color: '"rgb(15, 4, 21)"',
                  fontFamily: 'Roboto_400Regular',
                  paddingBottom: 8,
                  paddingTop: 8,
                  width: '100%',
                }
              ),
              dimensions.width
            )}
            title={'Save changes'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ProfileChangePasswordP1InProgressScreen);
