import React from 'react';
import {
  Button,
  Icon,
  IconButton,
  LinearProgress,
  LoadingIndicator,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Platform,
  RefreshControl,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import completeDateTime from '../global-functions/completeDateTime';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const EntriesOpenedP1InProgress2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [entriesData, setEntriesData] = React.useState({});
  const [is_screen_active, setIs_screen_active] = React.useState(false);
  const [page, setPage] = React.useState(2);
  const [screen_active, setScreen_active] = React.useState(false);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [refreshing1xis3fhK, setRefreshing1xis3fhK] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setIs_screen_active(true);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasLeftSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['TFA-ScreenBG'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['TopRectangleProfileThrillerFantasy'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            { height: 170 }
          ),
          dimensions.width
        )}
      >
        {/* container */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: 30,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 30,
            },
            dimensions.width
          )}
        >
          {/* top-box */}
          <View>
            {/* nav-top */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  paddingBottom: 10,
                  paddingTop: 10,
                },
                dimensions.width
              )}
            >
              {/* nav-top__center */}
              <View>
                {/* Entries */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: '"rgb(255, 255, 255)"',
                        fontFamily: 'Roboto_700Bold',
                        fontSize: 24,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Entries'}
                </Text>
              </View>
            </View>
          </View>
          {/* entry-tab */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 10,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* open */}
            <Button
              accessible={true}
              iconPosition={'left'}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: palettes.App['Form Field BG'],
                    borderRadius: 35,
                    fontFamily: 'Roboto_500Medium',
                    paddingBottom: 11,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 11,
                  }
                ),
                dimensions.width
              )}
              title={'Open'}
            />
            {/* past */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  navigation.navigate('EntriesPastP1InProgressScreen2');
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: null,
                    borderRadius: 35,
                    color: palettes.App['TFA-StatsBlockText'],
                    fontFamily: 'Roboto_500Medium',
                    paddingBottom: 11,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 11,
                  }
                ),
                dimensions.width
              )}
              title={'Past'}
            />
          </View>

          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth(
              { marginBottom: 50, marginTop: 10 },
              dimensions.width
            )}
          >
            <ThrillerXanoApi.FetchGetOpenEntriesGET
              handlers={{
                onData: fetchData => {
                  try {
                    setIs_screen_active(false);
                    setEntriesData(fetchData?.items);
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
              page={1}
              refetchInterval={is_screen_active ? 0 : undefined}
            >
              {({ loading, error, data, refetchGetOpenEntries }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    <>
                      {!is_screen_active ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignSelf: 'center', marginTop: 40 },
                            dimensions.width
                          )}
                        >
                          <LoadingIndicator
                            color={theme.colors.branding.primary}
                            size={30}
                            type={'circleFade'}
                          />
                        </View>
                      )}
                    </>
                    {/* no-entries */}
                    <>
                      {!(fetchData?.itemsReceived < 1) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              height: 500,
                              justifyContent: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={palettes.App['#7F7685']}
                            name={'AntDesign/clockcircle'}
                            size={38}
                          />
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App['TFA-White'],
                                  fontFamily: 'Oswald_700Bold',
                                  fontSize: 29,
                                  lineHeight: 48,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'No entries yet'}
                          </Text>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['body-content']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['body-content']
                                  .style,
                                {
                                  fontFamily: 'Poppins_500Medium',
                                  lineHeight: 24,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Your open entries will appear here'}
                          </Text>
                          {/* btn-start-entry */}
                          <Button
                            accessible={true}
                            iconPosition={'left'}
                            onPress={() => {
                              try {
                                navigation.navigate('BottomTabNavigator', {
                                  screen: 'FeedMainP1InProgressScreen',
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            iconSize={20}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button']
                                  .style,
                                {
                                  backgroundColor: palettes.App['TFA-White'],
                                  borderRadius: 30,
                                  color: '"rgb(15, 4, 21)"',
                                  fontFamily: 'Poppins_500Medium',
                                  marginTop: 30,
                                  paddingBottom: 11,
                                  paddingLeft: 22,
                                  paddingRight: 22,
                                  paddingTop: 11,
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Start an entry'}
                          />
                        </View>
                      )}
                    </>
                    <>
                      {!(fetchData?.itemsReceived >= 1) ? null : (
                        <SimpleStyleFlatList
                          data={entriesData}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'1xis3fhK'}
                          nestedScrollEnabled={false}
                          onEndReached={() => {
                            const handler = async () => {
                              try {
                                if (fetchData?.nextPage === null) {
                                  return;
                                }
                                setPage(page + 1);
                                const newData = (
                                  await ThrillerXanoApi.getOpenEntriesGET(
                                    Constants,
                                    { page: page }
                                  )
                                )?.json;
                                console.log(newData?.curPage);
                                setEntriesData(
                                  entriesData.concat(newData?.items)
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          onEndReachedThreshold={0.5}
                          refreshControl={
                            <RefreshControl
                              refreshing={refreshing1xis3fhK}
                              onRefresh={() => {
                                try {
                                  setRefreshing1xis3fhK(true);
                                  setPage(1);
                                  setRefreshing1xis3fhK(false);
                                } catch (err) {
                                  console.error(err);
                                  setRefreshing1xis3fhK(false);
                                }
                              }}
                            />
                          }
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* Card */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor: 'rgb(4, 6, 19)',
                                      borderColor: palettes.App.White,
                                      borderRadius: 10,
                                      borderWidth: 0.1,
                                      marginBottom: 10,
                                      marginTop: 20,
                                      minWidth: {
                                        minWidth: Breakpoints.BigScreen,
                                        value:
                                          dimensions.width >=
                                          Breakpoints.Desktop
                                            ? 700
                                            : undefined,
                                      },
                                      padding: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Game Data */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: 'row',
                                        marginBottom: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Left */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { width: '50%' },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              color: palettes.App['TFA-White'],
                                              fontFamily: 'Roboto_400Regular',
                                              fontSize: 16,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          listData?._payout_chart
                                            ?.no_of_selection
                                        }
                                        {' Picks To Pay $'}
                                        {listData?.entry_fee *
                                          listData?._payout_chart?.multiplier}
                                        {'\n'}
                                      </Text>
                                      {/* Text 2 */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              color: palettes.App['TFA-White'],
                                              fontFamily: 'Roboto_400Regular',
                                              fontSize: 14,
                                              marginTop: 4,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Entry Fees $'}
                                        {listData?.entry_fee}
                                      </Text>
                                    </View>
                                    {/* Right */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'stretch',
                                          justifyContent: 'flex-start',
                                          width: '50%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              alignSelf: 'flex-end',
                                              color: palettes.App['TFA-White'],
                                              fontFamily: 'Roboto_700Bold',
                                              fontSize: 14,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'IN PROGRESS'}
                                      </Text>
                                      {/* Text 2 */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              alignSelf: 'flex-end',
                                              color: palettes.App['TFA-White'],
                                              fontFamily: 'Roboto_400Regular',
                                              fontSize: 10,
                                              marginTop: 5,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {completeDateTime(
                                          listData?.submitted_at
                                        )}
                                      </Text>
                                    </View>
                                  </View>
                                  <SimpleStyleFlatList
                                    data={listData?._lineup_selections}
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(listData, index) =>
                                      listData?.id ??
                                      listData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={JSON.stringify(
                                      listData?._lineup_selections
                                    )}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listData = item;
                                      return (
                                        <>
                                          {/* player-card */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginBottom: 20,
                                                marginTop: 15,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* card left */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  marginRight: 10,
                                                  width: '65%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginRight: 10 },
                                                  dimensions.width
                                                )}
                                              >
                                                <Image
                                                  resizeMode={'cover'}
                                                  {...GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'].props}
                                                  source={imageSource(
                                                    listData?._player_props
                                                      ?._player_data
                                                      ?.player_image?.url
                                                      ? listData?._player_props
                                                          ?._player_data
                                                          ?.player_image?.url
                                                      : Images[
                                                          'jerseyremovebgpreview'
                                                        ]
                                                  )}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.ImageStyles(
                                                        theme
                                                      )['Image'].style,
                                                      {
                                                        borderRadius: 50,
                                                        height: 48,
                                                        width: 48,
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                />
                                              </View>
                                              {/* View 2 */}
                                              <View>
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      flexDirection: 'row',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['poppins-16-bold-white']
                                                      .props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'poppins-16-bold-white'
                                                        ].style,
                                                        {
                                                          fontFamily:
                                                            'Roboto_700Bold',
                                                          fontSize: 14,
                                                          marginRight: 5,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      listData?._player_props
                                                        ?._player_data?.name
                                                    }
                                                  </Text>
                                                  {/* Text 2 */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['body-content'].props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['body-content'].style,
                                                        {
                                                          alignSelf: 'center',
                                                          color:
                                                            palettes.App[
                                                              'TFA-White'
                                                            ],
                                                          fontFamily:
                                                            'Roboto_400Regular',
                                                          fontSize: 10,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      listData?._player_props
                                                        ?.player_team?.alias
                                                    }
                                                    {' - '}
                                                    {
                                                      listData?._player_props
                                                        ?._player_data?.position
                                                    }
                                                  </Text>
                                                </View>
                                                {/* View 2 */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { marginTop: 2 },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['body-content'].props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['body-content'].style,
                                                        {
                                                          color:
                                                            palettes.App[
                                                              'TFA-White'
                                                            ],
                                                          fontFamily:
                                                            'Roboto_400Regular',
                                                          fontSize: 10,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {completeDateTime(
                                                      listData?._player_props
                                                        ?._seasons_schedule_nfl
                                                        ?.scheduled
                                                    )}
                                                    {' vs '}
                                                    {
                                                      listData?._player_props
                                                        ?.opponent_team?.alias
                                                    }
                                                  </Text>
                                                </View>
                                                {/* View 3 */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flexDirection: 'row',
                                                      marginTop: 5,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* View 2 */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        backgroundColor:
                                                          palettes.App[
                                                            'BG Gray'
                                                          ],
                                                        borderRadius: 1,
                                                        marginRight: 5,
                                                        padding: 2,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          {
                                                            color:
                                                              palettes.App[
                                                                'TFA-White'
                                                              ],
                                                            fontFamily:
                                                              'Roboto_400Regular',
                                                            fontSize: 10,
                                                            textTransform:
                                                              'uppercase',
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        listData?._player_props
                                                          ?._player_data
                                                          ?._leagues?.alias
                                                      }
                                                    </Text>
                                                  </View>

                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        backgroundColor:
                                                          palettes.App[
                                                            'BG Gray'
                                                          ],
                                                        borderRadius: 1,
                                                        marginRight: 5,
                                                        padding: 2,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          {
                                                            color:
                                                              palettes.App[
                                                                'TFA-White'
                                                              ],
                                                            fontFamily:
                                                              'Roboto_400Regular',
                                                            fontSize: 10,
                                                            textTransform:
                                                              'uppercase',
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {listData?._player_props
                                                        ?.status
                                                        ? listData
                                                            ?._player_props
                                                            ?.status
                                                        : 'Not Started'}
                                                    </Text>
                                                  </View>
                                                </View>
                                                {/* View 4 */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignContent:
                                                        'space-between',
                                                      flexDirection: 'row',
                                                      marginBottom: 5,
                                                      marginTop: 10,
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'].style,
                                                        {
                                                          alignSelf: 'center',
                                                          color:
                                                            palettes.App[
                                                              'TFA-White'
                                                            ],
                                                          fontFamily:
                                                            'Roboto_700Bold',
                                                          fontSize: 9,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {listData?._player_props
                                                      ?._players_stat?.total
                                                      ? listData?._player_props
                                                          ?._players_stat?.total
                                                      : 0}
                                                  </Text>
                                                  <LinearProgress
                                                    animationDuration={500}
                                                    indeterminate={false}
                                                    isAnimated={true}
                                                    trackColor={
                                                      theme.colors.border.brand
                                                    }
                                                    color={
                                                      listData?.color ??
                                                      palettes.App['#aaa7a3']
                                                    }
                                                    lineCap={'square'}
                                                    maximumValue={
                                                      listData?.locked_projection_point
                                                    }
                                                    minimumValue={0}
                                                    showTrack={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignSelf: 'center',
                                                        marginLeft: 3,
                                                        marginRight: 3,
                                                        maxWidth: 100,
                                                        width: '100%',
                                                      },
                                                      dimensions.width
                                                    )}
                                                    thickness={10}
                                                    trackLineCap={'square'}
                                                    value={
                                                      listData?._player_props
                                                        ?._players_stat?.total
                                                    }
                                                  />
                                                  {/* Text 2 */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'].style,
                                                        {
                                                          alignSelf: 'center',
                                                          color:
                                                            palettes.App[
                                                              'TFA-White'
                                                            ],
                                                          fontFamily:
                                                            'Roboto_700Bold',
                                                          fontSize: 9,
                                                        }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      listData?.locked_projection_point
                                                    }
                                                  </Text>
                                                </View>
                                                {/* View 5 */}
                                                <View />
                                              </View>
                                            </View>
                                            {/* card-right */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  backgroundColor:
                                                    'rgb(19, 20, 31)',
                                                  borderRadius: 10,
                                                  flexDirection: 'row',
                                                  justifyContent: 'center',
                                                  width: '32%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    borderColor:
                                                      palettes.App.White,
                                                    justifyContent: 'center',
                                                    padding: 10,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Text 3 */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].style,
                                                      {
                                                        alignSelf: 'center',
                                                        color:
                                                          palettes.App[
                                                            'TFA-White'
                                                          ],
                                                        fontFamily:
                                                          'Roboto_400Regular',
                                                        fontSize: 10,
                                                        textAlign: 'center',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    listData?._player_props
                                                      ?._market?.title
                                                  }
                                                </Text>

                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['poppins-14-regular-white']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'poppins-14-regular-white'
                                                      ].style,
                                                      {
                                                        alignSelf: 'center',
                                                        color: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value:
                                                              palettes.App[
                                                                'TFA-White'
                                                              ],
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value:
                                                              listData?.color
                                                                ? listData?.color
                                                                : 'white',
                                                          },
                                                        ],
                                                        fontFamily:
                                                          'Roboto_700Bold',
                                                        fontSize: 16,
                                                        marginBottom: 5,
                                                        marginRight: 0,
                                                        marginTop: 5,
                                                        textAlign: 'center',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    listData?.locked_projection_point
                                                  }
                                                </Text>
                                                {/* Text 2 */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['headling-19'].props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['headling-19'].style,
                                                      {
                                                        alignSelf: 'center',
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 12,
                                                        textAlign: 'center',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listData?.selection}
                                                </Text>
                                              </View>
                                            </View>
                                          </View>
                                        </>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                  />
                                </View>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          numColumns={1}
                          scrollEnabled={true}
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.BigScreen,
                                value: 'center',
                              },
                              alignSelf: {
                                minWidth: Breakpoints.BigScreen,
                                value: 'center',
                              },
                              justifyContent: {
                                minWidth: Breakpoints.BigScreen,
                                value: 'center',
                              },
                              marginBottom: [
                                { minWidth: Breakpoints.Mobile, value: 40 },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: Platform.OS === 'ios' ? 80 : undefined,
                                },
                              ],
                              marginTop: 20,
                              width: {
                                minWidth: Breakpoints.BigScreen,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </>
                );
              }}
            </ThrillerXanoApi.FetchGetOpenEntriesGET>
          </SimpleStyleScrollView>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(EntriesOpenedP1InProgress2Screen);
