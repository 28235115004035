import React from 'react';
import { Button, Icon, ScreenContainer, withTheme } from '@draftbit/ui';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const ProfileShareScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'flex-end',
          marginLeft: 20,
          marginRight: 20,
        },
        dimensions.width
      )}
    >
      {/* share-panel */}
      <View
        style={StyleSheet.applyWidth(
          { paddingBottom: 24, paddingTop: 24 },
          dimensions.width
        )}
      >
        {/* Share profile */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: theme.colors['TFA-White'],
              fontFamily: 'Oswald_700Bold',
              fontSize: 21,
            }),
            dimensions.width
          )}
        >
          {'Share profile'}
        </Text>
        {/* icon-panel */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 20,
            },
            dimensions.width
          )}
        >
          {/* icon-panel__item-1 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            {/* icon-panel__icon */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: '"rgb(46, 40, 50)"',
                  borderRadius: 34,
                  paddingBottom: 12,
                  paddingLeft: 18,
                  paddingRight: 18,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={palettes.App['TFA-White']}
                name={'MaterialCommunityIcons/link-variant'}
              />
            </View>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 11,
                    lineHeight: 22,
                  }
                ),
                dimensions.width
              )}
            >
              {'Copy link'}
            </Text>
          </View>
          {/* icon-panel__item-2 */}
          <View
            onLayout={event => {
              const handler = async () => {
                try {
                  await openShareUtil('https://app.thrillerfantasy.com/');
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            {/* icon-panel__icon */}
            <View
              onLayout={event => {
                const handler = async () => {
                  try {
                    await openShareUtil('https://app.thrillerfantasy.com/');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: '"rgb(46, 40, 50)"',
                  borderRadius: 34,
                  paddingBottom: 12,
                  paddingLeft: 18,
                  paddingRight: 18,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={palettes.App['TFA-White']}
                name={'Ionicons/logo-instagram'}
              />
            </View>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 11,
                    lineHeight: 22,
                  }
                ),
                dimensions.width
              )}
            >
              {'Instagram'}
            </Text>
          </View>
          {/* icon-panel__item-3 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            {/* icon-panel__icon */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: '"rgb(46, 40, 50)"',
                  borderRadius: 34,
                  paddingBottom: 12,
                  paddingLeft: 18,
                  paddingRight: 18,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={palettes.App['TFA-White']}
                name={'FontAwesome/facebook-f'}
              />
            </View>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 11,
                    lineHeight: 22,
                  }
                ),
                dimensions.width
              )}
            >
              {'Facebook'}
            </Text>
          </View>
          {/* icon-panel__item-4 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            {/* icon-panel__icon */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: '"rgb(46, 40, 50)"',
                  borderRadius: 34,
                  paddingBottom: 12,
                  paddingLeft: 18,
                  paddingRight: 18,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={palettes.App['TFA-White']}
                name={'MaterialCommunityIcons/discord'}
              />
            </View>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 11,
                    lineHeight: 22,
                  }
                ),
                dimensions.width
              )}
            >
              {'Discord'}
            </Text>
          </View>
          {/* icon-panel__item-5 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            {/* icon-panel__icon */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: '"rgb(46, 40, 50)"',
                  borderRadius: 34,
                  paddingBottom: 12,
                  paddingLeft: 18,
                  paddingRight: 18,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              <Icon
                size={24}
                color={palettes.App['TFA-White']}
                name={'FontAwesome/twitter'}
              />
            </View>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 11,
                    lineHeight: 22,
                  }
                ),
                dimensions.width
              )}
            >
              {'Twitter'}
            </Text>
          </View>
        </View>
        {/* button-wrap */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 30 }, dimensions.width)}
        >
          {/* Close */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('RootNavigator');
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: 'rgb(46, 40, 50)',
                  borderRadius: 34,
                  color: palettes.App['TFA-White'],
                  fontFamily: 'Poppins_500Medium',
                }
              ),
              dimensions.width
            )}
            title={'Close'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ProfileShareScreen);
