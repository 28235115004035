import React from 'react';
import {
  Button,
  IconButton,
  Picker,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import generateDropdownOptions from '../global-functions/generateDropdownOptions';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const BalanceWithdrawP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [error_message, setError_message] = React.useState('');
  const [inputCurrentPassValue, setInputCurrentPassValue] = React.useState('');
  const [inputCurrentPassValue2, setInputCurrentPassValue2] =
    React.useState('');
  const [inputNewPassValue, setInputNewPassValue] = React.useState('');
  const [max_withdraw_amount, setMax_withdraw_amount] = React.useState(0);
  const [multiSelectPickerValue, setMultiSelectPickerValue] = React.useState(
    []
  );
  const [payment_methods, setPayment_methods] = React.useState([
    { label: 'Paypal', value: 'paypal' },
  ]);
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const thrillerXanoCreatePayoutRequestPOST =
    ThrillerXanoApi.useCreatePayoutRequestPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setError_message('');
        setPickerValue('');
        const resp = (await ThrillerXanoApi.payoutDetailsGET(Constants))?.json;
        setPayment_methods(generateDropdownOptions(resp));
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      {/* top-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginLeft: 20, marginRight: 20, marginTop: 30 },
          dimensions.width
        )}
      >
        {/* nav-top */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 10,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* nav-top__left */}
          <View style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}>
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('BalanceMainP1InProgressScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              color={palettes.App['Form Placeholder Text color']}
              icon={'Feather/arrow-left'}
            />
          </View>
          {/* nav-top__center */}
          <View>
            {/* Withdraw */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: '"rgb(255, 255, 255)"',
                    fontFamily: 'Roboto_700Bold',
                    fontSize: 24,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Withdraw'}
            </Text>
          </View>
          {/* nav-top__right */}
          <View
            style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
          />
        </View>
        {/* withdraw-form */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 60, zIndex: -1 },
            dimensions.width
          )}
        >
          {/* withdraw-form__item-1 */}
          <View>
            {/* Amount of money */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'Amount to withdraw'}
            </Text>
            {/* amount */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App['TFA-InputBG'],
                  borderRadius: 20,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingRight: 10,
                },
                dimensions.width
              )}
            >
              {/* amount */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newAmountValue => {
                  try {
                    setInputCurrentPassValue(newAmountValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={'Minimum 100$'}
                placeholderTextColor={
                  palettes.App['Form Placeholder Text color']
                }
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    {
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      borderRadius: 30,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderWidth: 0,
                      color: palettes.App['TFA-White'],
                      fontFamily: 'Roboto_500Medium',
                      paddingBottom: 12,
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 12,
                      width: '80%',
                    }
                  ),
                  dimensions.width
                )}
                value={inputCurrentPassValue}
              />
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['TFA-White'],
                    borderRadius: 35,
                    paddingBottom: 5,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 5,
                  },
                  dimensions.width
                )}
              >
                {/* max */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['body-content'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['body-content'].style,
                      {
                        color: palettes.App.Thriller,
                        fontFamily: 'Roboto_400Regular',
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'$10000\nDaily'}
                </Text>
              </View>
            </View>
            {/* field-description */}
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', marginTop: 10 },
                dimensions.width
              )}
            >
              {/* Your Balance: */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App['TFA-White'],
                      fontFamily: 'Roboto_400Regular',
                      marginBottom: 10,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Your Balance: '}
              </Text>

              <ThrillerXanoApi.FetchBalanceDetailsWithdrawGET
                handlers={{
                  onData: fetchData => {
                    try {
                      setMax_withdraw_amount(fetchData?.amount);
                    } catch (err) {
                      console.error(err);
                    }
                  },
                }}
              >
                {({ loading, error, data, refetchBalanceDetailsWithdraw }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* $5,384.00 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['TFA-White'],
                              fontFamily: 'Roboto_500Medium',
                              marginBottom: 10,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'$ ' + fetchData?.amount}
                      </Text>
                    </>
                  );
                }}
              </ThrillerXanoApi.FetchBalanceDetailsWithdrawGET>
            </View>
          </View>
          {/* withdraw-form__item-2 */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* Card */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_500Medium',
                    marginBottom: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {'Select Payment Method'}
            </Text>
            {/* select-card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'stretch',
                  backgroundColor: palettes.App['TFA-InputBG'],
                  borderRadius: 5,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                },
                dimensions.width
              )}
            >
              <Picker
                autoDismissKeyboard={true}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                iconSize={24}
                leftIconMode={'inset'}
                onValueChange={newPickerValue => {
                  try {
                    setPickerValue(newPickerValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                dropDownBackgroundColor={StyleSheet.getWidthValue(
                  [
                    {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App['Custom Color 2'],
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App['Custom Color 2'],
                    },
                  ],
                  dimensions.width
                )}
                dropDownBorderColor={StyleSheet.getWidthValue(
                  [
                    {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App['Custom Color 2'],
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App['Custom Color 2'],
                    },
                  ],
                  dimensions.width
                )}
                dropDownTextColor={palettes.App['TFA-White']}
                iconSize={StyleSheet.getWidthValue(
                  [{ minWidth: Breakpoints.Laptop, value: 18 }],
                  dimensions.width
                )}
                mode={'dropdown-modal'}
                options={payment_methods}
                placeholder={'Select Payment Method'}
                placeholderTextColor={palettes.App['TFA-White']}
                rightIconName={'AntDesign/caretdown'}
                selectedIconColor={palettes.App['TFA-White']}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_400Regular',
                    fontSize: 14,
                  },
                  dimensions.width
                )}
                value={pickerValue}
              />
            </View>

            <View
              style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
            >
              {/* Error Text */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Tablet,
                          value: palettes.App['Custom Color_8'],
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App['Custom Color_8'],
                        },
                      ],
                      fontFamily: 'Roboto_400Regular',
                      fontSize: 18,
                      marginBottom: 10,
                    }
                  ),
                  dimensions.width
                )}
              >
                {error_message}
              </Text>
            </View>

            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('AddRefillMethodsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App.Studily_Blue_Night,
                      fontFamily: 'Roboto_400Regular',
                      fontSize: 16,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Manage Payment/Withdrawl Methods'}
              </Text>
            </Touchable>
          </View>
        </View>
      </View>
      {/* bottom-box */}
      <View
        style={StyleSheet.applyWidth(
          { marginBottom: 60, marginLeft: 20, marginRight: 20 },
          dimensions.width
        )}
      >
        {/* bottom-button */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', justifyContent: 'space-between' },
            dimensions.width
          )}
        >
          {/* Withdraw */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  const resp = (
                    await thrillerXanoCreatePayoutRequestPOST.mutateAsync({
                      amount: inputCurrentPassValue,
                      payment_method: pickerValue,
                    })
                  )?.json;
                  if (extractErrorFromResponse(resp) === 'false') {
                    navigation.navigate('WithdrawalRequestSuccessScreen', {
                      withdraw_id: resp?.withdrawal?.id,
                    });
                  } else {
                    setError_message(extractErrorFromResponse(resp));
                  }
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button'].style,
                {
                  backgroundColor: palettes.App['TFA-White'],
                  borderRadius: 30,
                  color: '"rgb(15, 4, 21)"',
                  fontFamily: 'Roboto_500Medium',
                  paddingBottom: 8,
                  paddingTop: 8,
                  width: '100%',
                }
              ),
              dimensions.width
            )}
            title={'Withdraw'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(BalanceWithdrawP1InProgressScreen);
