import React from 'react';
import {
  Button,
  Divider,
  IconButton,
  LottieAnimation,
  Picker,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as DwollaXanoCallsApi from '../apis/DwollaXanoCallsApi.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import Animations from '../config/Animations';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import generateBankJson from '../global-functions/generateBankJson';
import generateJsonForData from '../global-functions/generateJsonForData';
import getPlatformColumn from '../global-functions/getPlatformColumn';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';
import waitUtil from '../utils/wait';

const defaultProps = { json: null };

const AddRefillMethodsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [AccountNo, setAccountNo] = React.useState('');
  const [Address, setAddress] = React.useState('');
  const [FirstName, setFirstName] = React.useState('');
  const [LastName, setLastName] = React.useState('');
  const [PayoneerDetail, setPayoneerDetail] = React.useState('');
  const [PaypalDetail, setPaypalDetail] = React.useState('');
  const [Routing, setRouting] = React.useState('');
  const [VenmoDetail, setVenmoDetail] = React.useState('');
  const [account_type, setAccount_type] = React.useState([
    { label: 'Checking', value: 'Checking' },
    { label: 'Savings', value: 'Savings' },
  ]);
  const [ach_verification_method, setAch_verification_method] =
    React.useState('');
  const [bank_details, setBank_details] = React.useState({});
  const [bank_error, setBank_error] = React.useState('');
  const [error_message, setError_message] = React.useState('');
  const [inputCurrentPassValue, setInputCurrentPassValue] = React.useState('');
  const [inputCurrentPassValue2, setInputCurrentPassValue2] =
    React.useState('');
  const [inputNewPassValue, setInputNewPassValue] = React.useState('');
  const [max_withdraw_amount, setMax_withdraw_amount] = React.useState(0);
  const [multiSelectPickerValue, setMultiSelectPickerValue] = React.useState(
    []
  );
  const [open_banking_error, setOpen_banking_error] = React.useState('');
  const [payment_methods, setPayment_methods] = React.useState([
    { label: 'ACH BANK', value: 'ach' },
  ]);
  const [payoneer_error, setPayoneer_error] = React.useState('');
  const [paypal_error, setPaypal_error] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [pickerValue3, setPickerValue3] = React.useState('');
  const [pickerValue4, setPickerValue4] = React.useState('');
  const [show_success, setShow_success] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [venmo_error, setVenmo_error] = React.useState('');
  const thrillerXanoPayoutsInsertPOST = ThrillerXanoApi.usePayoutsInsertPOST();
  const dwollaXanoCallsIntiateOpenBankingPOST =
    DwollaXanoCallsApi.useIntiateOpenBankingPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const resp = (await ThrillerXanoApi.payoutDetailsGET(Constants))?.json;
        /* 'If/Else' action requires configuration: select If Condition */
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      <SimpleStyleScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
      >
        {/* top-box */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginRight: 20, marginTop: 30 },
            dimensions.width
          )}
        >
          {/* nav-top */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 10,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            {/* nav-top__left */}
            <View
              style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
            >
              <IconButton
                onPress={() => {
                  try {
                    navigation.navigate('BalanceMainP1InProgressScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                color={palettes.App['Form Placeholder Text color']}
                icon={'Feather/arrow-left'}
              />
            </View>
            {/* nav-top__center */}
            <View>
              {/* Withdraw */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: '"rgb(255, 255, 255)"',
                      fontFamily: 'Roboto_700Bold',
                      fontSize: 24,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Payment Methods'}
              </Text>
            </View>
            {/* nav-top__right */}
            <View
              style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
            />
          </View>
          {/* withdraw-form */}
          <View
            style={StyleSheet.applyWidth(
              { marginTop: 20, zIndex: -1 },
              dimensions.width
            )}
          >
            {/* withdraw-form__item-2 */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
            >
              {/* Card */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App['TFA-StatsBlockText'],
                      fontFamily: 'Roboto_500Medium',
                      fontSize: 16,
                      marginBottom: 10,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Select Payment Method to add Details'}
              </Text>
              {/* select-card */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'stretch',
                    backgroundColor: palettes.App['TFA-InputBG'],
                    borderRadius: 5,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  },
                  dimensions.width
                )}
              >
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background.brand}
                  dropDownBorderColor={theme.colors.border.brand}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      setPickerValue(newPickerValue);
                      if (newPickerValue === 'ach') {
                        setAccountNo('');
                        setRouting('');
                      } else {
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  dropDownTextColor={palettes.App['TFA-White']}
                  options={payment_methods}
                  placeholder={'Select Payment Method'}
                  placeholderTextColor={palettes.App['TFA-White']}
                  rightIconName={'AntDesign/caretdown'}
                  selectedIconColor={palettes.App['TFA-White']}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['TFA-White'],
                      fontFamily: 'Roboto_700Bold',
                      fontSize: 14,
                    },
                    dimensions.width
                  )}
                  value={pickerValue}
                />
              </View>
            </View>
            {/* Payoneer View */}
            <>
              {!(pickerValue === 'payoneer') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 20 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { color: palettes.App['TFA-White'] }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Enter Payoneer Account Email'}
                  </Text>
                  {/* amount */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['TFA-InputBG'],
                        borderRadius: 30,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 20,
                        paddingRight: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* input_current_pass */}
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newInputCurrentPassValue => {
                        try {
                          setPayoneerDetail(newInputCurrentPassValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Text Input']
                        .props}
                      placeholder={'Enter Email'}
                      placeholderTextColor={
                        palettes.App['Form Placeholder Text color']
                      }
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Text Input']
                            .style,
                          {
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            borderRadius: 30,
                            borderRightWidth: 0,
                            borderTopWidth: 0,
                            borderWidth: 0,
                            color: palettes.App['TFA-White'],
                            fontFamily: 'Poppins_500Medium',
                            paddingBottom: 12,
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 12,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                      value={PayoneerDetail}
                    />
                  </View>
                  {/* bottom-button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginTop: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['Custom Color_8'],
                            fontFamily: 'System',
                            fontWeight: '600',
                            marginBottom: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {payoneer_error}
                    </Text>
                    {/* Withdraw */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            if (PayoneerDetail !== '') {
                              const resp = (
                                await thrillerXanoPayoutsInsertPOST.mutateAsync(
                                  {
                                    details:
                                      generateJsonForData(PayoneerDetail),
                                    platform: 'payoneer',
                                  }
                                )
                              )?.json;
                              if (extractErrorFromResponse(resp) === 'false') {
                                setPayoneer_error('');
                                setShow_success(true);
                                await waitUtil({ milliseconds: 1000 });
                                setShow_success(false);
                                setPickerValue('');
                              } else {
                                setPayoneer_error(
                                  extractErrorFromResponse(resp)
                                );
                              }
                            } else {
                              setPayoneer_error(
                                'Payoneer Account Email is required'
                              );
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: palettes.App['TFA-White'],
                            borderRadius: 30,
                            color: '"rgb(15, 4, 21)"',
                            fontFamily: 'Poppins_500Medium',
                            paddingBottom: 8,
                            paddingTop: 8,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'SAVE'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Paypal View */}
            <>
              {!(pickerValue === 'paypal') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 20 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { color: palettes.App['TFA-White'] }
                      ),
                      dimensions.width
                    )}
                  >
                    {'PayPal username, email address, or mobile number'}
                  </Text>
                  {/* amount */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['TFA-InputBG'],
                        borderRadius: 30,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 20,
                        paddingRight: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* input_current_pass */}
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newInputCurrentPassValue => {
                        try {
                          setPaypalDetail(newInputCurrentPassValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Text Input']
                        .props}
                      placeholder={
                        'PayPal username, email address, or mobile number'
                      }
                      placeholderTextColor={
                        palettes.App['Form Placeholder Text color']
                      }
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Text Input']
                            .style,
                          {
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            borderRadius: 30,
                            borderRightWidth: 0,
                            borderTopWidth: 0,
                            borderWidth: 0,
                            color: palettes.App['TFA-White'],
                            fontFamily: 'Poppins_500Medium',
                            paddingBottom: 12,
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 12,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                      value={PaypalDetail}
                    />
                  </View>
                  {/* bottom-button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginTop: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['Custom Color_8'],
                            fontFamily: 'System',
                            fontWeight: '600',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {paypal_error}
                    </Text>
                    {/* Withdraw */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            if (PaypalDetail === '') {
                              setPaypal_error('Paypal Details are required');
                            } else {
                              const resp = (
                                await thrillerXanoPayoutsInsertPOST.mutateAsync(
                                  {
                                    details: generateJsonForData(PaypalDetail),
                                    platform: 'paypal',
                                  }
                                )
                              )?.json;
                              if (extractErrorFromResponse(resp) === 'false') {
                                setPaypal_error('');
                                setShow_success(true);
                                await waitUtil({ milliseconds: 1000 });
                                setShow_success(false);
                                setPickerValue('');
                              } else {
                                setPaypal_error(extractErrorFromResponse(resp));
                              }
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: palettes.App['TFA-White'],
                            borderRadius: 30,
                            color: '"rgb(15, 4, 21)"',
                            fontFamily: 'Poppins_500Medium',
                            paddingBottom: 8,
                            paddingTop: 8,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'SAVE'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Venmo View */}
            <>
              {!(pickerValue === 'venmo') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 20 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        { color: palettes.App['TFA-White'] }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Venmo username, email address, or mobile number'}
                  </Text>
                  {/* amount */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['TFA-InputBG'],
                        borderRadius: 30,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 20,
                        paddingRight: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* input_current_pass */}
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newInputCurrentPassValue => {
                        try {
                          setVenmoDetail(newInputCurrentPassValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Text Input']
                        .props}
                      placeholder={
                        'Venmo username, email address, or mobile number'
                      }
                      placeholderTextColor={
                        palettes.App['Form Placeholder Text color']
                      }
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Text Input']
                            .style,
                          {
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            borderRadius: 30,
                            borderRightWidth: 0,
                            borderTopWidth: 0,
                            borderWidth: 0,
                            color: palettes.App['TFA-White'],
                            fontFamily: 'Poppins_500Medium',
                            paddingBottom: 12,
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 12,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                      value={VenmoDetail}
                    />
                  </View>
                  {/* bottom-button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginTop: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['Custom Color_8'],
                            fontFamily: 'System',
                            fontWeight: '600',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {venmo_error}
                    </Text>
                    {/* Withdraw */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            if (VenmoDetail !== '') {
                              const resp = (
                                await thrillerXanoPayoutsInsertPOST.mutateAsync(
                                  {
                                    details: generateJsonForData(VenmoDetail),
                                    platform: 'venmo',
                                  }
                                )
                              )?.json;
                              if (extractErrorFromResponse(resp) === 'false') {
                                setVenmo_error('');
                                setShow_success(true);
                                await waitUtil({ milliseconds: 1000 });
                                setShow_success(false);
                                setPickerValue('');
                              } else {
                                setVenmo_error(extractErrorFromResponse(resp));
                              }
                            } else {
                              setVenmo_error('Venmo Detail is required');
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: palettes.App['TFA-White'],
                            borderRadius: 30,
                            color: '"rgb(15, 4, 21)"',
                            fontFamily: 'Poppins_500Medium',
                            paddingBottom: 8,
                            paddingTop: 8,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'SAVE'}
                    />
                  </View>
                </View>
              )}
            </>
            {/* ACH View */}
            <>
              {!(pickerValue === 'ach') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 20 },
                    dimensions.width
                  )}
                >
                  {/* method view */}
                  <View>
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App['TFA-White'],
                            fontFamily: 'Roboto_400Regular',
                            fontSize: 18,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Select Method To Add Your Bank Account. '}
                    </Text>
                    {/* manual */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setAch_verification_method('manual');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'rgba(0, 0, 0, 0)',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value:
                                  ach_verification_method === 'manual'
                                    ? palettes.App['Thriller Pink']
                                    : undefined,
                              },
                            ],
                            borderColor: palettes.App['Thriller Pink'],
                            borderWidth: 1,
                            fontFamily: 'Roboto_400Regular',
                            marginTop: 20,
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Using Micro-Deposit (1-2 Day For Verification)'}
                    />
                    {/* open banking */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setAch_verification_method('auto');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: 'rgba(0, 0, 0, 0)',
                              },
                              {
                                minWidth: Breakpoints.Mobile,
                                value:
                                  ach_verification_method === 'auto'
                                    ? palettes.App['Thriller Pink']
                                    : undefined,
                              },
                            ],
                            borderColor: palettes.App['Thriller Pink'],
                            borderWidth: 1,
                            fontFamily: 'Roboto_400Regular',
                            marginTop: 10,
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Open Banking (Instant Verification)'}
                    />
                  </View>
                  {/* manual ach add */}
                  <>
                    {!(ach_verification_method === 'manual') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingLeft: 30, paddingRight: 30 },
                          dimensions.width
                        )}
                      >
                        {/* Account */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: palettes.App['TFA-InputBG'],
                              borderRadius: 30,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginTop: 20,
                              paddingRight: 10,
                            },
                            dimensions.width
                          )}
                        >
                          {/* input_current_pass */}
                          <TextInput
                            autoCapitalize={'none'}
                            autoCorrect={true}
                            changeTextDelay={500}
                            onChangeText={newInputCurrentPassValue => {
                              try {
                                setAccountNo(newInputCurrentPassValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            webShowOutline={true}
                            {...GlobalStyles.TextInputStyles(theme)[
                              'Text Input'
                            ].props}
                            placeholder={'Account no'}
                            placeholderTextColor={
                              palettes.App['Form Placeholder Text color']
                            }
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ].style,
                                {
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  borderRadius: 30,
                                  borderRightWidth: 0,
                                  borderTopWidth: 0,
                                  borderWidth: 0,
                                  color: palettes.App['TFA-White'],
                                  fontFamily: 'Roboto_500Medium',
                                  paddingBottom: 12,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  paddingTop: 12,
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                            value={AccountNo}
                          />
                        </View>
                        {/* Routing */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: palettes.App['TFA-InputBG'],
                              borderRadius: 30,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginTop: 20,
                              paddingRight: 10,
                            },
                            dimensions.width
                          )}
                        >
                          {/* input_current_pass */}
                          <TextInput
                            autoCapitalize={'none'}
                            autoCorrect={true}
                            changeTextDelay={500}
                            onChangeText={newInputCurrentPassValue => {
                              try {
                                setRouting(newInputCurrentPassValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            webShowOutline={true}
                            {...GlobalStyles.TextInputStyles(theme)[
                              'Text Input'
                            ].props}
                            placeholder={'Routing'}
                            placeholderTextColor={
                              palettes.App['Form Placeholder Text color']
                            }
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ].style,
                                {
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  borderRadius: 30,
                                  borderRightWidth: 0,
                                  borderTopWidth: 0,
                                  borderWidth: 0,
                                  color: palettes.App['TFA-White'],
                                  fontFamily: 'Roboto_500Medium',
                                  paddingBottom: 12,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  paddingTop: 12,
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                            value={Routing}
                          />
                        </View>
                        {/* Account Type */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: palettes.App['TFA-InputBG'],
                              borderRadius: 30,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginTop: 20,
                              paddingRight: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <Picker
                            autoDismissKeyboard={true}
                            dropDownBackgroundColor={
                              theme.colors.background.brand
                            }
                            dropDownBorderColor={theme.colors.border.brand}
                            dropDownBorderRadius={8}
                            iconSize={24}
                            leftIconMode={'inset'}
                            mode={'native'}
                            onValueChange={newPickerValue => {
                              const pickerValue = newPickerValue;
                              try {
                                setPickerValue3(newPickerValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'Select an option'}
                            selectedIconName={'Feather/check'}
                            selectedIconSize={20}
                            type={'solid'}
                            dropDownBorderWidth={0}
                            dropDownTextColor={palettes.App['TFA-White']}
                            label={'Account Type'}
                            options={account_type}
                            selectedIconColor={palettes.App['TFA-White']}
                            style={StyleSheet.applyWidth(
                              {
                                borderWidth: 0,
                                color: palettes.App['TFA-White'],
                                fontFamily: 'Roboto_400Regular',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                            value={pickerValue3}
                          />
                        </View>
                        {/* bottom-button */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              marginTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App['Custom Color_8'],
                                  fontFamily: 'Roboto_400Regular',
                                  marginBottom: 40,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {bank_error}
                          </Text>
                          {/* Save Details */}
                          <Button
                            accessible={true}
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const resp = (
                                    await thrillerXanoPayoutsInsertPOST.mutateAsync(
                                      {
                                        details: generateBankJson(
                                          AccountNo,
                                          Routing,
                                          pickerValue3
                                        ),
                                        platform: 'ach',
                                      }
                                    )
                                  )?.json;
                                  if (
                                    extractErrorFromResponse(resp) === 'false'
                                  ) {
                                    setBank_error('');
                                    setShow_success(true);
                                    await waitUtil({ milliseconds: 1000 });
                                    setShow_success(false);
                                    setPickerValue('');
                                  } else {
                                    setBank_error(
                                      extractErrorFromResponse(resp)
                                    );
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button']
                                  .style,
                                {
                                  backgroundColor: palettes.App['TFA-White'],
                                  borderRadius: 30,
                                  color: '"rgb(15, 4, 21)"',
                                  fontFamily: 'Poppins_500Medium',
                                  paddingBottom: 8,
                                  paddingTop: 8,
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'SAVE'}
                          />
                        </View>
                      </View>
                    )}
                  </>
                  {/* open banking */}
                  <>
                    {!(ach_verification_method === 'auto') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 30, paddingLeft: 30, paddingRight: 30 },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App['TFA-White'],
                                fontFamily: 'Roboto_400Regular',
                                fontSize: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            'After Clicking on verification, you will be redirected to third party website to validate your online banking creds\n'
                          }
                        </Text>
                        {/* Account Type */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: palettes.App['TFA-InputBG'],
                              borderRadius: 30,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginTop: 20,
                              paddingRight: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <Picker
                            autoDismissKeyboard={true}
                            dropDownBackgroundColor={
                              theme.colors.background.brand
                            }
                            dropDownBorderColor={theme.colors.border.brand}
                            dropDownBorderRadius={8}
                            iconSize={24}
                            leftIconMode={'inset'}
                            mode={'native'}
                            onValueChange={newPickerValue => {
                              try {
                                setPickerValue4(newPickerValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            placeholder={'Select an option'}
                            selectedIconName={'Feather/check'}
                            selectedIconSize={20}
                            type={'solid'}
                            dropDownBorderWidth={0}
                            dropDownTextColor={palettes.App['TFA-White']}
                            label={'Account Type'}
                            options={account_type}
                            selectedIconColor={palettes.App['TFA-White']}
                            style={StyleSheet.applyWidth(
                              {
                                borderWidth: 0,
                                color: palettes.App['TFA-White'],
                                fontFamily: 'Roboto_400Regular',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                            value={pickerValue4}
                          />
                        </View>
                        <>
                          {!(open_banking_error !== '') ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 10 },
                                dimensions.width
                              )}
                            >
                              {/* error message */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App['Custom Color_8'],
                                      fontFamily: 'Roboto_400Regular',
                                      marginBottom: 40,
                                      textAlign: 'center',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {open_banking_error}
                              </Text>
                            </View>
                          )}
                        </>
                        {/* Start  Verification */}
                        <Button
                          accessible={true}
                          iconPosition={'left'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const resp = (
                                  await dwollaXanoCallsIntiateOpenBankingPOST.mutateAsync(
                                    { account_type: pickerValue4 }
                                  )
                                )?.json;
                                if (
                                  extractErrorFromResponse(resp) === 'false'
                                ) {
                                  setOpen_banking_error('');
                                  setAch_verification_method('');
                                  await WebBrowser.openBrowserAsync(`${resp}`);
                                } else {
                                  setOpen_banking_error(
                                    extractErrorFromResponse(resp)
                                  );
                                }
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: palettes.App['TFA-White'],
                                color: palettes.App['Custom Color 2'],
                                fontFamily: 'Roboto_400Regular',
                                fontSize: 18,
                                letterSpacing: 1,
                                marginTop: 10,
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Start Verification'}
                        />
                      </View>
                    )}
                  </>
                </View>
              )}
            </>
          </View>

          <View
            style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
          >
            <>
              {!(show_success === true) ? null : (
                <LottieAnimation
                  autoPlay={true}
                  loop={true}
                  speed={1}
                  {...GlobalStyles.LottieAnimationStyles(theme)[
                    'Lottie Animation'
                  ].props}
                  source={imageSource(Animations['animation1726787253141'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LottieAnimationStyles(theme)[
                        'Lottie Animation'
                      ].style,
                      { height: 300, width: '100%' }
                    ),
                    dimensions.width
                  )}
                />
              )}
            </>
            <Divider
              color={theme.colors.border.brand}
              {...GlobalStyles.DividerStyles(theme)['Divider'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.DividerStyles(theme)['Divider'].style,
                  { marginBottom: 20, marginTop: 20 }
                ),
                dimensions.width
              )}
            />
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_700Bold',
                    fontSize: 18,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Your Payment Methods'}
            </Text>
            {/* Divider 2 */}
            <Divider
              color={theme.colors.border.brand}
              {...GlobalStyles.DividerStyles(theme)['Divider'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.DividerStyles(theme)['Divider'].style,
                  { marginBottom: 20, marginTop: 20 }
                ),
                dimensions.width
              )}
            />
            <SimpleStyleScrollView
              bounces={true}
              horizontal={false}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
              keyboardShouldPersistTaps={'always'}
              nestedScrollEnabled={true}
              pagingEnabled={true}
              scrollEnabled={true}
            >
              <ThrillerXanoApi.FetchPayoutDetailsGET>
                {({ loading, error, data, refetchPayoutDetails }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <SimpleStyleFlatList
                      data={fetchData}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'4ThGLRIH'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            <>
                              {!(listData?.platform_name === 'ach') ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'row',
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 'row',
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    {...GlobalStyles.ImageStyles(theme)['Image']
                                      .props}
                                    resizeMode={'contain'}
                                    source={imageSource(
                                      listData?.platform_name === 'paypal'
                                        ? Images['paypallogo']
                                        : listData?.platform_name === 'payoneer'
                                        ? Images[
                                            'payoneerstackedlogoonwhitergb'
                                          ]
                                        : listData?.platform_name === 'venmo'
                                        ? Images['pngwingcom']
                                        : Images['navidorpaymentsolutionsach']
                                    )}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)['Image']
                                          .style,
                                        { height: 120 }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-start',
                                        justifyContent: 'center',
                                        marginLeft: [
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 20,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 20,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Bank Name */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color: palettes.App['TFA-White'],
                                            fontFamily: 'Roboto_700Bold',
                                            fontSize: 16,
                                            marginBottom: 5,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.payout_details?.bankName}
                                    </Text>
                                    {/* type */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color: palettes.App['TFA-White'],
                                            fontFamily: 'Roboto_400Regular',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.payout_details?.account_type}
                                    </Text>

                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignContent: 'center',
                                          backgroundColor:
                                            palettes.App['Custom Color_9'],
                                          borderRadius: 10,
                                          marginTop: 5,
                                          paddingBottom: 5,
                                          paddingLeft: 5,
                                          paddingRight: 5,
                                          paddingTop: 5,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Text 2 */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              color:
                                                palettes.App['Custom Color 2'],
                                              fontFamily: 'Roboto_400Regular',
                                              fontSize: 11,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.status_text}
                                      </Text>
                                    </View>
                                    <>
                                      {!(
                                        listData?.status === 'completed'
                                      ) ? null : (
                                        <Button
                                          accessible={true}
                                          iconPosition={'left'}
                                          onPress={() => {
                                            try {
                                              if (
                                                listData?.status === 'completed'
                                              ) {
                                                navigation.navigate(
                                                  'VerifyBankAccountScreen',
                                                  {
                                                    funding_id:
                                                      listData?.resource_id,
                                                  }
                                                );
                                              } else {
                                              }
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          {...GlobalStyles.ButtonStyles(theme)[
                                            'Button'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ButtonStyles(theme)[
                                                'Button'
                                              ].style,
                                              {
                                                backgroundColor:
                                                  palettes.App.Studily_Primary,
                                                fontFamily: 'Roboto_400Regular',
                                                fontSize: 12,
                                                letterSpacing: 1,
                                                margin: {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 10,
                                                },
                                                marginTop: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 10,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 10,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          title={'Click Here To Verify'}
                                        />
                                      )}
                                    </>
                                  </View>
                                </View>
                              )}
                            </>
                            <Divider
                              color={theme.colors.border.brand}
                              {...GlobalStyles.DividerStyles(theme)['Divider']
                                .props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.DividerStyles(theme)['Divider']
                                  .style,
                                dimensions.width
                              )}
                            />
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                      scrollEnabled={true}
                      style={StyleSheet.applyWidth(
                        { borderColor: palettes.App['TFA-White'], gap: 1 },
                        dimensions.width
                      )}
                    />
                  );
                }}
              </ThrillerXanoApi.FetchPayoutDetailsGET>
            </SimpleStyleScrollView>
          </View>
        </View>
        {/* bottom-box */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'center',
              justifyContent: 'center',
              marginBottom: 60,
              marginLeft: 20,
              marginRight: 20,
            },
            dimensions.width
          )}
        />
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(AddRefillMethodsScreen);
