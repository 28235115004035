import React from 'react';
import {
  Button,
  Icon,
  Link,
  LoadingIndicator,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Platform,
  RefreshControl,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as CustomCode from '../CustomCode';
import * as GlobalStyles from '../GlobalStyles.js';
import * as ThrillerXanoApi from '../apis/ThrillerXanoApi.js';
import UserBalanceViewBlock from '../components/UserBalanceViewBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as countDownTimer from '../custom-files/countDownTimer';
import extractErrorFromResponse from '../global-functions/extractErrorFromResponse';
import month_date_time from '../global-functions/month_date_time';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { item: '' };

const FeedMainP1InProgressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [active_league_id, setActive_league_id] = React.useState(1);
  const [current_above_below_id, setCurrent_above_below_id] = React.useState(0);
  const [is_above_below_error, setIs_above_below_error] = React.useState(false);
  const [markets_id, setMarkets_id] = React.useState(32);
  const [page, setPage] = React.useState(2);
  const [processing_id, setProcessing_id] = React.useState(0);
  const [projectionData, setProjectionData] = React.useState({});
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [user_balance, setUser_balance] = React.useState('$ 0');
  const [user_points, setUser_points] = React.useState('0 PT');
  const [refreshingLEPwHpTo, setRefreshingLEPwHpTo] = React.useState(false);
  const relativeTime = time => {
    const timestamp = time; // Replace this with your API value
    const date = new Date(Number(timestamp));

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayOfWeek = days[date.getDay()]; // Get the day name (Sun, Mon, etc.)

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure double digits for minutes
    const ampm = hours >= 12 ? 'pm' : 'am';

    // Convert 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Adjust hours for 0 (midnight)

    // Format the final time string
    const formattedTime = `${dayOfWeek} ${hours}:${minutes}${ampm}`;
    return formattedTime;
  };
  const thrillerXanoLineupSelectionsPOST =
    ThrillerXanoApi.useLineupSelectionsPOST();

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasBottomSafeArea={false}
      hasLeftSafeArea={true}
      hasRightSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['TFA-ScreenBG'],
          paddingLeft: 20,
          paddingRight: 20,
        },
        dimensions.width
      )}
    >
      {/* Scroll View 2 */}
      <SimpleStyleScrollView
        bounces={true}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        horizontal={false}
        nestedScrollEnabled={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={true}
        snapToAlignment={'start'}
        style={StyleSheet.applyWidth({ marginBottom: 40 }, dimensions.width)}
      >
        {/* header */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                {
                  minWidth: Breakpoints.Mobile,
                  value: Platform.OS === 'ios' ? 80 : undefined,
                },
              ],
            },
            dimensions.width
          )}
        >
          {/* logo */}
          <View
            style={StyleSheet.applyWidth({ width: '30%' }, dimensions.width)}
          >
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(Images['ThrillerFanstayLogo'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { height: 32, width: 103 }
                ),
                dimensions.width
              )}
            />
          </View>
          <UserBalanceViewBlock />
        </View>
        {/* filter */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'flex-start',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              marginTop: 20,
            },
            dimensions.width
          )}
        >
          <SimpleStyleScrollView
            bounces={true}
            nestedScrollEnabled={false}
            horizontal={true}
            keyboardShouldPersistTaps={'always'}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', height: 55 },
              dimensions.width
            )}
          >
            <ThrillerXanoApi.FetchGetLeaguesGET>
              {({ loading, error, data, refetchGetLeagues }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <SimpleStyleFlatList
                    data={fetchData?.items}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'MkPGxASQ'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <Pressable
                          onPress={() => {
                            try {
                              setActive_league_id(listData?.id);
                              setMarkets_id(null);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { borderColor: palettes.App['TFA-White'] },
                            dimensions.width
                          )}
                        >
                          {/* filter__item-1 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: palettes.App['BG Gray'],
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value:
                                      listData?.id === active_league_id
                                        ? palettes.App['Thriller Pink']
                                        : undefined,
                                  },
                                ],
                                borderRadius: 35,
                                flexDirection: 'row',
                                height: 45,
                                marginRight: 10,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                          >
                            <Image
                              resizeMode={'cover'}
                              {...GlobalStyles.ImageStyles(theme)['Image']
                                .props}
                              source={imageSource(listData?.logo?.url)}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)['Image']
                                    .style,
                                  {
                                    borderRadius: 50,
                                    height: 25,
                                    marginRight: 10,
                                    width: 25,
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.App['TFA-White'],
                                    fontFamily: 'RobotoMono_500Medium',
                                    fontSize: 13,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?.alias}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    horizontal={true}
                  />
                );
              }}
            </ThrillerXanoApi.FetchGetLeaguesGET>
          </SimpleStyleScrollView>
        </View>

        <SimpleStyleScrollView
          bounces={true}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
          horizontal={true}
          style={StyleSheet.applyWidth(
            { flexDirection: 'column', height: 55, marginTop: 20 },
            dimensions.width
          )}
        >
          <ThrillerXanoApi.FetchGetMarketsGET leagues_id={active_league_id}>
            {({ loading, error, data, refetchGetMarkets }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <SimpleStyleFlatList
                  data={fetchData?.items}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) =>
                    listData?.id ??
                    listData?.uuid ??
                    index?.toString() ??
                    JSON.stringify(listData)
                  }
                  keyboardShouldPersistTaps={'never'}
                  listKey={'MkppR5Ux'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <View
                        style={StyleSheet.applyWidth(
                          { marginRight: 5 },
                          dimensions.width
                        )}
                      >
                        {/* btn-content */}
                        <Button
                          accessible={true}
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              setMarkets_id(listData?.id);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: palettes.App['Form Field BG'],
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value:
                                      markets_id === listData?.id
                                        ? palettes.App['TFA-Pink']
                                        : undefined,
                                  },
                                ],
                                borderRadius: 20,
                                color: palettes.App['TFA-White'],
                                fontFamily: 'RobotoMono_400Regular',
                                fontSize: 12,
                                maxWidth: 150,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={`${listData?.title}`}
                        />
                      </View>
                    );
                  }}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row' },
                    dimensions.width
                  )}
                />
              );
            }}
          </ThrillerXanoApi.FetchGetMarketsGET>
        </SimpleStyleScrollView>
        {/* title-bar */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 20,
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['headling-19'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TextStyles(theme)['headling-19'].style,
                { fontFamily: 'Roboto_700Bold', fontSize: 24 }
              ),
              dimensions.width
            )}
          >
            {'Players'}
          </Text>
          {/* btn-wrap */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', justifyContent: 'flex-end' },
              dimensions.width
            )}
          >
            {/* btn-search-icon */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  /* hidden 'Navigate' action */
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              icon={'AntDesign/search1'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: null,
                    borderRadius: 0,
                    color: palettes.App['#7F7685'],
                    padding: 0,
                  }
                ),
                dimensions.width
              )}
              title={''}
            />
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newTextInputValue => {
                const textInputValue = newTextInputValue;
                try {
                  setMarkets_id('');
                  setTextInputValue2(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              placeholder={'Search Players'}
              placeholderTextColor={palettes.App['TFA-White']}
              selectionColor={palettes.App['TFA-White']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    color: palettes.App['TFA-White'],
                    fontFamily: 'Roboto_400Regular',
                    maxWidth: 240,
                    minWidth: 170,
                  }
                ),
                dimensions.width
              )}
              value={textInputValue2}
            />
          </View>
        </View>
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'stretch', marginTop: 10, width: '100%' },
            dimensions.width
          )}
        >
          <ThrillerXanoApi.FetchCurrentLineupGET
            handlers={{
              onData: fetchData => {
                try {
                  if (fetchData?.correct_picks) {
                  } else {
                  }
                } catch (err) {
                  console.error(err);
                }
              },
            }}
          >
            {({ loading, error, data, refetchCurrentLineup }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* View 2 */}
                  <>
                    {!fetchData?.multiplier ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'stretch',
                            alignSelf: 'auto',
                            backgroundColor: palettes.App['TFA-Pink'],
                            borderRadius: 5,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginTop: 10,
                            paddingBottom: 10,
                            paddingTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App['TFA-White'],
                                fontFamily: 'Roboto_700Bold',
                                fontSize: 14,
                                marginRight: 10,
                                textAlign: 'left',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Pays Up To '}
                          {fetchData?.multiplier}
                          {'X For '}
                          {fetchData?.correct_picks}
                          {' Picks\n'}
                        </Text>
                        <Link
                          accessible={true}
                          onPress={() => {
                            try {
                              navigation.navigate(
                                'CurrentEntryP1InProgressScreen'
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          selectable={false}
                          {...GlobalStyles.LinkStyles(theme)['Link'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.LinkStyles(theme)['Link'].style,
                              {
                                color: palettes.App['TFA-White'],
                                fontFamily: 'Roboto_400Regular',
                                textAlign: 'right',
                                textDecorationLine: 'underline',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Finalize Your Lineup'}
                        />
                      </View>
                    )}
                  </>
                  {/* View 3 */}
                  <>
                    {!(fetchData?._lineup_selections?.length === 1) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['Thriller Pink'],
                            borderRadius: 5,
                            flexWrap: 'nowrap',
                            marginTop: 10,
                            position: 'relative',
                            zIndex: 1000,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: 0, padding: 10, width: '100%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App['TFA-White'],
                                  fontFamily: 'Roboto_400Regular',
                                  fontSize: 16,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Select One More To Proceed'}
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                </>
              );
            }}
          </ThrillerXanoApi.FetchCurrentLineupGET>
        </View>
        {/* player-grid */}
        <View
          accessibilityRole={'button'}
          accessible={false}
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              marginTop: 20,
            },
            dimensions.width
          )}
        >
          <ThrillerXanoApi.FetchGetPlayersGET
            handlers={{
              onData: fetchData => {
                try {
                  setProjectionData(fetchData?.items);
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            leagues_id={active_league_id}
            markets_id={markets_id}
            page={1}
            search={textInputValue2}
          >
            {({ loading, error, data, refetchGetPlayers }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <SimpleStyleFlatList
                    data={projectionData}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    listKey={'LEPwHpTo'}
                    onEndReached={() => {
                      const handler = async () => {
                        try {
                          if (fetchData?.nextPage === null) {
                            return;
                          }
                          setPage(page + 1);
                          const newData = (
                            await ThrillerXanoApi.getPlayersGET(Constants, {
                              leagues_id: 1,
                              markets_id: 32,
                              page: page,
                              search: textInputValue2,
                            })
                          )?.json;
                          setProjectionData(
                            projectionData.concat(newData?.items)
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    onEndReachedThreshold={0.5}
                    refreshControl={
                      <RefreshControl
                        refreshing={refreshingLEPwHpTo}
                        onRefresh={() => {
                          try {
                            setRefreshingLEPwHpTo(true);
                            setPage(1);
                            setRefreshingLEPwHpTo(false);
                          } catch (err) {
                            console.error(err);
                            setRefreshingLEPwHpTo(false);
                          }
                        }}
                      />
                    }
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* player__item-2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'stretch',
                                borderColor: palettes.App['Thriller Pink'],
                                borderRadius: 10,
                                borderWidth: listData?.lineup?.selection
                                  ? 2
                                  : undefined,
                                margin: 5,
                                marginBottom: 10,
                                minHeight: 280,
                                width: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: '48%',
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value:
                                      dimensions.width >= Breakpoints.Desktop
                                        ? '25%'
                                        : '48%',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <ImageBackground
                              {...GlobalStyles.ImageBackgroundStyles(theme)[
                                'Image Background'
                              ].props}
                              resizeMode={'cover'}
                              source={imageSource(Images['PlayerCardBg'])}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageBackgroundStyles(theme)[
                                    'Image Background'
                                  ].style,
                                  {
                                    alignItems: 'center',
                                    borderRadius: 16,
                                    overflow: 'hidden',
                                    paddingBottom: 16,
                                    paddingTop: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* player__top */}
                              <View>
                                {/* point */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignContent: 'flex-start',
                                      left: -42,
                                      padding: 5,
                                      position: 'absolute',
                                      top: -12,
                                      zIndex: 5,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* btn-stats */}
                                  <Button
                                    accessible={true}
                                    iconPosition={'left'}
                                    onPress={() => {
                                      try {
                                        navigation.navigate(
                                          'CurrentEntryPlayerP1InProgressScreen',
                                          { player_prop_id: listData?.id }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    {...GlobalStyles.ButtonStyles(theme)[
                                      'Button'
                                    ].props}
                                    icon={'Entypo/bar-graph'}
                                    iconSize={16}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ButtonStyles(theme)[
                                          'Button'
                                        ].style,
                                        {
                                          backgroundColor:
                                            palettes.App['Form Field BG'],
                                          borderRadius: 35,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={''}
                                  />
                                </View>
                                <Image
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  resizeMode={'contain'}
                                  source={imageSource(
                                    listData?._player_data?.player_image?.url
                                      ? listData?._player_data?.player_image
                                          ?.url
                                      : Images['jerseyremovebgpreview']
                                  )}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      {
                                        borderRadius: 50,
                                        height: 80,
                                        width: 80,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                                {/* vs */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignContent: 'flex-start',
                                      backgroundColor:
                                        palettes.App['Thriller Pink'],
                                      borderColor: palettes.App['TFA-BlockBG'],
                                      borderRadius: 8,
                                      borderWidth: 4,
                                      bottom: 0,
                                      padding: 5,
                                      position: 'absolute',
                                      right: -35,
                                      zIndex: 5,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: palettes.App['TFA-White'],
                                        fontFamily: 'RobotoMono_400Regular',
                                        fontSize: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'vs '}
                                    {listData?._away_team?.alias}
                                  </Text>
                                </View>
                              </View>

                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'poppins-16-bold-white'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'poppins-16-bold-white'
                                    ].style,
                                    {
                                      fontFamily: 'Roboto_500Medium',
                                      marginTop: 10,
                                      textAlign: 'center',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?._player_data?.name}
                              </Text>
                              {/* Text 2 */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'poppins-14-medium-white'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'poppins-14-medium-white'
                                    ].style,
                                    {
                                      color: palettes.App['TFA-White'],
                                      fontFamily: 'Roboto_500Medium',
                                      fontSize: 12,
                                      marginTop: 5,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?._home_team?.alias}
                                {' - '}
                                {listData?._player_data?.position}
                              </Text>
                              {/* entry-date-time */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    borderRadius: 8,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    marginBottom: 6,
                                    marginTop: 6,
                                    width: '90%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* entry-player__right */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      alignSelf: 'center',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        width: '100%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* icon-calendar */}
                                    <Icon
                                      color={palettes.App['TFA-White']}
                                      name={'Ionicons/calendar-outline'}
                                      size={14}
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 5 },
                                        dimensions.width
                                      )}
                                    />
                                    {/* date */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'poppins-14-medium-white'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'poppins-14-medium-white'
                                          ].style,
                                          {
                                            alignSelf: 'center',
                                            fontFamily: 'Roboto_500Medium',
                                            fontSize: 12,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {month_date_time(
                                        listData?._seasons_schedule_nfl
                                          ?.scheduled
                                      )}
                                    </Text>
                                  </View>
                                  {/* View 3 */}
                                  <>
                                    {!(listData?._seasons_schedule_nfl
                                      ?.status === 'created'
                                      ? true
                                      : undefined) ? null : (
                                      <View>
                                        <Utils.CustomCodeErrorBoundary>
                                          <countDownTimer.CountdownTimer
                                            unixTimes={
                                              listData._seasons_schedule_nfl
                                                .scheduled
                                            }
                                          />
                                        </Utils.CustomCodeErrorBoundary>
                                      </View>
                                    )}
                                  </>
                                </View>
                              </View>
                              {/* Projection Point */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Market */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        alignSelf: 'auto',
                                        color: palettes.App['TFA-White'],
                                        fontFamily: 'Roboto_500Medium',
                                        fontSize: 18,
                                        marginBottom: 5,
                                        marginTop: 5,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.projection_point}
                                  {'  '}
                                </Text>

                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['TFA-White'],
                                        fontFamily: 'Roboto_400Regular',
                                        fontSize: 12,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?._market?.title}
                                </Text>
                              </View>
                              {/* selection Buttons */}
                              <>
                                {(
                                  processing_id === listData?.id ? true : false
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        marginTop: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Below BTN */}
                                    <Button
                                      accessible={true}
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            setProcessing_id(listData?.id);
                                            const resp = (
                                              await thrillerXanoLineupSelectionsPOST.mutateAsync(
                                                {
                                                  props_id: listData?.id,
                                                  selection: 'Below',
                                                }
                                              )
                                            )?.json;
                                            if (
                                              extractErrorFromResponse(resp) ===
                                              'false'
                                            ) {
                                              await refetchGetPlayers();
                                            } else {
                                              showAlertUtil({
                                                title: 'Oops',
                                                message:
                                                  extractErrorFromResponse(
                                                    resp
                                                  ),
                                                buttonText: undefined,
                                              });
                                            }

                                            setProcessing_id(0);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      {...GlobalStyles.ButtonStyles(theme)[
                                        'Button'
                                      ].props}
                                      icon={'Ionicons/caret-down'}
                                      iconPosition={'right'}
                                      iconSize={18}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ButtonStyles(theme)[
                                            'Button'
                                          ].style,
                                          {
                                            backgroundColor: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value:
                                                  palettes.App['Form Field BG'],
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value:
                                                  listData?.lineup
                                                    ?.selection === 'Below'
                                                    ? palettes.App[
                                                        'Custom Color_21'
                                                      ]
                                                    : undefined,
                                              },
                                            ],
                                            borderRadius: 35,
                                            borderStyle: 'solid',
                                            color: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value:
                                                  palettes.App['TFA-White'],
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value:
                                                  listData?.lineup
                                                    ?.selection === 'Below'
                                                    ? palettes.App['#FF4141']
                                                    : undefined,
                                              },
                                            ],
                                            fontFamily: 'Roboto_500Medium',
                                            fontSize: 11,
                                            marginRight: 2,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={'Below'}
                                    />
                                    {/* Above BTN */}
                                    <Button
                                      accessible={true}
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            setProcessing_id(listData?.id);
                                            const resp = (
                                              await thrillerXanoLineupSelectionsPOST.mutateAsync(
                                                {
                                                  props_id: listData?.id,
                                                  selection: 'Above',
                                                }
                                              )
                                            )?.json;
                                            if (
                                              extractErrorFromResponse(resp) ===
                                              'false'
                                            ) {
                                              await refetchGetPlayers();
                                            } else {
                                              showAlertUtil({
                                                title: undefined,
                                                message:
                                                  extractErrorFromResponse(
                                                    resp
                                                  ),
                                                buttonText: undefined,
                                              });
                                            }

                                            setProcessing_id(0);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      {...GlobalStyles.ButtonStyles(theme)[
                                        'Button'
                                      ].props}
                                      icon={'Ionicons/caret-up-outline'}
                                      iconPosition={'right'}
                                      iconSize={18}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ButtonStyles(theme)[
                                            'Button'
                                          ].style,
                                          {
                                            backgroundColor: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value:
                                                  palettes.App['Form Field BG'],
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value:
                                                  listData?.lineup
                                                    ?.selection === 'Above'
                                                    ? palettes.App[
                                                        'Custom Color_20'
                                                      ]
                                                    : undefined,
                                              },
                                            ],
                                            borderRadius: 35,
                                            color: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value:
                                                  palettes.App['TFA-White'],
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value:
                                                  listData?.lineup
                                                    ?.selection === 'Above'
                                                    ? palettes.App['TFA-Green']
                                                    : undefined,
                                              },
                                            ],
                                            fontFamily: 'Roboto_500Medium',
                                            fontSize: 11,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={'Above'}
                                    />
                                  </View>
                                )}
                              </>
                              {/* View 2 */}
                              <>
                                {!(processing_id === listData?.id
                                  ? true
                                  : false) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { alignItems: 'center', marginTop: 10 },
                                      dimensions.width
                                    )}
                                  >
                                    <LoadingIndicator
                                      color={theme.colors.branding.primary}
                                      size={30}
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 15, marginRight: 15 },
                                        dimensions.width
                                      )}
                                      type={'circle'}
                                    />
                                  </View>
                                )}
                              </>
                            </ImageBackground>
                          </View>
                        </>
                      );
                    }}
                    horizontal={false}
                    initialNumToRender={20}
                    keyboardShouldPersistTaps={'never'}
                    nestedScrollEnabled={true}
                    numColumns={dimensions.width >= Breakpoints.Desktop ? 4 : 2}
                    scrollEnabled={true}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    style={StyleSheet.applyWidth(
                      { width: '100%' },
                      dimensions.width
                    )}
                  />
                  <>
                    {!(fetchData?.itemsReceived === 0) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 60,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                alignSelf: 'center',
                                color: palettes.App['TFA-White'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 20,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'No Available Props'}
                        </Text>
                      </View>
                    )}
                  </>
                </>
              );
            }}
          </ThrillerXanoApi.FetchGetPlayersGET>
        </View>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(FeedMainP1InProgressScreen);
