import React from 'react';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { Image, ImageBackground, Text } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const NotAvailableScrenScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.Thriller },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={imageSource(Images['Background'])}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            {
              alignContent: {
                minWidth: Breakpoints.Laptop,
                value: 'flex-start',
              },
              alignItems: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
              alignSelf: 'auto',
              justifyContent: 'center',
              marginTop: 50,
            }
          ),
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.Studily_Lavender_Purple,
                },
                {
                  minWidth: Breakpoints.Mobile,
                  value: palettes.App['TFA-White'],
                },
              ],
              fontFamily: [
                { minWidth: Breakpoints.Laptop, value: 'System' },
                { minWidth: Breakpoints.Mobile, value: 'Poppins_400Regular' },
              ],
              fontSize: [
                { minWidth: Breakpoints.Laptop, value: 50 },
                { minWidth: Breakpoints.Mobile, value: 30 },
              ],
              fontWeight: { minWidth: Breakpoints.Laptop, value: '700' },
              marginBottom: 30,
              marginTop: { minWidth: Breakpoints.Laptop, value: 100 },
              textAlign: [
                { minWidth: Breakpoints.Laptop, value: 'center' },
                { minWidth: Breakpoints.Mobile, value: 'center' },
              ],
            }),
            dimensions.width
          )}
        >
          {'Oops!'}
        </Text>
        <Image
          resizeMode={'cover'}
          {...GlobalStyles.ImageStyles(theme)['Image'].props}
          source={imageSource(Images['notavailable'])}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'].style, {
              height: [
                { minWidth: Breakpoints.Laptop, value: '60%' },
                { minWidth: Breakpoints.Mobile, value: 200 },
              ],
              position: { minWidth: Breakpoints.Laptop, value: 'relative' },
              width: [
                { minWidth: Breakpoints.Laptop, value: '60%' },
                { minWidth: Breakpoints.Mobile, value: 200 },
              ],
            }),
            dimensions.width
          )}
        />
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(NotAvailableScrenScreen);
